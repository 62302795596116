
import { useEffect, useState } from "react";
import { EventCode, FlowType } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import { ReactComponent as TacSVG} from '../../assets/svg/heart.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as DoctorSVG} from '../../assets/svg/doctor-2.svg'; 
import { ReactComponent as AISVG} from '../../assets/svg/artificial-intelligence.svg'; 

import ProductDetailSection from "../../enums/productDetailSection.enum";
import './cleerly.scss'
import { getProductTypeByCode } from "../../services/productType.service";

export const Cleerly= () => {
  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)

  // init + get prices
  useEffect(() => {
    fetchProductType()
  }, []);
  
  // set product attributes
  useEffect(() => {
    if (!productType) return

    const faqs = [
      {
        question: "What is a Coronary CT Angiogram?",
        answer: "Coronary computed tomography angiography (CCTA) is a heart specific diagnostic imaging test that helps determine if plaque buildup is present in the coronary arteries, the blood vessels that supply the heart. Computed tomography is more commonly known as a CT scan. A CT scan generates images that can be reformatted in multiple planes and can even generate 3-D images. Patients undergoing a CCTA scan receive an iodine-containing contrast material as an intravenous (IV) injection to ensure the best possible images of the heart blood vessels."
      },
      {
        question: "How much does a Coronary CT Angiogram with Cleerly cost?",
        answer: "The price varies by location, usually between $1500-$2000. Instalab will always recommend a nearby center with the best price. You will pay the center directly at the time of service."
      },
      {
        question: "What is a Cleerly AI and how reliable is it?",
        answer: <>The Cleerly coronary analysis is a thorough evaluation of the presence, amount, and type of plaque in your heart's arteries based on a CCTA study. Images captured in the CCTA exam are processed by Cleerly software - a set of artificial intelligence based algorithms - and translated into measurements and reports. The results of the Cleerly analysis give you a comprehensive understanding of your current state of disease for reference your ongoing heart health and treatment.<br/><br/>Cleerly’s technology is based on over 10 million images from over 30,000 patients gathered over a 15-year-period in multi-center clinical trials. Their proprietary machine-learning algorithms used to analyze the amount and type of plaque are FDA-cleared. Data from six, discrete multi-center clinical trials validate Cleerly as one of the most accurate approaches for detection of coronary artery disease. These trials show that Cleerly has superior accuracy against every current clinical gold standard for heart disease diagnosis.</>
      },
      {
        question: "How does the procedure work?",
        answer: <>Patients undergoing a CCTA scan receive an iodine-containing contrast material as an intravenous (IV) injection to ensure the best possible images of the heart blood vessels. The contrast material is used to clearly define the blood vessels being examined by making them appear bright white.<br/><br/>During the exam, x-rays pass through the body and are picked up by special detectors in the scanner. The information collected during the CCTA exam is used to identify the coronary artery anatomy, plaque, narrowing of the vessel, and, in certain cases, heart function.</>
      },
      {
        question: "What will I experience during and after the CCTA exam?",
        answer: <>While the CT scan itself only takes a few minutes to perform, you may be at the imaging center or hospital for up to an hour. You will have your blood pressure taken before, during, and after the examination. <br/><br/>Depending on your heart rate, a medication may be given prior to the scan to slow your heart rate which makes the CT images sharper and a second medication will be given that slightly dilates your arteries for better image quality. A nurse or technologist will insert an IV line into a vein in your arm to administer contrast material during your procedure. While lying on the scanning table, you may be asked to raise your arms over your head for the duration of the exam. This will help improve image quality. During this time, you may hear slight buzzing, clicking and whirring sounds. The technologist may ask you to hold your breath during portions of the scanning.</>
      },
      {
        question: "What is plaque and why does it matter?",
        answer: <>Plaque is the physical representation of a disease called Atherosclerosis. It is made of various substances such as fat, cholesterol and calcium that deposit along the inner lining of the arteries. Plaque, which builds up over time, can reduce or in some cases completely block blood flow. The presence and type of plaque in the coronary arteries has proven to be the greatest predictor of an impending cardiac event.<br/><br/>Stabilizing high-risk plaques has been achieved with lifestyle changes and medical therapy through the transformation of non-calcified high-risk plaques to calcified low-risk plaques. This transformation of plaques has been shown to be associated with reduced rates of major adverse cardiovascular events.</>
      },
      {
        question: "Am I candidate for CCTA + Cleerly analysis?",
        answer: "If you have any risk factors for cardiovascular disease, including family history, high cholesterol, diabetes, you are a candidate."
      },
      {
        question: "Should I do this if I did a coronary calcium scan recently?",
        answer: <>The coronary calcium scan can detect and quantify atheroscelrosis and is valuable in informing treatment decisions in asymptomatic adults. But it can't detect and quantify low-density, non-calcified plaque, which is more likely than hardened and calcified plaque to rupture and cause a blood clot to form. A CAC score of zero doesn't include the precense of high-risk plaque.<br/><br/>Cleerly's model of personlaized cardiovascular care leverages an AI-enabled analysis of coronary computed tomography angiography (CCTA) images to identify, characterize and quantify plaque buildup. Several studies have concluded that CCTA shows coronary ahteroscelorsis in 41% to 53% of paitnest with a CAC score of zero. The Western Denmark Health Study further found that 31% of major adverse cardiac events occur in patients with a CAC score of zero.</>
      },
      {
        question: "How much radiation exposure do I get from this procedure?",
        answer: `The amount of radiation exposure you receive from this procedure depends on factors such as your body mass and the type of machine used at the imaging center. Typically, the exposure ranges from 3 to 5 millisieverts (mSv). For comparison, the average person is exposed to about 3 mSv of natural background radiation each year.`,
      },
      {
        question: `Does my health insurance cover this test?`,
        answer: `Insurance does not cover CCTA with Cleerly for prevenatative purposes. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card for this purpose. If you don’t have one, we can generate an itemized receipt for you to submit.`,
      }, 
      {
        question: `Can I talk to an actual human physician to review my results with me?`,
        answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a physician who's experienced in reading Cleerly reports. For Instalab members, we can also help find and refer you to a cardiologist, if needed.`,
      }, 
      {
        question: "Where is CCTA with Cleerly available?",
        answer: `It's available in all 50 US states.`,
      },


    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: "Coronary CT Angiogram with Cleery AI",
          memberPrice: ProductHelper.getMemberPrice(productType.cost),
          originalPrice: productType.cost,
          oneliner: "A non-invasive heart scan that detects presence of coronary artery disease, high-risk soft plaque, artery blockages and more.",
          points: [
              <><DoctorSVG className="point-icon"/>Required physician referral to get scan done</>,
              <><TacSVG className="point-icon"/>Scan scheduled and done at nearby imaging center</>,
              <><AISVG className="point-icon"/>Analysis by Cleerly AI to precisely identify disease</>,              
              <><CardSVG className="point-icon" />HSA and FSA cards accepted</>,
          ],
          cta: {
            text: "Get Started Now",
            url:`/flow/${FlowType.CLEERLY}`
          },
          photos: [
            {
              original: "/img/cleerly-3.png",
              thumbnail:  "/img/cleerly-3.png"
            },
            {
              original: "/img/cleerly-1.png", 
              thumbnail: "/img/cleerly-1.png"
            },
            {
                original: "/img/cleerly-4.png", 
                thumbnail: "/img/cleerly-4.png"
            },
        ],
          faqs: faqs.slice(0,3)
        }
      },
      { type: ProductDetailSection.XPANEL,
        title: "What is your personal risk for heart attack?",
        description: <>Heart attacks can be prevented if risk is found early. Many current methods of testing for heart disease can't detect the largest predictor of heart attacks: <u>high-risk soft plaque</u>. CCTA catches this and more.</>,
        data: [
          {
            image: "/img/cleerly-50pct.png",
            text: "50% of people who suffer a heart attack will do so without experiencing any symptoms beforehand."
          },
          {
            image: "/img/cleerly-70pct.png",
            text: "70% of heart attack victims are considered low risk by traditional methods of assessing heart disease."
          },
          {
            image: "/img/cleerly-75pct.png",
            text: "75% of lesions causing heart attacks cause mild artery narrowing and are often missed by standard testing."
          }
        ]
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
          {
              title: "Schedule your CCTA",
              description: "After you order, Instalab Concierge will reach out and facilitate the scheduling of your CCTA appointment with a partner imaging center.",
              image: "./img/cleerly-step1.png"
          },
          {
              title: "Get your CCTA done",
              description: "Go to the imaging center and get the scan done. The entire appointment should take at most 1 hour, usually less. Some may need to take a beta blocker beforehand.",
              image: "./img/cleerly-step2.png"
          },
          {
              title: "Receive results + report",
              description: "Once Cleerly finishes analyzing the images, Instalab will email you with your results and detailed report. Physicians are available to answer any questions.",
              image: "./img/cleerly-step3.png"
          }
      ],
      },
      // { type: ProductDetailSection.TESTIMONIALS,
      //   title: "Hear from real patients",
      //   data: [
      //     {
      //         name: "Tim O'Donnell",
      //         title: "World Champion Triathlete",
      //         video: "https://vimeo.com/860341101",
      //         image: "/img/tim-odonnell.png",
      //         // text: "All the other tests I did were helpful, but they weren't definitive. And it was the Cleerly scan for me that really showed — It showed the whole picture and it put everything right in front of my eyes and that's what I needed … And I knew after I did the Cleerly scan, there's nothing else I can do that's going to show anything better than this."
      //     },
      //     {
      //         name: "Terry Schmell",
      //         title: "Quality Director",
      //         video: "https://vimeo.com/699910857",
      //         image: "/img/terry-schmell.png",
      //         // text: "I don't have family history of heart disease and had no symptoms. I had 95% blockage in the left anterior descending. They call that the widowmaker. "
      //     },
      //     {
      //         name: "Ron Guillot",
      //         title: "Sales Executive",
      //         video: "https://www.youtube.com/watch?v=GdFiohj2dJ0",
      //         image: "/img/ron-guillot.png",
      //         // text: "I'm blown away by how comprehensive Instalab is. It's great to see a report focused on my most pressing issues. I love the intuitive summaries that explaind my results and where I stood on the risk curve."
      //     },
      //     {
      //         name: "Sharon Bruno",
      //         title: "Registered Nurse",
      //         image: "/img/sharon-bruno.png",
      //         video: "https://vimeo.com/829288920"
  
      //     }
      //   ],
      // },
      {
        type: ProductDetailSection.FAQ,
        data: faqs.slice(3)
      }
    ])

  }, [productType])

  const fetchProductType = async () => {
    setProductType(await getProductTypeByCode(ProductTypeCode.CLEERLY))
  };


  return (
    sections && <ProductDetail className="galleri" sections={sections} trackCode={EventCode.VISIT_CLEERLY}/>
  )
}
