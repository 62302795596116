import { Typography } from "antd";
import "./metabolicAdvice.scss";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import PatientHelper from "../../../helpers/patient.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { TestModal } from "../../testModal/testModal.component";
import { Condition, LowercaseTest, ReportSection, RiskLevel, TestCode } from "../../../enums/index.enum";
import classNames from "classnames";
import ReactDOM from "react-dom";
import ReportHelper from "../../../helpers/report.helper";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import { TestLink } from "../testLink/testLink.component";
const converter = require("number-to-words");

const { Paragraph } = Typography;

const primaryCodes = [
  TestCode.HOMAIR,
  TestCode.HBA1C,
  TestCode.INSULIN,
  TestCode.GLUCOSE,
  TestCode.TG,
  TestCode.VLDL,
  TestCode.NMR_LP_IR_SCORE
];

const femaleBlacklist = [
  TestCode.TOTAL_TESTOSTERONE,
  TestCode.FREE_TESTOSTERONE,
];


export const MetabolicAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [openModal, setOpenModal] = useState();
  const [testId, setTestId] = useState();

  // tests
  const [metabolicTests, setMetabolicTests] = useState();
  const [primaryTests, setPrimaryTests] = useState();
  const [secondaryTests, setSecondaryTests] = useState();
  const [improvedPrimaryTests, setImprovedPrimaryTests] = useState();
  const [worsePrimaryTests, setWorsePrimaryTests] = useState();
  const [staticPrimaryTests, setStaticPrimaryTests] = useState();
  const [staticSecondaryTests, setStaticSecondaryTests] = useState();
  const [improvedSecondaryTests, setImprovedSecondaryTests] = useState();
  const [worseSecondaryTests, setWorseSecondaryTests] = useState();
  const [newSuboptimalTests, setNewSuboptimalTests] = useState();

  // content
  const [title, setTitle] = useState();
  const [isPrediabetic, setIsPrediabetic] = useState();
  const [isDiabetic, setIsDiabetic] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();

  useEffect(() => {
    setMetabolicTests(getMetabolicTests());
    setIsFixed(ReportHelper.isFixed(report, ReportSection.METABOLIC_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.METABOLIC_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.METABOLIC_ADVICE));
    setIsStatic(ReportHelper.isStatic(report, ReportSection.METABOLIC_ADVICE));
    setIsPrediabetic(getIsPrediabetic());
    setIsDiabetic(getIsDiabetic());
  }, [report, results, tests]);

  useEffect(() => {
    replaceTestViewPrimaryTests()
  }, [primaryDescription, secondaryDescription, report, primaryTests, results, reports, user])

  useEffect(() => {
    replaceTestViewStaticPrimaryTests()
  }, [primaryDescription, secondaryDescription, report, staticPrimaryTests, results, reports, user])

  useEffect(() => {
    replaceTestViewWorsePrimaryTests()
  }, [primaryDescription, secondaryDescription, report, worsePrimaryTests, results, reports, user])

  useEffect(() => {
    replaceTestViewStaticAndWorseSecondaryTests()
  }, [primaryDescription, secondaryDescription, report, staticSecondaryTests, worseSecondaryTests, results, reports, user])

  useEffect(() => {
    replaceTestViewStaticAndWorsePrimaryTests()
  }, [primaryDescription, secondaryDescription, report, staticPrimaryTests, worsePrimaryTests, results, reports, user])

  useEffect(() => {
    replaceTestViewImprovedPrimaryTests()
  }, [primaryDescription, secondaryDescription, report, improvedPrimaryTests, results, reports, user])

  useEffect(() => {
    replaceTestViewImprovedSecondaryTests()
  }, [primaryDescription, secondaryDescription, report, improvedSecondaryTests, results, reports, user])

  useEffect(() => {
    replaceTestViewSecondaryTests()
  }, [primaryDescription, secondaryDescription, report, secondaryTests, results, reports, user])
  
  useEffect(() => {
    replaceTestViewNewSuboptimalTests()
  }, [primaryDescription, secondaryDescription, report, newSuboptimalTests, results, reports, user])


  useEffect(() => {
    categorizeTests();
  }, [report, tests, metabolicTests, user]);

  useEffect(() => {
    setTitle(getTitle());
  }, [user, report, results, primaryTests, tests, isBetter, isFixed, isWorse, isStatic]);

  useEffect(() => {
    setPrimaryDescription(getPrimaryDescription());
  }, [report, isPrediabetic, primaryTests, improvedPrimaryTests, worsePrimaryTests, isBetter, isFixed, isWorse, isStatic]);

  useEffect(() => {
    setSecondaryDescription(getSecondaryDescription());
  }, [report, secondaryTests, improvedSecondaryTests, isFixed, isBetter, isWorse, isStatic]);

  useEffect(() => {
    populateTestLinks()
  }, [primaryDescription, secondaryDescription])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          secondaryDescription: renderToString(secondaryDescription),
          recs
        }
      }
    })
  }, [title, primaryDescription, secondaryDescription, recs])

  const populateTestLinks = () => {
    const testLinks = document.getElementsByClassName('view-test')
    for (const testLink of testLinks) {
      const testCode = testLink.id.split('-')[2]
      const test = tests.find(({ code }) => code === Number(testCode))
      ReactDOM.render(
        <TestLink
          test={test}
          content={testLink.getAttribute('text')}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />,
        testLink
      )
    }
  }

  const replaceTestViewPrimaryTests = () => {
    const target = document.getElementById('test-view-primary-tests')
    if (!target || report === undefined || !primaryTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={primaryTests}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const replaceTestViewStaticPrimaryTests = () => {
    const target = document.getElementById('test-view-static-primary-tests')
    if (!target || report === undefined || !staticPrimaryTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={staticPrimaryTests}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const replaceTestViewWorsePrimaryTests = () => {
    const target = document.getElementById('test-view-worse-primary-tests')
    if (!target || report === undefined || !worsePrimaryTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={worsePrimaryTests}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const replaceTestViewStaticAndWorsePrimaryTests = () => {
    const target = document.getElementById('test-view-static-and-worse-primary-tests')

    if (!target || report === undefined || !staticPrimaryTests || !worsePrimaryTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={[...(staticPrimaryTests ? staticPrimaryTests : []), ...(worsePrimaryTests ? worsePrimaryTests : [])]}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const replaceTestViewStaticAndWorseSecondaryTests = () => {
    const target = document.getElementById('test-view-static-and-worse-secondary-tests')

    if (!target || report === undefined || !staticSecondaryTests || !worseSecondaryTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={[...(staticSecondaryTests ? staticSecondaryTests : []), ...(worseSecondaryTests ? worseSecondaryTests : [])]}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const replaceTestViewImprovedPrimaryTests = () => {
    const target = document.getElementById('test-view-improved-primary-tests')
    if (!target || report === undefined || !improvedPrimaryTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={improvedPrimaryTests}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const replaceTestViewImprovedSecondaryTests = () => {
    const target = document.getElementById('test-view-improved-secondary-tests')
    if (!target || report === undefined || !improvedSecondaryTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={improvedSecondaryTests}
        results={results}
        reports={reports}
      />,
      target
    )
  }

  const replaceTestViewSecondaryTests = () => {
    const target = document.getElementById('test-view-secondary-tests')
    if (!target || report === undefined || !secondaryTests) return
    ReactDOM.render(
      <TestView
        user={user}
        report={report}
        tests={secondaryTests}
        results={results}
        reports={reports}
      />,
      target
    )
  }


  const replaceTestViewNewSuboptimalTests = () => {
    const target = document.getElementById('test-view-newsuboptimal-metabolic-tests')
    if (!target || report === undefined || !newSuboptimalTests) return
    ReactDOM.render(
      <TestView
        user={user} 
        report={report} 
        tests={newSuboptimalTests}
        results={results}
        reports={reports}
      />,
      target
    );
  }

  const getMetabolicTests = () => {
    if (!report || !tests) return null;
    const metabolicTests = ReportHelper.getSectionTests(report, ReportSection.METABOLIC_ADVICE, tests)   
    return metabolicTests;
  };

  const getIsPrediabetic = () => {
    if (!report) return null;
    const { result } = report
    return result?.conditions?.includes(Condition.PRE_DIABETES);
  };

  const getIsDiabetic = () => {
    if (!report) return null;
    const { result } = report
    return result?.conditions?.includes(Condition.DIABETES);
  };

  const categorizeTests = () => {
    if (!metabolicTests || !user) return null;

    setPrimaryTests(
      metabolicTests.filter(({ code }) => primaryCodes.includes(code))
    );
    setSecondaryTests(
      metabolicTests.filter(({ code }) => {
        return PatientHelper.isFemale(user)
          ? !primaryCodes.includes(code) && !femaleBlacklist.includes(code)
          : !primaryCodes.includes(code);
      })
    );

    const improvedTests = ReportHelper.getSectionImprovedTests(report, ReportSection.METABOLIC_ADVICE, tests)|| null
    const staticTests = ReportHelper.getSectionStaticTests(report, ReportSection.METABOLIC_ADVICE, tests)|| null
    const worseTests = ReportHelper.getSectionWorseTests(report, ReportSection.METABOLIC_ADVICE, tests)|| null

    setImprovedPrimaryTests(improvedTests?.filter(({ code }) => primaryCodes.includes(code)) || []);
    setImprovedSecondaryTests(
      improvedTests?.filter(({ code }) => {
        return PatientHelper.isFemale(user)
          ? !primaryCodes.includes(code) && !femaleBlacklist.includes(code)
          : !primaryCodes.includes(code);
      }) || []
    );

    setStaticPrimaryTests(staticTests?.filter(({ code }) => primaryCodes.includes(code) && !(code === TestCode.BMI && ReportHelper.getTestValue(TestCode.BMI, report, results ,tests) < 26) ) || []);

    setStaticSecondaryTests(
      staticTests?.filter(({ code }) => {
        return (PatientHelper.isFemale(user)
          ? !primaryCodes.includes(code) && !femaleBlacklist.includes(code)
          : !primaryCodes.includes(code)) 

      }) || []
    );

    setWorsePrimaryTests(worseTests?.filter(({ code }) => primaryCodes.includes(code))|| []);
    setWorseSecondaryTests(
      worseTests?.filter(({ code }) => {
        return PatientHelper.isFemale(user)
          ? !primaryCodes.includes(code) && !femaleBlacklist.includes(code)
          : !primaryCodes.includes(code);
      })|| []
    );

    // what are new tests that were run, e.g. LP-IR
    const oldCodes = new Set([
      ...(staticTests ? staticTests : []),    // Only include staticTests if it's not null or undefined
      ...(worseTests ? worseTests : []),      // Only include worseTests if it's not null or undefined
      ...(improvedTests ? improvedTests : []) // Only include improvedTests if it's not null or undefined
    ].map(test => test.code));
    
    setNewSuboptimalTests(
      metabolicTests.filter(test => !oldCodes.has(test.code) && primaryCodes.includes(test.code))
    );
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !primaryTests || !results || !tests) return null;

    const hasRiskyTests = primaryTests.some((test) => {
      const riskLevel = ReportHelper.getTestRisk(
        test.code,
        report,
        results,
        tests
      );
      return (
        riskLevel &&
        riskLevel !== RiskLevel.OPTIMAL &&
        riskLevel !== RiskLevel.UNKNOWN
      );
    });

    if (ReportHelper.hasCondition(report, Condition.ISOLATED_GLUCOSE))
      return <>Your <span>metabolic health</span> appears to have <span>declined</span>, possibly due to temporary fluctuations in glucose levels.</>

    else if (isFixed && !hasRiskyTests) {
      return (
        <>
          Your <span>metabolic health</span> is{" "}
          {hasRiskyTests ? "almost" : "now"} <span>optimal</span>.
          Congratulations on this milestone!
        </>
      );
    } 
    
    else if (isBetter || (isFixed && hasRiskyTests)) {
      return (
        <>
          Your <span>metabolic health</span> has <span>improved</span> since
          your last blood draw.
        </>
      );
    }

    else if (isWorse) {
      return (
        <>
          Your <span>metabolic health</span> has <span>declined</span> since
          your last blood draw.
        </>
      );
    }

    else if (isStatic) {
      return (
        <>
          Your <span>metabolic health</span> remains relatively <span>unchanged</span> since
          your last blood draw.
        </>
      );
    }

    else if (ReportHelper.getSectionIndex(report, ReportSection.METABOLIC_ADVICE)===0)
      return (
        <>
          Let's focus on where you can make the most progress:{" "}
          <span>your metabolic health</span>.
        </>
      );
    
    else {
      const isAthlete = PatientHelper.isAthlete(user)

      if (isAthlete) {
        return (        <>
           These <span>{converter.toWords(primaryTests?.length)} metabolic biomarkers</span> indicate the early stages of <span>insulin resistance</span>.
        </>)
      }

      return (
        <>
          Your <span>metabolic health</span> shows signs of {!isPrediabetic && <> early </>} <span>insulin resistance</span>, a condition that can escalate to diabetes.
        </>
      );
    }
  };


  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription)

    if (!report) return 
    if (isFixed || isBetter) {
      if (!improvedPrimaryTests?.length) return null
    }
    else {
      if (!primaryTests?.length  || !report ) return null;
    }

    const isAthlete = PatientHelper.isAthlete(user)

    let content = <></>
    const staticAndWorseTests = [...staticPrimaryTests, ...worsePrimaryTests]

    if ((isFixed || isBetter) && improvedPrimaryTests?.length) {

      content = (
        <>
          {improvedPrimaryTests?.length === 1
            ? `Your ${LowercaseTest[improvedPrimaryTests[0].code]} has`
            : `These ${converter.toWords(
                improvedPrimaryTests?.length
              )} biomarkers have `}{" "}
          improved, lowering your risk for diabetes and restoring order to your
          metabolic health, your body's ability to convert food into energy.

          <div id="test-view-improved-primary-tests" />
          
          {staticAndWorseTests?.length>0 &&
            <>
            {staticAndWorseTests?.length === 1 
              ? <>{improvedPrimaryTests?.length > 0 ? "But your" : "Your "} {LowercaseTest[staticAndWorseTests[0].code]} levels {staticPrimaryTests?.length>0 ? "haven't moved significantly enough to show clear improvement" : " are getting worse"}, so it's important to closely monitor this despite the positive changes in your overall metabolic health.</>
              : <>{improvedPrimaryTests?.length > 0 ? "But these" : "These "} {converter.toWords(staticAndWorseTests?.length)} biomarkers haven't moved significantly or are getting worse, so it's important to closely monitor this despite the positive changes in your overall metabolic health.</>
            }
            <div id="test-view-static-and-worse-primary-tests" />
            </>
          }

          Stick to your game plan and make sure your metabolic health continues moving in the right direction for your next blood draw.
        </>
      );
      return content;
    } 

    else if (isStatic) {
      content = (
        <>
          Metabolic health is your body's ability to convert food into energy and efficiently use and store that energy.<br/><br/>

          {improvedPrimaryTests?.length > 0 && 
            <>
            { improvedPrimaryTests?.length === 1
              ? `On a positive note, your ${improvedPrimaryTests[0].code === TestCode.HOMAIR ? "HOMA-IR, a standard measure for insulin resistance," : LowercaseTest[improvedPrimaryTests[0].code]} has`
              : `On a positive note, these ${converter.toWords(improvedPrimaryTests?.length)} risk factors for diabetes and other chronic metabolic diseases have `}{" "}
            improved. 

            <div id="test-view-improved-primary-tests" />
          </>
          }

          {staticAndWorseTests?.length>0 &&
            <>
            {staticAndWorseTests?.length === 1 
              ? <>{improvedPrimaryTests?.length > 0 ? "But your" : "Your "} {LowercaseTest[staticAndWorseTests[0].code]} levels {staticPrimaryTests?.length>0 ? "haven't moved significantly to show clear improvement" : " are getting worse"}, {improvedPrimaryTests?.length > 0 ? "which means despite the other improvements, " : "which means "}your metabolic health is not actually improving.</>
              : <>{improvedPrimaryTests?.length > 0 ? "But these" : "These "} {converter.toWords(staticAndWorseTests?.length)} biomarkers haven't moved significantly or are getting worse, {improvedPrimaryTests?.length > 0 ? "which means despite the other improvements, " : "which means "}your metabolic health is not actually improving.</>
            }
            <div id="test-view-static-and-worse-primary-tests" />
            </>
          }


          Stick to your game plan to help make sure your metabolic health begins moving in the right direction for your next blood draw. 

        </>
      );
      return content;
    } 

    else if ((isWorse) && worsePrimaryTests?.length) {
      content = (
        <>        
          {worsePrimaryTests?.length === 1
              ? `Your ${LowercaseTest[worsePrimaryTests[0].code]} has `
              : `These ${converter.toWords(worsePrimaryTests?.length)} biomarkers have `}{" "}
            gotten worse since your last blood draw. This is a usually sign that your metabolic health, your body's ability to convert food into energy, has been disrupted and increasing your risk for diabetes.

        <div id="test-view-worse-primary-tests" />

        {staticPrimaryTests?.length>0 && <>
            {staticPrimaryTests?.length === 1
                ? <>{worsePrimaryTests?.length>0 ? `Moreover` : `On the other hand`}, your {LowercaseTest[staticPrimaryTests[0].code]} levels </>
                : <>{worsePrimaryTests?.length>0 ? `Moreover` : `On the other hand`}, these {converter.toWords(staticPrimaryTests?.length)} biomarkers </>}
              have not moved in a clinically significant manner. This also requires careful monitoring and intervention for optimal metabolic health.

            <div id="test-view-static-primary-tests" />
            </>
          }

          {newSuboptimalTests?.length>0 && <>
            {newSuboptimalTests?.length === 1
                ? <>{newSuboptimalTests?.length>0 ? `Also` : `On the other hand`}, your {LowercaseTest[newSuboptimalTests[0].code]} levels </>
                : <>{newSuboptimalTests?.length>0 ? `Also` : `On the other hand`}, these new {converter.toWords(newSuboptimalTests?.length)} biomarkers </>}
              are suboptimal.

            <div id="test-view-newsuboptimal-metabolic-tests" /></>}


        {improvedPrimaryTests?.length>0 && <>On a positive note,  
          {improvedPrimaryTests?.length === 1
            ? ` your ${LowercaseTest[improvedPrimaryTests[0].code]} has`
            : ` these ${converter.toWords(
                improvedPrimaryTests?.length
              )} metabolic biomarkers have `}{" "}
          improved.

          <div id="test-view-improved-primary-tests" />
        </>}

          {ReportHelper.hasCondition(report, Condition.ISOLATED_GLUCOSE)  && <>That said, your  <span className="view-test" id={`view-test-${TestCode.HBA1C}`} text="hbA1c" /> is optimal, which offers a broader picture of overall glucose control, unlike single glucose tests that can fluctuate due to immediate factors like recent food intake, physical activity, stress, and illness.<br/><br/></>}

          Stick to your game plan to help make sure your metabolic health moves in the right direction for your next blood draw.
        </>
      );

      return content
    }

    else {
    if (isDiabetic === true) {
      return (
        <>
          {primaryTests?.length === 1
            ? `Your elevated ${primaryTests[0].name} level is`
            : `These ${converter.toWords(
                primaryTests?.length
              )} biomarkers are `}{" "}
          consistent with insulin resistance at the level of diabetes, which means the tissues in your body are having
          trouble utilizing glucose correctly for daily function.

          <br />
        <br />
        Over time, this will damage your
        metabolic health, your body's ability to convert food into energy.
        
        <div id="test-view-worse-primary-tests" />

        <>
          {PatientHelper.getAge(user) <= 40 && "The good news is that you are catching this early, while you're young."} By making a small number of changes, you can
          significantly improve your metabolic health.
        </>

        </>
      );
    } 

    else if (isPrediabetic === true) {
      content = (
        <>
          {primaryTests?.length === 1
            ? `Your elevated ${LowercaseTest[primaryTests[0].code]} level suggests`
            : `These ${converter.toWords(primaryTests?.length)} biomarkers suggest `}{" "}
          that you may be prediabetic and the tissues in your body are having
          trouble utilizing glucose correctly for daily function.

          <br />
        <br />
        Over time, this can lead to the development of diabetes and damage your
        metabolic health, your body's ability to convert food into energy.
        </>
      );
    } 
    else if (primaryTests.some(({ code }) => code === TestCode.INSULIN)) {

      const optimalSugarLevels = !(ReportHelper.hasCondition(report, Condition.ELEVATED_GLUCOSE) || ReportHelper.hasCondition(report, Condition.ELEVATED_HBA1C))

      if (optimalSugarLevels) {

        content = (
          <>While your <span className="view-test" id={`view-test-${TestCode.GLUCOSE}`} text="fasting glucose" /> and <span className="view-test" id={`view-test-${TestCode.HBA1C}`} text="hbA1c" />  levels are currently not elevated, 
            {primaryTests?.length === 1
              ? ` your elevated ${LowercaseTest[primaryTests[0].code]} level suggests`
              : ` these other ${converter.toWords(primaryTests?.length)} biomarkers suggest `}{" "}
            that your body's tissues are becoming less sensitive to insulin, impairing their ability to efficiently utilize glucose.
  
            <br />
          <br />
          If left unaddressed for too long, this can eventually lead to elevated glucose and HbA1c levels, negatively affecting your metabolic health, your body's ability to convert food into energy, and increasing your risk of diabetes. 
          </>
        );
      }

      else {

        content = (
          <>
            {primaryTests?.length === 1
              ? `Your elevated ${LowercaseTest[primaryTests[0].code]} level suggests`
              : `These ${converter.toWords(primaryTests?.length)} biomarkers suggest `}{" "}
            that the tissues in your body are losing sensitivity to insulin and
            are having trouble utilizing glucose efficiently (aka insulin resistance).
  
            <br />
          <br />
          Over time, this can lead to the development of diabetes and damage your
          metabolic health, your body's ability to convert food into energy.
          </>
        );
      }

    } 
    else {
      content = (
        <>
          {primaryTests?.length === 1
            ? `Your elevated ${LowercaseTest[primaryTests[0].code]} level suggests`
            : `These ${converter.toWords(primaryTests?.length)} biomarkers suggest `}{" "}
          that the tissues in your body are having trouble utilizing glucose
          efficiently for daily function.

          <br />
          <br />
        Over time, this can lead to the development of diabetes and damage your
        metabolic health, your body's ability to convert food into energy.
        </>
      );
    }
    return (
      <>
        {content}

        <div id="test-view-primary-tests" />

        <>
          The good news is that you've identified this early, which gives you a great opportunity to reverse the insulin resistance quickly. Implementing simple changes can significantly reduce your risk{isAthlete && <> and help maintain your peak performance</>}.
        </>
      </>
    );
  }};

  const getSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription)

    if (!report) return null;
    let content = null

    const staticAndWorseTests = [...(staticSecondaryTests ? staticSecondaryTests : []), ...(worseSecondaryTests ? worseSecondaryTests : [])];

    if (ReportHelper.hasCondition(report, Condition.ISOLATED_GLUCOSE)) {
      return <></>
    }

    if (isStatic && staticSecondaryTests?.length>0) {
      content =
      <>
        In addition,{" "}
        {staticSecondaryTests?.length === 1
          ? `your ${LowercaseTest[staticAndWorseTests[0].code]} level ${staticSecondaryTests?.length>0 ? "hasn't moved significantly enough to show clear improvement" : " is getting worse"}`
          : `these ${converter.toWords(
            staticAndWorseTests?.length
            )} biomarkers also haven't moved significantly or are getting worse`}
        , likely due to your metabolic health remaining static. As you improve your metabolic health, these biomarkers will most likely improve.

        <div id="test-view-static-and-worse-secondary-tests" />
      </>
    }

    else if (isWorse && staticAndWorseTests.length>0) {
      content =
        <>
          In addition,{" "}
          {staticAndWorseTests?.length === 1
            ? `your ${LowercaseTest[staticAndWorseTests[0].code]} level ${staticSecondaryTests?.length>0 ? "hasn't moved significantly enough to show clear improvement" : " is getting worse"}`
            : `these ${converter.toWords(
              staticAndWorseTests?.length
              )} biomarkers also haven't moved significantly or are getting worse`}
          , likely due to your metabolic health not improving. As you improve your metabolic health, these biomarkers will most likely improve.

          <div id="test-view-static-and-worse-secondary-tests" />
        </>
    }
    else if ((isFixed || isBetter) && improvedSecondaryTests?.length>0) {
      content =
        <>
          In addition,{" "}
          {improvedSecondaryTests?.length === 1
            ? `your ${LowercaseTest[improvedSecondaryTests[0].code]} level is`
            : `these ${converter.toWords(improvedSecondaryTests?.length)} biomarkers are`}{" "}
          also improving, likely due to your metabolic health improving.
        
          <div id="test-view-improved-secondary-tests" />
        </>
    }

    else if ((!isWorse || !isStatic || !isBetter || !isFixed) && secondaryTests?.length>0) {
      content = (
        <>
          In addition,{" "}
          {secondaryTests?.length === 1
            ? `your ${LowercaseTest[secondaryTests[0].code]} level is`
            : `these ${converter.toWords(
                secondaryTests?.length
              )} biomarkers are`}{" "}
          not optimal, probably due to your underlying metabolic issues. Improving your metabolic health  should also improve{" "}
          {secondaryTests?.length === 1 ? "this biomarker" : "these biomarkers"}.

          <div id="test-view-secondary-tests" />
        </>
      );

    }

    return content

  };

  return (
    user &&
    primaryTests &&
    secondaryTests && (
      <div className="metabolic-advice">

        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={testId}
          setTestId={setTestId}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>

        <AdviceSection 
          report={report} 
          section={section}
          results={results}
          tests={tests}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />

      
        {secondaryDescription && (
          <>
            <Paragraph className={classNames( "report-template-description", "secondary-tests-description")}>
              {secondaryDescription}
            </Paragraph>
          </>
        )}

      </div>
    )
  );
};
