import { useState, useEffect, useContext } from 'react';
import { Button, Col, Row, Typography, Tag, Tooltip } from 'antd';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { listProductTypes } from '../../services/productType.service';
import { ReactComponent as UrineSvg } from "../../assets/svg/urine_sample.svg";
import { ReactComponent as BloodSvg } from "../../assets/svg/blood_drop.svg";
import { ReactComponent as CTSvg } from "../../assets/svg/tac.svg";
import { ReactComponent as BPSvg } from "../../assets/svg/blood_pressure.svg";
import { ReactComponent as SalivaSvg } from "../../assets/svg/saliva.svg"
import CollectionMethod from '../../enums/collectionMethod.enum';
import Breakpoint from '../../enums/breakpoint.enum';
import ProductHelper from "../../helpers/product.helper";
import PosthogHelper from '../../helpers/posthog.helper';
import EventCode from '../../enums/eventCode.enum';
import './testCatalog.scss';
import useWidth from '../../hooks/useWidth.hook';
import { UserContext } from '../../contexts/user.context';
import { DownOutlined } from "@ant-design/icons";
import { addSlack } from "../../services/slack.service"
import {RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';


const { Paragraph } = Typography;

export const TestCatalog = ({ xs = 24, sm = 12, md = 12, lg = 8, xl = 8, xxl = 8, showAll = false }) => {
  const { currentUser, longevityMembership } = useContext(UserContext);
  const width = useWidth();
  const navigate = useNavigate()

  const [showAllState, setShowAllState] = useState(showAll);
  const [panels, setPanels] = useState([]);

  useEffect(() => {
    trackPosthog();
  }, [currentUser]);

  const trackPosthog = () => {
    if (currentUser) {
      PosthogHelper.track(currentUser, EventCode.TEST_CATALOG_VIEW);
    }
  };

  let panelCodes = [
    ProductTypeCode.HEART_HEALTH_TEST_KIT,
    ProductTypeCode.CTCALCIUM,
    ProductTypeCode.GALLERI,
    ProductTypeCode.WATCHPAT_ONE,
    ProductTypeCode.FERTILITY,
    ProductTypeCode.TESTOSTERONE_PANEL_KIT,
    ProductTypeCode.LONGEVITY_GENETIC_TEST,
    ProductTypeCode.DEXA_BODYCOMP,
    ProductTypeCode.DEXA_BONE,
    ProductTypeCode.BIOBEAT,
    ProductTypeCode.TOXIC_METALS_URINE_TEST,
    ProductTypeCode.MYCOTOX_PROFILE,
    ProductTypeCode.VO2MAX,
    ProductTypeCode.CGM,
    ProductTypeCode.CLEERLY,
    ProductTypeCode.PRENUVO,
    ProductTypeCode.TRUAGE_COMPLETE,
    ProductTypeCode.DIURNAL_CORTISOL_PROFILE
  ];

  if (currentUser?.isAthlete)  {
    panelCodes = [ProductTypeCode.ATHLETE_PANEL, ...panelCodes]
  }
  else
    panelCodes = [ProductTypeCode.LONGEVITY_PANEL, ...panelCodes]


  useEffect(() => {
    fetchPanels();
  }, []);


  const fetchPanels = async () => {
    const fetchedPanels = await listProductTypes({
      filter: {
        code: {
          $in: panelCodes
        }
      }
    });
    // Sort panels based on the order in panelCodes
    const sortedPanels = fetchedPanels.sort((a, b) => {
      return panelCodes.indexOf(a.code) - panelCodes.indexOf(b.code);
    });
    setPanels(sortedPanels);
  };

  const displayPanels = showAllState ? panels : panels.slice(0, 6);

  const TestPanel = ({ test, isMostPopular, isNew }) => {
    const handlePanelClick = async () => {
      PosthogHelper.track(currentUser, `[${EventCode.TEST_CATALOG_CLICK}] ${test.code}`);
  
      try {
        await addSlack({
          message: currentUser.firstName ? `[${currentUser.firstName} ${currentUser.lastName}] Test Catalog Click: ${test.code}` : `Test Catalog Click: ${test.code}`,
          channel: process.env.REACT_APP_PATIENT_SLACK
        });
      } catch (error) {
        console.error('Failed to log message to Slack:', error);
      }
  
      if (test.path) {
        navigate(test.path);
      } else if (test.flowType) {
        navigate(`/flow/${test.flowType}`);
      }
    };
  
    return (
      <div
        className={`test-catalog-item ${isMostPopular ? 'most-popular' : isNew ? 'is-new': ''}`}
        onClick={handlePanelClick} // Make the entire panel clickable
      >
        <div className="test-labels">
          <div className="test-label-tags">
            {isMostPopular && <Tag className="most-popular-tag">Most Popular</Tag>}
            {isNew && <Tag className="is-new-tag">New</Tag>}
            {test.memberOnly && <Tag className="member-only tag">member only</Tag>}
            
            {test.selfCollection ?  <Tag className="self-collect tag">self collect</Tag> : test.atHome ? <Tag className="at-home tag">at home</Tag> : <Tag className="at-office tag">office visit</Tag>}
          </div>
          {!isMostPopular && !isNew && <div className="test-label-icons">
            {test.collectionMethod === CollectionMethod.BLOOD && <BloodSvg className="test-label-svg blood" />}
            {test.collectionMethod === CollectionMethod.URINE && <UrineSvg className="test-label-svg urine" />}
            {test.collectionMethod === CollectionMethod.CT_SCAN && <CTSvg className="test-label-svg scan" />}
            {test.collectionMethod === CollectionMethod.MRI_SCAN && <CTSvg className="test-label-svg scan" />}
            {test.collectionMethod === CollectionMethod.FDA_DEVICE && <BPSvg className="test-label-svg device" />}
            {test.collectionMethod === CollectionMethod.SALIVA && <SalivaSvg className="test-label-svg saliva"/>}
          </div>}
        </div>
        <Paragraph className="test-title" ellipsis={1}>
          {test.title}
        </Paragraph>
        <Paragraph className="test-description" ellipsis={{ rows: width > Breakpoint.SM ? 2 : 3 }}>
          {test.tagline}
        </Paragraph>
        <div className="learn-more-button-container">
          <div className="test-price">
            {ProductHelper.formatDiscountCost(test, longevityMembership, false, false)===0 
              ? "FREE" 
              : <>{test.cost ? <>${test.cost.toLocaleString()}</> : <Tooltip title="Price is based on your location. Instalab will find the best price for you.">Price varies</Tooltip>}</>
            }
            {/* {longevityMembership && test.cost ? (
              <>
                <span className="regular-price">${test.cost.toLocaleString()}</span>
                <span className="member-price">{ProductHelper.formatDiscountCost(test, longevityMembership, false)===0 ? "FREE" :<>${ProductHelper.formatDiscountCost(test, longevityMembership, false)}</> }</span>
              </>
            ) : (
              <>{test.cost ? <>${test.cost.toLocaleString()}</> : <Tooltip title="Price is based on your location. Instalab will find the best price for you.">Price varies</Tooltip> }</>
            )} */}
          </div>
          <a
              className='secondary-link learn-more-button'
            >
              Learn More <RightOutlined style={{fontSize:10}}/>
          </a>
        </div>
      </div>
    );
  };
  
  
  return panels.length > 0 && (
    <div className="test-catalog">
      <Row gutter={24}>
      {displayPanels.map((test, index) => (
        <Col
          key={`panel-${test.code}`}
          xs={{ span: xs }}
          sm={{ span: sm }}
          md={{ span: md }}
          lg={{ span: lg }}
          xl={{ span: xl }}
          xxl={{ span: xxl }}
        >
          <TestPanel 
            test={test} 
            isMostPopular={index===0} 
            // isNew={index===1} 
          />
        </Col>
      ))}
        
      </Row>
      {!showAllState && panels.length > 6 && (
        <div className="view-more-wrapper">
          <Button
            className="view-btn"
            onClick={() => setShowAllState(true)}
          >
            View More
            <DownOutlined className="view-btn-icon" />
          </Button>
        </div>
      )}
    </div>
  );
};
