import { Typography, Row, Col, Button } from "antd";
import React, { useEffect, useState } from "react";
import { EventType, ReportSection } from "../../../enums/index.enum";
import { addEvent } from "../../../services/event.service";
import "./nextSteps.scss";
import ReportHelper from "../../../helpers/report.helper";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import FlowType from "../../../enums/flowType.enum";
import PatientHelper from "../../../helpers/patient.helper";
import { ProductCode } from "../../../data/product.data";

const { Paragraph } = Typography;

export const NextSteps = ({ report, user, tests, results }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isMember, setIsMember] = useState();
  const [isHeartHealthMember, setIsHeartHealthMember] = useState();
  const [showHeartHealthSection, setShowHeartHealthSection] = useState(false);
  const isAtheletePanel = ReportHelper.isAthletePanel(report);

  useEffect(() => {
    if (!user) return;
  
    const fetchData = async () => {
      const memberStatus = await PatientHelper.isInstalabMember(user);
      setIsMember(memberStatus);
  
      const hhMemberStatus = await PatientHelper.isHeartHealthMember(user);
      setIsHeartHealthMember(hhMemberStatus !== undefined);
    };
  
    fetchData();
  }, [user]);
  
  useEffect(() => {
    if (!report || !tests || !results) return;
  
    const fetchData = async () => {
      let p = ReportHelper.getProducts(report, results, tests);
  
      // Check if Heart Health Membership is in the products
      const hasHeartHealthProduct = p.some(
        (product) => product.code === ProductCode.HEART_HEALTH_MEMBERSHIP
      );
  
      // Only show Heart Health section if the product exists and the user is not already a member
      if (hasHeartHealthProduct && !isHeartHealthMember) {
        // Define the section codes you are checking for
        const relevantSections = [
            ReportSection.METABOLIC_ADVICE,
            ReportSection.CARDIO_ADVICE,
            ReportSection.BP_ADVICE,
        ];
                
        // Check if the first section is one of the relevant sections
        const firstIsRelevant = relevantSections.includes(report.sections[1]?.code) 

        // Find if at least one of the relevant sections exists in the report
        const hasRelevantSection = report.sections.some((section) =>
        relevantSections.includes(section.code)
        );

        if (firstIsRelevant && hasRelevantSection) {
            setShowHeartHealthSection(true);
        }
      } else {
        setShowHeartHealthSection(false);
      }
  
      // Remove all instances of Heart Health Membership from products
      p = p.filter((product) => ![ProductCode.HEART_HEALTH_MEMBERSHIP, ProductCode.METABOLIC_HEALTH_CONSULT].includes(product.code));
  
      setProducts(p);
    };
  
    fetchData();
  }, [report, results, tests, isHeartHealthMember]);

  const onScheduleConsultation = async () => {
    const url = isAtheletePanel
      ? `https://calendly.com/d/cp32-v2b-dsx/athlete-consult?utm_content=${user._id}&email=${user.email}&name=${user.firstName}+${user.lastName}`
      : "/flow/longevity-consult";
    navigate(url);

    await addEvent({ eventType: EventType.CLICK_CONSULTATION_REPORT });
  };

  const onScheduleDraw = async () => {
    const url = isAtheletePanel
      ? `/flow/${FlowType.ATHLETE_TEST}`
      : `/flow/${FlowType.LONGEVITY_TEST}`;
    window.open(url, '_blank'); // Opens the URL in a new tab
    await addEvent({ eventType: EventType.CLICK_REPORT_SUMMARY_BUTTON_SCHEDULE });
  };
  
  const onJoinHeartHealth = async () => {
    window.open("/heart", '_blank'); // Opens the Heart Health page in a new tab
    await addEvent({ eventType: EventType.CLICK_REPORT_SUMMARY_BUTTON_HH });
  };

  // Define getDateString function
  const getDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
  };

  const handleReviewClick = async () => {
    const element = document.getElementById(report?.sections[1].code);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    await addEvent({ eventType: EventType.CLICK_REPORT_SUMMARY_BUTTON_REPORT });
  };

  const ProductLinks = ({ products }) => {
    const handleOpenAllLinks = async () => {
      if (products && products.length > 0) {
        products.forEach((supplement) => {
          if (supplement?.buyUrl) {
            window.open(supplement.buyUrl, "_blank"); // Opens each link in a new tab
          }
        });
      }
      await addEvent({ eventType: EventType.CLICK_REPORT_SUMMARY_BUTTON_PRODUCTS });
    };

    return (
      products && (
        <Row gutter={12} className="summary-item">
          <Col span={20} className="summary-item-description">
            <div className="advice-item-description">
              Obtain your&nbsp;
              {products.map((supplement, index) => (
                <span key={`supplement-${index}`}>
                  {products.length > 1 && index === products.length - 1 && " and "}
                  <a href={supplement?.buyUrl} target="_blank" rel="noopener noreferrer">
                    {supplement?.genericName}
                  </a>
                  {index < products.length - 2 && products.length > 2 && ", "}
                </span>
              ))}
              .
            </div>
          </Col>
          <Col span={4} className="summary-item-button">
            <Button onClick={handleOpenAllLinks}> &rarr;</Button>
          </Col>
        </Row>
      )
    );
  };

  return (
    user &&
    products && 
    report && (
      <div className="summary-next-steps">
        <div className={classNames("advice-section", "next-steps-section")}>
          <Paragraph className="advice-title">
            Simple steps to make the most of your {getDateString(report?.result?.collectedAt)} results:
          </Paragraph>

          <Row gutter={12} className="summary-item">
            <Col span={20} className="summary-item-description">
              <div className="advice-item-description">Review this report and begin your personalized game plan.</div>
            </Col>
            <Col span={4} className="summary-item-button">
              <Button onClick={handleReviewClick}> &rarr;</Button>
            </Col>
          </Row>

          { showHeartHealthSection ? (
            <Row gutter={12} className="summary-item">
              <Col span={20} className="summary-item-description">
                <div className="advice-item-description">
                  Get a <a onClick={onJoinHeartHealth}>Heart Health Coach</a> for expert, personalized support in your top focus area.
                </div>
              </Col>
              <Col span={4} className="summary-item-button">
                <Button onClick={onJoinHeartHealth}> &rarr;</Button>
              </Col>
            </Row>
          ) :            <Row gutter={12} className="summary-item">
          <Col span={20} className="summary-item-description">
            <div className="advice-item-description">Consult with longevity physician to discuss results and clarify questions.</div>
          </Col>
          <Col span={4} className="summary-item-button">
            <Button onClick={onScheduleConsultation}> &rarr;</Button>
          </Col>
        </Row>}

          <ProductLinks products={products} />

          <Row gutter={12} className="summary-item">
            <Col span={20} className="summary-item-description">
              <div className="advice-item-description">Schedule a follow-up blood test in 3 months to track your progress.</div>
            </Col>
            <Col span={4} className="summary-item-button">
              <Button onClick={onScheduleDraw}> &rarr;</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  );
};