import React, { useEffect, useState } from 'react';
import { Typography, Tooltip as AntTooltip, Dropdown, Spin, message, Row, Col } from 'antd'
import { SyncOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import './adminHeartHealth.scss';
import dayjs from 'dayjs'
import moment from 'moment'
import { PageHeader } from '../pageHeader/pageHeader.component';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useNavigate } from 'react-router-dom';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { refillPrescription, updatePrescriptionStatus } from '../../services/prescription.service.js';
import { getData } from '../../services/heart.service.js';
import classNames from 'classnames';
import PrescriptionStatus from '../../enums/prescriptionStatus.enum.js';
import { listTests } from '../../services/test.service.js';
import TestCode from '../../enums/testCode.enum.js';
import RiskLevel from '../../enums/riskLevel.enum.js';


import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns'; // Import date-fns adapter for time scale
import { listAppointments } from '../../services/appointment.service.js';

// Register the required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, TimeScale);

dayjs.extend(utc)
dayjs.extend(timezone)

const heartTestCodes = [
  TestCode.DIRECT_LDL,
  TestCode.APO_B,
  TestCode.TG,
  TestCode.VLDL,
  TestCode.HDL,
  TestCode.LIPOPROTEIN_A,
  TestCode.HS_CRP,
]

const metabolicTestCodes = [
  TestCode.GLUCOSE, 
  TestCode.HBA1C, 
  TestCode.HOMAIR
]

const bpTestCodes = [
  TestCode.BP_S, 
  TestCode.BP_D, 
]

const liverTestCodes = [
  TestCode.ALP, 
  TestCode.ALT, 
  TestCode.AST, 
  TestCode.TOTAL_BILIRUBIN, 
  TestCode.DIRECT_BILIRUBIN, 
  TestCode.GGT,
]

const heartColor = '#D9368B'
const metabolismColor = '#4A90E2'
const liverColor = '#9B59B6'
const bpColor = '#00BCD4'

const { Text } = Typography

export const AdminHeartHealth = () => {
  const navigate = useNavigate()
  const [memberships, setMemberships] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [filteredCount, setFilteredCount] = useState()
  const [tests, setTests] = useState()

  useEffect(() => {
    document.title = 'Instalab | Heart Health'
    fetchTests()
  }, [])

  useEffect(() => {
    fetchMemberships()
  }, [tests])

  const fetchTests = async () => {
    setIsLoading(true)
    setTests(await listTests({
      select: '_id code name',
      filter: {
        code: {
          $in: [
            ...heartTestCodes,
            ...metabolicTestCodes,
            ...liverTestCodes,
            ...bpTestCodes,
          ]
        }
      }
    }))
  }


  const fetchMemberships = async () => {
    if (!tests?.length) return

    const data = await getData()

    const fetchedMemberships = data.map(membership => {
      return {
        ...membership,
        trend: getTrend(membership)
      }
    })
    setMemberships(fetchedMemberships)
    setFilteredCount(fetchedMemberships.length)
    setIsLoading(false)
  }

  const isNumber = (value) => {
    return typeof value === 'number' && !isNaN(value);
  }

  const getTrend = (membership) => {
    const heartTests = tests.filter(({ code }) => heartTestCodes.includes(code))
    const metabolicTests = tests.filter(({ code }) => metabolicTestCodes.includes(code))
    const liverTests = tests.filter(({ code }) => liverTestCodes.includes(code))
    const bpTests = tests.filter(({ code }) => bpTestCodes.includes(code))
    
    return membership.results.map(result => {
        return {
          collectedAt: moment(result.collectedAt).format('MM/DD/YY'),
          trends: {
            heart: getResultScore(result, heartTests, [TestCode.APO_B]),
            metabolism: getResultScore(result, metabolicTests),
            liver: getResultScore(result, liverTests),
            bp: getResultScore(result, bpTests),
          }
        }
      })
      .filter(({ trends }) => {
        return isNumber(trends.heart) || isNumber(trends.metabolism) || isNumber(trends.liver) || isNumber(trends.bp)
      })
  }

  const TrendLineChart = ({ trend }) => {
    const [showHeart, setShowHeart] = useState(true)
    const [showMetabolism, setShowMetabolism] = useState(true)
    const [showLiver, setShowLiver] = useState(true)
    const [showBp, setShowBp] = useState(true)

    // Convert the trend data to Chart.js format
    const chartData = convertToChartData(trend);
  
    // Chart.js configuration options with fewer dates shown on x-axis
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time', // Use time scale
          time: {
            unit: 'month', // Adjust based on your time span (e.g., day, month, year)
          },
          ticks: {
            maxTicksLimit: 5, // Show a maximum of 5 date labels on the x-axis
          },
          title: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Disable the legend if not needed
        },
        title: {
          display: false, // Disable the title
        },
      },
    };

    const getLatestValue = (system) => {
      const trendItem = trend.find(({ trends }) => isNumber(trends[system]))
      if (!trendItem) return <Text className="no-value">N/A</Text>
      return `${trendItem.trends[system]}%`
    }

    const RecentScores = () => {
      return (
        <div className="recent-scores">
          <div className="score-item" onClick={() => setShowHeart(!showHeart)}>
            <span className="score-bullet" style={{ backgroundColor: showHeart ? heartColor : '#bbb' }}/>
            <Text className="score-label">Heart:</Text> <Text className="score-value">{getLatestValue('heart')}</Text>
          </div>

          <div className="score-item" onClick={() => setShowMetabolism(!showMetabolism)}>
            <span className="score-bullet" style={{ backgroundColor: showMetabolism ? metabolismColor : '#bbb' }}/>
            <Text className="score-label">Metabolism:</Text> <Text className="score-value">{getLatestValue('metabolism')}</Text>
          </div>

          <div className="score-item" onClick={() => setShowLiver(!showLiver)}>
            <span className="score-bullet" style={{ backgroundColor: showLiver ? liverColor : '#bbb' }}/>
            <Text className="score-label">Liver:</Text> <Text className="score-value">{getLatestValue('liver')}</Text>
          </div>

          <div className="score-item" onClick={() => setShowBp(!showBp)}>
            <span className="score-bullet" style={{ backgroundColor: showBp ? bpColor : '#bbb' }}/>
            <Text className="score-label">BP:</Text> <Text className="score-value">{getLatestValue('bp')}</Text>
          </div>
        </div>
      )
    }
  
    return <>
      <Row>
        <Col>
          <RecentScores />
        </Col>

        <Col>
          <div style={{ width: '400px', height: '150px', marginTop: 1 }}>
            <Line 
              data={{
                datasets: chartData.filter(({ code }) => {
                  if (!showHeart && code === 'heart') return false
                  if (!showMetabolism && code === 'metabolism') return false
                  if (!showLiver && code === 'liver') return false
                  if (!showBp && code === 'bp') return false
                  return true
                })
              }} 
              options={options} 
            />
          </div>
        </Col>
      </Row>
    </>
  };
  
  // Function to convert the trend to Chart.js data format
  function convertToChartData(trend) {
    const heartData = [];
    const metabolismData = [];
    const liverData = [];
    const bpData = [];
    const labels = [];
  
    trend.forEach(item => {
      labels.push(item.collectedAt); // Collect dates
      if (isNumber(item.trends.heart)) {
        heartData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.heart,
        });
      }
      if (isNumber(item.trends.metabolism)) {
        metabolismData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.metabolism,
        });
      }
      if (isNumber(item.trends.liver)) {
        liverData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.liver,
        });
      }
      if (isNumber(item.trends.bp)) {
        bpData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.bp,
        });
      }
    });
  
    return [
      {
        code: 'heart',
        label: 'Heart',
        data: heartData,
        borderColor: heartColor,
        fill: false,
        tension: 0.3,
        spanGaps: true,
      },
      {
        code: 'metabolism',
        label: 'Metabolism',
        data: metabolismData,
        borderColor: metabolismColor,
        fill: false,
        tension: 0.3,
        spanGaps: true,
      },
      {
        code: 'liver',
        label: 'Liver',
        data: liverData,
        borderColor: liverColor,
        fill: false,
        tension: 0.3,
        spanGaps: true,
      },
      {
        code: 'bp',
        label: 'Blood Pressure',
        data: bpData,
        borderColor: bpColor,
        fill: false,
        tension: 0.3,
        spanGaps: true,
      },
    ]
  }  

  const getResultScore = (result, tests, requiredTestCodes=[]) => {
    const RiskScore = {
      [RiskLevel.HIGH]: 0,
      [RiskLevel.MODERATE]: 1,
      [RiskLevel.OPTIMAL]: 2,
    }

    const isMissingRequiredTest = requiredTestCodes.some(code => {
      return !result.values.some(value => {
        const test = tests.find(t => t.code === code)
        return value.risk && value.test === test._id
      })
    })

    if (isMissingRequiredTest) {
      return null
    }

    const risks = result.values
      .filter(({ test }) => tests.some(({ _id }) => _id === test))
      .map(({ risk }) => risk)
      .filter(risk => risk)

    const resultScore = risks.reduce((acc, risk) => {
      acc += RiskScore[risk]
      return acc
    }, 0)/(risks.length * 2)*100

    return isNumber(resultScore) ? Math.round(resultScore) : null
  }

  const getActionItems = ({ _id }) => {
    let menuItems = []
    return menuItems
  }

  const getCustomFilter = (membership, value) => {
    return {
      patient: () => `${membership.patient.firstName.toLowerCase()} ${membership.patient.lastName.toLowerCase()}`.includes(value.toLowerCase()),
    }
  }

  const getRefillDate = prescription => {
    let { daysToRefill, gogo } = prescription
    if (gogo && !daysToRefill) {
      const { doseQty=1, units } = gogo
      daysToRefill = units / doseQty
    }
    if (daysToRefill) {
      // Buffer to ship medication
      daysToRefill -= 7
      return moment(prescription.createdAt).add(daysToRefill, 'days').toDate()
    }
    return null
  }

  const PrescriptionItem = ({ prescription: defaultPrescription }) => {
    const [refillDate, setRefillDate] = useState()
    const [status, setStatus] = useState()
    const [isProcessing, setIsProcessing] = useState()
    const [prescription, setPrescription] = useState()

    useEffect(() => {
      setPrescription(defaultPrescription)
    }, [defaultPrescription])

    useEffect(() => {
      fetchData()
    }, [prescription])

    const fetchData = () => {
      if (!prescription) return
      const fetchedRefillDate = getRefillDate(prescription)
      setRefillDate(fetchedRefillDate)
      if (prescription.status !== PrescriptionStatus.PAUSED && fetchedRefillDate < new Date()) {
        setStatus('empty')
      } else {
        setStatus(prescription.status)
      }
    }

    const onRefill = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await refillPrescription(prescription._id))
        message.info('Prescription refilled')
      } catch (err) {
        message.error('Failed to refill')
      }
      setIsProcessing(false)
    }

    const onPause = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await updatePrescriptionStatus(prescription._id, { status: PrescriptionStatus.PAUSED }))
        message.info(`Prescription paused`)
      } catch (err) {
        message.error('Failed to pause')
      }
      setIsProcessing(false)
    }

    const onRestart = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await updatePrescriptionStatus(prescription._id, { status: PrescriptionStatus.SENT }))
        message.info(`Prescription restarted`)
      } catch (err) {
        message.error('Failed to restart')
      }
      setIsProcessing(false)
    }

    return (
      <Dropdown
        overlayStyle={{
          width: 150
        }}
        menu={{
          items: [{
            key: 'refill',
            label: (
              <a onClick={onRefill}>
                <SyncOutlined style={{ marginRight: 5 }} /> Refill
              </a>
            )
          }, status !== PrescriptionStatus.PAUSED && {
            key: 'pause',
            label: (
              <a onClick={onPause}>
                <PauseCircleOutlined style={{ marginRight: 5 }} /> Pause
              </a>
            )
          }, status === PrescriptionStatus.PAUSED && {
            key: 'restart',
            label: (
              <a onClick={onRestart}>
                <PlayCircleOutlined style={{ marginRight: 5 }} /> Restart
              </a>
            )
          }]
        }}
        placement='bottom'
      >
        <AntTooltip
          title={(refillDate && status !== PrescriptionStatus.PAUSED) ? `Refill: ${moment(refillDate).format('MMM D, YYYY')}` : ''}
        >
          <div className="status-label-container">
            <div 
              className={classNames(
                "status-label",
                `${status}-status-label`
              )}
            >
              {isProcessing && <Spin className="status-label-spin" size='small' />}
              {prescription?.type}
            </div>
          </div>
        </AntTooltip>    
      </Dropdown>
    )
  }

  return (
    <div className="admin-heart-health">
      <PageHeader
        title='Heart Health'
        count={filteredCount}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={memberships}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        tableProps={{
          pagination: false,
        }}
        columns={[{
          title: 'Patient',
          dataIndex: 'patient',
          render: patient => {
            return <a onClick={() => window.open(`/patients/${patient._id}`, '_blank')}>{patient.firstName} {patient.lastName}</a>
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Prescriptions',
          dataIndex: 'prescriptions',
          width: 200,
          render: membershipPrescriptions => {
            if (!membershipPrescriptions?.length) return

            const prescriptionTypes = membershipPrescriptions.reduce((acc, prescription) => {
              if (!acc.includes(prescription.type)) {
                acc.push(prescription.type)
              }
              return acc
            }, [])
            return prescriptionTypes.map(prescriptionType => {
              const filteredPrescriptions = membershipPrescriptions
                .filter(({ type }) => type === prescriptionType)
                .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              const prescription = filteredPrescriptions[0]
              return (
                <PrescriptionItem 
                  key={prescription._id} 
                  prescription={prescription} 
                />
              )
            })
          }
        }, {
          title: 'Trend',
          dataIndex: 'trend',
          render: trend => <TrendLineChart trend={trend} />
        }]}
      />
    </div>
  )
}