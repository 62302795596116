import DISCOUNT from '../enums/discount.enum'
import MembershipStatus from '../enums/membershipStatus.enum'
import MembershipTypeCode from '../enums/membershipTypeCode.enum'
import { getMembershipTypeByCode } from '../services/membershipType.service'

const MembershipHelper = {
  getCost: async (membershipTypeCode, longevityMembership, isProvider) => {
    const { cost } = await getMembershipTypeByCode(membershipTypeCode)
    if (longevityMembership || isProvider) {
      return Math.round(cost*DISCOUNT*100)/100
    }
    return cost
  },
  getActiveMembership: (memberships, code) => {
    if (!memberships?.length) return
    return memberships?.find(membership => membership.membershipType.code === code && MembershipHelper.isActive(membership))
  },
  isActive: (membership) => {
    const { status, endAt } = membership
    return status === MembershipStatus.ACTIVE || new Date(endAt) > new Date()
  },
  isUltimate: (membership) => {
    return membership?.membershipType?.code === MembershipTypeCode.ULTIMATE
  },
  isHeartHealth:(membership) => {
    return membership?.membershipType?.code === MembershipTypeCode.HEART_HEALTH
  },
  formatCost: (membershipType, hasDollarSign=true) => {
    if (!membershipType?.cost) return ''
    let { cost } = membershipType
    const hasDecimal = cost - Math.round(cost) !== 0
    if (hasDecimal) {
      cost = cost.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      cost = `$${cost.toLocaleString()}`
    }
    return cost
  },
  formatDiscountCost: (membershipType, hasDollarSign=true) => {
    if (!membershipType?.cost) return ''
    let cost = Math.round(membershipType.cost * DISCOUNT * 100)/100 

    // Memberships don't have discount
    if ([MembershipTypeCode.LONGEVITY, MembershipTypeCode.ULTIMATE, MembershipTypeCode.HEART_HEALTH].includes(membershipType.code)) {
      cost = membershipType.cost
    }

    const hasDecimal = cost - Math.round(cost) !== 0
    if (hasDecimal) {
      cost = cost.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      cost = `$${cost.toLocaleString()}`
    }
    return cost
  },
}

export default MembershipHelper