import { Input, Form, Select, Spin, Skeleton } from "antd"
import "./location.scss"
import { WarningFilled } from '@ant-design/icons'
import { FlowStepFooter } from "../../flowStep/flowStepFooter.component"
import zipState from 'zip-state';
import { useEffect, useRef, useState } from "react"
import FormHelper from "../../helpers/form.helper"
import classNames from 'classnames'
import { useLoadScript } from "@react-google-maps/api";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

const { Item } = Form

const stateList = ["AL","AK","AZ","AR","CA","CO","CT","DE","FL","GA","HI","ID","IL","IN","IA","KS","KY","LA","ME","MD","MA","MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI","SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"]

export const Location = ({
  step,
  onSubmit,
  form,
  isSubmitting,
  hasAttempt,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ['places']
  });
  const inputRef = useRef(null)
  const [isSearching, setIsSearching] = useState()
  const requiredRule = {
    required: true, 
    message: <><WarningFilled />&nbsp; Please fill this in</>,
  }
  const [stateRule, setStateRule] = useState([])

  useEffect(() => {
    fetchStateRule()
    inputRef?.current?.focus()
  }, [step])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const fetchStateRule = () => {
    if (!step) return
    if (step.excludeStates?.length) {
      setStateRule([{
        message: <><WarningFilled />&nbsp; Apologies, this is not currently available in your state.</>,
        validator: (_, value) => {
          const state = zipState(value)
          const isValid = !step.excludeStates?.length || !step.excludeStates.includes(state)
          if (!value || isValid) {
            return Promise.resolve();
          } else {
            return Promise.reject('Not available in your state');
          }
        }
      }])
    }
  }

  const getAddressValueByType = (results, type) => {
    const component = results[0].address_components.filter(component => component.types.includes(type))
    if (component.length) {
        return component[0].short_name
    }
    return null
  }

  const getFormattedFields = async (address) => {
    const results = await geocodeByAddress(address)
    const streetNumber = getAddressValueByType(results, 'street_number')
    const route = getAddressValueByType(results, 'route')

    let formattedAddress = ''
    if (streetNumber) {
      formattedAddress = streetNumber
    }
    if (route) {
      formattedAddress = `${formattedAddress} ${route}`
    }

    return {
      streetAddress: formattedAddress,
      postalCode: getAddressValueByType(results, 'postal_code'),
      city: getAddressValueByType(results, 'locality') || getAddressValueByType(results, 'sublocality_level_1') || getAddressValueByType(results, 'neighborhood'),
      state: getAddressValueByType(results, 'administrative_area_level_1'),
    }
  }

  const handlePlacesChange = address => {
    form.setFieldsValue({
      streetAddress: address
    })
  };

  const handlePlacesSelect = async address => {
    setIsSearching(true)
    const formattedFields = await getFormattedFields(address)

    const params = {
      streetAddress: formattedFields.streetAddress,
      city: formattedFields.city,
      state: formattedFields.state,
      postalCode: formattedFields.postalCode,
    }
    form.setFieldsValue(params)
    setIsSearching(false)
  };

  return (
    <div className="location">
      <Form
        form={form}
        className="location-form"
        layout="vertical"
      >

        <Item 
          name='streetAddress'
          label='Street Address'
          rules={[requiredRule]}
          validateTrigger='onSubmit'
          className='location-item'
        >
          {isLoaded ? (
            <PlacesAutocomplete
              onChange={handlePlacesChange}
              onSelect={handlePlacesSelect}
              debounce={0}
            >

              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="street-address-container">
                <div className="adm-list-item">
                  <div className="adm-list-item-content">
                    <div className="adm-list-item-content-main">
                      <Input
                        {...getInputProps({
                          className: classNames(
                            'street-address-input',
                            'location-field',
                          ),
                        })}
                        onKeyDown={e => {
                          if (e.which === 13 && suggestions?.length) {
                            e.stopPropagation()
                            handlePlacesSelect(suggestions[0].description)
                            inputRef.current.blur()
                          }
                        }}
                        ref={inputRef}
                        placeholder="Enter street address"
                      />
                    </div>
                  </div>
                </div>

                <div className="autocomplete-dropdown-container">
                  {(loading || isSearching) && (
                    <div className="address-spinner">
                      <Spin size='small' /> Loading...
                    </div>
                  )}
                  {suggestions.map(suggestion => {
                    return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: classNames('suggestion-item', suggestion.active ? 'active-item' : 'default-item'),
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                    );
                  })}
                </div>
              </div>
              )}
            </PlacesAutocomplete>
          ) : (
            <Skeleton.Avatar 
              active
              shape='square' 
              className="street-address-skeleton"
            />
          )}
        </Item>

        <Item 
          name='streetAddress2'
          label='Apt, Suit, Bldg #'
          validateTrigger='onSubmit'
          className='location-item'
        >
          <Input
            placeholder='Enter apt, suite, or bldg #'
            className="location-field"
            onChange={() => {
              if (hasAttempt) FormHelper.fetchHasError(form)
            }}
          />
        </Item>

        <Item 
          name='city'
          label='City'
          hidden
          validateTrigger='onSubmit'
          className='location-item'

        >
          <Input
            placeholder='Enter city'
            className="location-field"
            onChange={() => {
              if (hasAttempt) FormHelper.fetchHasError(form)
            }}
          />
        </Item>

        <Item 
          name='state'
          label='State'
          validateTrigger='onSubmit'
          className='location-item'
          hidden
        >
          <Select
            placeholder='Select state'
            className="location-select"
            onChange={() => {
              if (hasAttempt) FormHelper.fetchHasError(form)
            }}
            options={stateList.map(state => {
              return {
                label: state,
                value: state,
              }
            })}
          />
        </Item>

        <Item 
          name='postalCode'
          label='Postal Code'
          rules={[requiredRule, ...stateRule]}
          validateTrigger='onSubmit'
          className='location-item'
        >
          <Input
            placeholder='Enter postal code'
            className="location-field"
            inputMode="numeric"
            onChange={() => {
              if (hasAttempt) FormHelper.fetchHasError(form)
            }}
          />
        </Item>

        <FlowStepFooter 
          onSubmit={() => onSubmit()} 
          isSubmitting={isSubmitting}
        />
      </Form>
    </div>
  )
}