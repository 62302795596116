import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import Tracker from "../../enums/tracker.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { PrenuvoApproval } from "./prenuvoApproval.component"
import { PrenuvoIntro } from "./prenuvoIntro.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  MEMBERSHIP: 'membership',
  PAY: 'pay',
  NAME: 'name',
  PHONE: 'phone',
  ADDRESS: 'address',
  GENDER: 'gender',
  DOB: 'dob',
  MORE_INFO: 'more-info',
  HAS_SURGERIES: 'has-surgeries',
  SURGERIES: 'surgeries',
  METAL: 'metal',
  EYE: 'eye',
  RECENT_SURGERY: 'recent-surgery',
  CLAUSTROPHOBIC: 'clasutrophobic',
  MEDICATIONS: 'medications',
  HAS_ALLERGIES: 'has-allerges',
  ALLERGIES: 'allergies',
  TRACKERS: 'trackers',
  COMMUNICABLE_DISEASE: 'communicable-disease',
  COVID_VACCINE: 'covid-vaccine',
  COVID_DOSES: 'covid-vaccine-doses',
  COVID_RECENT_DOSE: 'covid-vaccine-recent-dose',
  COVID_ARM: 'covid-vaccine-arm',
  CONFIRM: 'confirm',
}

export const PrenuvoFlow = () => {
  const { longevityMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const navigate = useNavigate()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, longevityMembership, flow, hasPass])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.PRENUVO,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <PrenuvoIntro footer={footer} />
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.MEMBERSHIP,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.PRENUVO}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.MEMBERSHIP]: {
        type: StepType.PAY,
        nextStep: Step.PAY,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LONGEVITY,
        title: <>Activate Instalab membership for <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>. Cancel anytime.</>,
        description: "This test is exclusive to members, who also enjoy special savings, a care concierge, access to longevity physicians, and more. Easily cancel anytime.",
        skipIf: () => {
          return longevityMembership
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.MORE_INFO,
        addProduct: true,
        productType: ProductTypeCode.PRENUVO,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.PRENUVO)?.cost?.toLocaleString()}</span> for the Prenuvo Whole Body Scan.</>,
        description: `This includes the scan, results within 1 week and a consult with a Prenuvo specialist to review your results`,
      },

      [Step.MORE_INFO]: {
        type: StepType.STATIC,
        nextStep: Step.NAME,
        title: `Great, we’re almost done.`,
        description: `Prenuvo requires more information from you to make sure your scan goes smoothly.`,
        buttonText: 'Continue On!'
        
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.ADDRESS,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.ADDRESS]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.GENDER,
        title: `What's your current address?`,
        description: `We'll book you in the closest Prenuvo location to you.`,
        field: {
          name: 'location'
        },
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What was your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.HAS_SURGERIES,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.HAS_SURGERIES]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.hasSurgeries) {
            return Step.SURGERIES
          } 
          else {
            return Step.EYE
          }
        },
        title: `Have you had any surgeries?`,
        buildUser: true,
        field: {
          name: 'hasSurgeries',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.SURGERIES]: {
        type: StepType.TEXTAREA,
        nextStep: Step.METAL,
        buildUser: true,
        title: `Please list your surgeries.`,
        description: `Separate each surgery with a new line. `,
        field: {
          name: "surgeries",
          placeholder: 'Type surgeries here ...'
        },  
      },
      [Step.METAL]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.EYE,
        title: `In any of these surgeries, was metal inserted into your body?`,
        buildUser: true,
        field: {
          name: "hasMetalSurgery",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.EYE]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.RECENT_SURGERY,
        title: `Have you ever had any injury where metal has penetrated your eye?`,
        buildUser: true,
        field: {
          name: "hasMetalEyeInjutry",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.RECENT_SURGERY]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.CLAUSTROPHOBIC,
        title: `Are you scheduled to have any surgery in the next 8 weeks?`,
        buildUser: true,
        field: {
          name: "hasRecentSurgery",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.CLAUSTROPHOBIC]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.MEDICATIONS,
        title: 'Are you claustrophobic, or do you suspect that you may be claustrophobic?',
        buildUser: true,
        field: {
          name: 'claustrophobic',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },

      [Step.MEDICATIONS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.HAS_ALLERGIES,
        buildUser: true,
        title: `What medications do you currently take?`,
        description: `Separate each medication with a new line. Include dosage if you know it.`,
        field: {
          name: 'freeformMedications',
          placeholder: 'Type medications here ...',
        },
      },

      [Step.HAS_ALLERGIES]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.hasAllergies) {
            return Step.ALLERGIES
          }  
          else {
            return Step.TRACKERS
          }
        },
        buildUser: true,
        title: 'Do you currently have any allergies?',
        field: {
          name: "hasAllergies",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.ALLERGIES]: {
        type: StepType.TEXTAREA,
        nextStep: Step.TRACKERS,
        buildUser: true,
        title: `Please list your allergies.`,
        description: `Separate each allergy with a new line.`,
        field: {
          name: 'allergies',
          placeholder: 'Type allergies here ...',
        },
      },
      [Step.TRACKERS]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        nextStep: Step.COMMUNICABLE_DISEASE,
        title: `What trackers do you currently use?`,
        description: 'Select all that apply',
        field: {
          name: 'trackers',
          options: Object.values(Tracker).map(value => ({label: value, value: value}))
        }
      },
      [Step.COMMUNICABLE_DISEASE]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.COVID_VACCINE,
        title: `Do you have a known or suspected communicable disease? `,
        buildUser: true,
        field: {
          name: 'hasCommunicableDisease',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.COVID_VACCINE]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.hasCovidVaccine) {
            return Step.COVID_DOSES
          }  
          else {
            return Step.CONFIRM
          }
        },
        buildUser: true,
        title: 'Have you received any COVID vaccines?',
        field: {
          name: "hasCovidVaccine",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.COVID_DOSES]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.COVID_RECENT_DOSE,
        buildUser: true,
        title: 'How many doses of the COVID vaccine have you gotten?',
        field: {
          name: 'covidVaccineDoses',
          required: true,
        },
      },
      [Step.COVID_RECENT_DOSE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.COVID_ARM,
        buildUser: true,
        title: 'When was your most recent dose?',
        field: {
          name: 'covidVaccineLastDate',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
      },
      [Step.COVID_ARM]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: 'In what arm did you receive your last COVID vaccine dose in?',
        field: {
          name: "covidVaccineLastArm",
          options: [{
            label: 'Right',
            value: 'Right',
          }, {
            label: 'Left',
            value: 'Left',
          }]
        },
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Confirmed!`,
        description: `Instalab Concierge team will send you an email very soon to schedule your Prenuvo whole body scan.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <PrenuvoApproval/>,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}