import { Typography }  from 'antd'
import './memberIntro.scss'
import { CloseOutlined } from '@ant-design/icons'
import { useState } from "react";
import Breakpoint from "../../../../enums/breakpoint.enum";
import PhotoHelper from '../../../../helpers/photo.helper';
import { PricingTableModal } from '../../../pricingTableModal/pricingTableModal.component'
import useWidth from '../../../../hooks/useWidth.hook';
import { MembershipPoints } from '../../../membershipPoints/membershipPoints.component';

const { Paragraph } = Typography

const MemberIntro = ({ footer, membershipType }) => {
  const width = useWidth()

  const onChat = () => {
    window.FrontChat("show");
  }

  return (
    <div 
      className="member-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >

      {PhotoHelper.format({
        jpg: "/img/green.jpg",
        webp: "/img/green.webp",
        className: "intro-bg",
      })}

      <div 
        className="member-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <div className="member-pricing-container">
          <div className="member-pricing">
            ${membershipType?.cost}/month. Cancel anytime.
          </div>
        </div>

        <Paragraph className="member-title">
          Instalab Membership
        </Paragraph>

        <Paragraph className="member-description">
          Get a partner dedicated to your long-term health.
          <br/>
          <CloseOutlined className="close-icon" />
        
          <MembershipPoints/>
          {/* Includes exclusive access and pricing on advanced testing, access to longevity physicians, your own care concierge, and many more benefits. */}

        
        </Paragraph>

        {footer({ 
          buttonText: "Let's Go",
          buttonCheck: false,
          buttonStyle: {
            height: 50,
            fontSize: 18,
            width: '100%',
            marginTop: 40,
            display: 'block',
          },
          enterStyle: {
            display: 'none',
          }
        })}

        <div className="question-link-container">
          <a 
            onClick={onChat}
            className="question-link"
          >
            I have a question.
          </a>
        </div>
      </div>
    </div>
  )
}

export default MemberIntro