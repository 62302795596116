import { Row, Col, Typography } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import "./membershipPoints.scss";

const { Paragraph } = Typography

export const MembershipPoints = () => {
  const points = [
    {
      title: `Exclusive Advanced Diagnostics Access`,
      description: "Enjoy the best pricing on all advanced diagnostics, reserved exclusively for members."
    }, 
    {
      title: `Access to Longevity Physicians`,
      description: `Receive personalized advice from expert longevity physicians through convenient online consultations.`
    }, 
    {
      title: `Dedicated Care Concierge`,
      description: `Your personal assistant for all health-related needs like scheduling, specialist referrals, and communication with our physician team.`,
    },
    {
      title: "Supplement Discounts",
      description: "Save 30% off retail on high-quality supplements through Fullscript."
    },
    {
      title: `Flexible Membership`,
      description: `Easily pause or cancel your membership anytime.`
    },

  ]

  return (
    <div className="membership-points">
      <div className="point-list">
        <Row gutter={10}>
          {points.map((point, index) => (
            <div className="point-item">
              <Row>
                <Col className="point-col">
                  <CheckCircleOutlined className="point-icon" />
                </Col>
                <Col className="text-col">
                  <Paragraph className="point-title">
                    {point.title}
                  </Paragraph>
                  <Paragraph className="point-description">
                    {point.description}
                  </Paragraph>
                </Col>
              </Row>
            </div>
          ))}
        </Row>
      </div>
    </div>
  )
}