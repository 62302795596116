import { Reference } from "../components/universalReport/reference/reference.component";
import { renderToString } from 'react-dom/server'
import { FlowType } from "../enums/index.enum"

export const ProductCode = {
  BENEFIBER: 0,
  CALCIFEDIOL: 1,
  IRON: 2,
  HOMOCYSTEINE_RESIST: 3,
  OMEGA_3: 4,
  VITAMIN_B12: 5,
  BP_MONITOR: 6,
  DYNAMOMETER: 7,
  VALIDATED_BP_MONITOR: 8,
  PELAGO: 9,
  NUTRISENSE: 10,
  VITAMIN_D3: 11,
  VITAMIN_B12_500: 12,
  VITAMIN_B9_800_B12_5000: 13,
  VITAMIN_B9: 14,
  VISCOUS_FIBER: 15,
  THYROID_PANEL: 16,
  RENAL_PANEL: 17,
  LIVER_PANEL: 18,
  CARDIO_PANEL: 19,
  SEX_HORMONE_PANEL: 20,
  FERRITIN: 21,
  CHOLESTEROL_TREATMENT: 22,
  OMEGA_3_VEGAN: 23,
  FREESTYLE_LIBRE_3: 24,
  INSURE: 25,
  TMG: 26,
  POTASSIUM: 27,
  CAC: 28,
  METABOLIC_HEALTH_CONSULT: 29,
  HEART_HEALTH_CONSULT: 30,
  ATHLETE_LIPIDOLOGIST_CONSULT: 31,
  SELENIUM: 32,
  FIT: 33,
  CCTA: 34,
  HEART_HEALTH_MEMBERSHIP: 35,
  VITAMIN_B9_400_B12_1000: 36,
  VITAMIN_B12_1000: 37

};

export const Product = {
  [ProductCode.BENEFIBER]: {
    code: ProductCode.BENEFIBER,
    brandName: "Benefiber",
    genericName: "soluble fiber",
    directions: "An unflavored powder that dissolves clear and completely in your food and drinks. One teaspoon contains 2 grams of soluble fiber.",
    image: "https://instalab-products.s3.amazonaws.com/benefiber.png",
    buyUrl: "https://www.amazon.com/Benefiber-Prebiotic-Supplement-Digestive-Taste-Free/dp/B00CX3ASFE",
    buyText: "View on Amazon",
  },
  [ProductCode.VISCOUS_FIBER]: {
    code: ProductCode.VISCOUS_FIBER,
    brandName: "Tomorrow's Nutrition Sunfiber",
    genericName: "viscous fiber",
    directions: "An unflavored powder that dissolves clear and completely in your food and drinks. Each small scoop contains 6 grams of viscous fiber.",
    image: "https://instalab-products.s3.amazonaws.com/sunfiber.png",
    buyUrl: "https://www.amazon.com/Tomorrows-Nutrition-Sunfiber-Prebiotic-Digestive/dp/B00GYHWJRA",
    buyText: "View on Amazon",
  },
  [ProductCode.CALCIFEDIOL]: {
    code: ProductCode.CALCIFEDIOL,
    genericName: "calcifediol",
    brandName: "d.velop Vitamin D",
    directions: renderToString(<>Each tablet provides 10 mcg of calcifediol.</>),
    image: "https://instalab-products.s3.amazonaws.com/dvelop-calcifediol-bottle.png",
    buyUrl: "https://www.amazon.com/d-velop-Vitamin-Supplements-2400-mcg/dp/B0977NKG98",
    buyText: "View on Amazon",
  },
  [ProductCode.IRON]: {
    code: ProductCode.IRON,
    genericName: "iron",
    brandName: "Thorne Iron Bisglycinate",
    directions: "Each capsule provides 25 mg of elemental iron.",
    image: "https://instalab-products.s3.amazonaws.com/thorne-iron.png",
    buyUrl: "https://www.amazon.com/Thorne-Research-Bisglycinate-Supplement-Gastrointestinal/dp/B0797GZDZL",
    buyText: "View on Amazon",

  },
  [ProductCode.HOMOCYSTEINE_RESIST]: {
    code: ProductCode.HOMOCYSTEINE_RESIST,
    genericName: "multi-B vitamins",
    brandName: "Life Extension Homocysteine Resist", 
    image: "https://instalab-products.s3.amazonaws.com/homocysteine-resist.png",
    directions: "Each tablet provides 100mg of vitamin B6, 8500 mcg of vitamin B9, 1000 mcg of vitamin B12.",
    buyUrl: "https://www.amazon.com/Life-Extension-Homocysteine-Vegetarian-Capsules/dp/B073JXFDMR",
    buyText: "View on Amazon",
  },
  [ProductCode.OMEGA_3]: {
    code: ProductCode.OMEGA_3,
    genericName: "omega-3s",
    brandName: "Carlson Maximum Omega 2000",
    image: "https://instalab-products.s3.amazonaws.com/carlson-maximum.png",
    directions: "Each capsule provides 1 gram of omega-3s, including 625 mg of EPA and 250 mg of DHA.",
    buyUrl: "https://www.amazon.com/Carlson-Including-Wild-Caught-Supplement-Sustainably/dp/B06XT711LK",
    buyText: "View on Amazon",
  },
  [ProductCode.OMEGA_3_VEGAN]: {
    code: ProductCode.OMEGA_3_VEGAN,
    genericName: "vegan omega-3s",
    brandName: "DEVA Vegan Omega-3",
    image: "https://instalab-products.s3.amazonaws.com/vegan-omega3.png",
    directions: "Each softgel provides just under 1 gram of omega-3s, including 500 mg of DHA and 420 mg of EPA.",
    buyUrl: "https://www.amazon.com/Deva-Vegan-Omega-3-DHA-Manufactured/dp/B08F8K36RP",
    buyText: "View on Amazon",
  },
  [ProductCode.VITAMIN_B12]: {
    code: ProductCode.VITAMIN_B12,
    genericName: "vitamin B12",
    brandName: "Jarrow Formulas B12",
    directions: "Each tablet provides 5000 mcg of vitamin B12.",
    image: "https://instalab-products.s3.amazonaws.com/jarrow-b12-5000.png",
    buyUrl: "https://www.amazon.com/Jarrow-Formulas-Methylcobalamin-Supports-Lozenges/dp/B00OGXISJW",
    buyText: "View on Amazon",
  },
  [ProductCode.VITAMIN_B12_1000]: {
    code: ProductCode.VITAMIN_B12,
    genericName: "vitamin B12",
    brandName: "Jarrow Formulas B12",
    directions: "Each tablet provides 1000 mcg of vitamin B12.",
    image: "https://instalab-products.s3.amazonaws.com/jarrow-b12-1000.png",
    buyUrl: "https://www.amazon.com/dp/B002FJW3ZY",
    buyText: "View on Amazon",
  },
  [ProductCode.BP_MONITOR]: {
    code: ProductCode.BP_MONITOR,
    genericName: "blood pressure monitor",
    brandName: "OMRON Silver Pressure Monitor",
    image: "https://instalab-products.s3.amazonaws.com/omron-silver.png",
    directions: "This is a validated blood pressure monitor. Take the average of 3 consecutive readings - one on each arm and then the third on the arm with higher reading.",
    buyUrl: "https://www.amazon.com/Pressure-Monitor-Bluetooth-Storesup-Readings/dp/B07RYBKNC2",
    buyText: "View on Amazon",
  },
  [ProductCode.DYNAMOMETER]: {
    code: ProductCode.DYNAMOMETER,
    buyUrl:
      "https://www.amazon.com/Handexer-Strengtheners-Dynamometer-Measurement-Electronic/dp/B09MTBSTDD",
    buyText: "View on Amazon",
    genericName: "dynanometer",
    brandName: "Handexer Dynanometer",
    image: "https://instalab-products.s3.amazonaws.com/handexer.png",
    directions: "Squeeze with maximum force for a few seconds to get grip strength reading. It can be also used for isometric exercises."
  },
  [ProductCode.VALIDATED_BP_MONITOR]: {
    code: ProductCode.VALIDATED_BP_MONITOR,
    name: "validated blood pressure monitor",
    buyUrl: 'https://www.validatebp.org/',
    buyText: 'View Monitors',
  },
  [ProductCode.PELAGO]: {
    code: ProductCode.PELAGO,
    genericName: "CBT",
    brandName: "Pelago",
    buyUrl: 'https://www.pelagohealth.com/how-it-works/for-members/',
    buyText: 'Learn more about Pelago',
    image: "https://instalab-products.s3.amazonaws.com/pelago.jpg",
    directions: renderToString(<>Pelago, formerly known as QuitGenius, is a clinician-assisted app for smoking cessation. Studies have shown <Reference content=" digital CBT is more effective than traditional in-person support" url="https://academic.oup.com/ntr/article/24/11/1763/6574261"/></>),
  },
  [ProductCode.NUTRISENSE]: {
    code: ProductCode.NUTRISENSE,
    genericName: "CGM",
    brandName: "Nutrisense",
    image: "https://instalab-products.s3.amazonaws.com/nutrisense.gif",
    directions: "Track CGM data via an app and get customized dietary guidance from professional dietitians.",
    buyUrl: 'https://www.nutrisense.io/',
    buyText: 'View on Nutrisense',
  },
  [ProductCode.FREESTYLE_LIBRE_3]: {
    code: ProductCode.FREESTYLE_LIBRE_3,
    genericName: "CGM",
    brandName: "Freestyle Libre 3",
    image: "https://instalab-products.s3.amazonaws.com/nutrisense.gif",
    directions: "Track CGM data via an app.",
    buyUrl:  `/flow/${FlowType.CGM}`,
    buyText: 'Learn More',
  },
  [ProductCode.VITAMIN_D3]: {
    code: ProductCode.VITAMIN_D3,
    name: "Vitamin D3",
    buyUrl: 'https://www.amazon.com/Nature-Made-Vitamin-1000-Softgels/dp/B004U3Y8OM',
    buyText: 'View on Amazon',
    brand: "Nature Made"
  },
  [ProductCode.VITAMIN_B12_500]: {
    code: ProductCode.VITAMIN_B12_500,
    name: "Vitamin B12",
    buyUrl: "https://www.amazon.com/Life-Extension-B12-Dissolving-lozenges/dp/B00028N1QU",
    buyText: "View on Amazon",
    brand: "Life Extension"
  },
  [ProductCode.VITAMIN_B9_800_B12_5000]: {
    code: ProductCode.VITAMIN_B9_800_B12_5000,
    brandName: "Jarrow Formulas B12 + Folate",
    genericName: "vitamin B9+B12",
    directions: "Each tablet provides 800 mcg of vitamin B9 and 5000 mcg of vitamin B12.",
    image: "https://instalab-products.s3.amazonaws.com/jarrow-b9-b12.png",
    buyUrl: "https://www.amazon.com/dp/B00OGXIGOE",
    buyText: "View on Amazon",
  },
  [ProductCode.VITAMIN_B9_400_B12_1000]: {
    code: ProductCode.VITAMIN_B9_400_B12_1000,
    brandName: "Jarrow Formulas B12 + Folate",
    genericName: "vitamin B9+B12",
    directions: "Each tablet provides 400 mcg of vitamin B9 and 1000 mcg of vitamin B12.",
    image: "https://instalab-products.s3.amazonaws.com/VITAMIN_B9_400_B12_1000.png",
    buyUrl: "https://www.amazon.com/dp/B01IJR5VM2",
    buyText: "View on Amazon",
  },
  [ProductCode.VITAMIN_B9]: {
    code: ProductCode.VITAMIN_B9,
    brandName: "Jarrow Formulas Methyl Folate",
    genericName: "vitamin B9",
    directions: "Each capsule provides 1000 mcg of vitamin B9.",
    image: "https://instalab-products.s3.amazonaws.com/jarrow-b9.png",
    buyUrl: "https://www.amazon.com/Jarrow-Formulas-Methyl-Supports-Cardiovascular/dp/B015XYNSVK",
    buyText: "View on Amazon",
  },
  [ProductCode.THYROID_PANEL]: {
    code: ProductCode.THYROID_PANEL,
    genericName: "comprehensive thyroid panel",
    brandName: "Comprehensive Thyroid Panel",
    directions: "Tests included are TSH, free T3, free T4, TPO antibodies and TG antibodies. An Instalab phlebotomist will come to your home to draw your blood at your chosen date and time. You'll get your results within 3 days.",
    image: "https://instalab-products.s3.amazonaws.com/logo-v3.png",
    buyUrl: `mailto:concierge@instalab.com?subject=Schedule Thyroid Panel`,
    buyText: "Ask Concierge to Schedule",
    testing: true,
    price: 0
  },
  [ProductCode.RENAL_PANEL]: {
    code: ProductCode.RENAL_PANEL,
    genericName: "kidney function panel",
    brandName: "Kidney Function Panel",
    directions: "Panel includes BUN, creatinine, cystatin C and eGFR. An Instalab phlebotomist will come to your home to draw your blood at your chosen date and time. You'll get your results within 3 days.",
    image: "https://instalab-products.s3.amazonaws.com/logo-v3.png",
    buyUrl: `mailto:concierge@instalab.com?subject=Schedule Kidney Function Panel`,
    buyText: "Ask Concierge to Schedule",
    testing: true,
    price: 0
  },
  [ProductCode.LIVER_PANEL]: {
    code: ProductCode.LIVER_PANEL,
    genericName: "liver function panel",
    brandName: "Liver Function Panel",
    directions: "Panel includes albumin, ALT, AST, direct bilirubin, total bilirubin and total protein. An Instalab phlebotomist will come to your home to draw your blood at your chosen date and time. You'll get your results within 3 days.",
    image: "https://instalab-products.s3.amazonaws.com/logo-v3.png",
    buyUrl: `mailto:concierge@instalab.com?subject=Schedule Liver Function Panel`,
    buyText: "Ask Concierge to Schedule",
    testing: true,
    price: 0
  },
  [ProductCode.SEX_HORMONE_PANEL]: {
    code: ProductCode.SEX_HORMONE_PANEL,
    genericName: "sex hormone panel",
    brandName: "Sex Hormone Panel",
    directions: "Panel includes total testosterone, free testosterone, LH, FSH and SHBG. An Instalab phlebotomist will come to your home to draw your blood at your chosen date and time. You'll get your results within 3 days.",
    image: "https://instalab-products.s3.amazonaws.com/logo-v3.png",
    buyUrl: `mailto:concierge@instalab.com?subject=Schedule Sex Hormone Panel`,
    buyText: "Ask Concierge to Schedule",
    testing: true,
    price: 0
  },
  [ProductCode.FERRITIN]: {
    code: ProductCode.FERRITIN,
    genericName: "ferritin test",
    brandName: "Ferritin Test",
    directions: "An Instalab phlebotomist will come to your home to draw your blood at your chosen date and time. You'll get your results within 3 days.",
    image: "https://instalab-products.s3.amazonaws.com/logo-v3.png",
    buyUrl: `mailto:concierge@instalab.com?subject=Ferritin`,
    buyText: "Ask Concierge to Schedule",
    testing: true,
    price: 0
  },
  [ProductCode.CHOLESTEROL_TREATMENT]: {
    code: ProductCode.CHOLESTEROL_TREATMENT,
    genericName: "cholesterol medication",
    brandName: "Instalab's Cholesterol Treatment Program",
    directions: `Based on your medical history and preferences, you'll get an initial medication prescribed and delivered to you. Every 6 weeks, we'll assess your lipid levels and adjust your medication as needed, helping to fine-tune the most effective treatment for you.`,
    // image: "https://instalab-products.s3.amazonaws.com/logo-v3.png",
    buyUrl: "/cholesterol",
    buyText: "Get Medication",
  },
  [ProductCode.TMG]: {
    code: ProductCode.TMG,
    brandName: "Allergy Research Group TMG Supplement",
    genericName: "TMG supplement",
    directions: "Each capsule provides 750 mg of TMG.",
    image: "https://instalab-products.s3.amazonaws.com/TMG.png",
    buyUrl: "https://www.amazon.com/Allergy-Research-Group-TMG-Trimethylglycine/dp/B000GU8SC6",
    buyText: "View on Amazon",
  },
  [ProductCode.POTASSIUM]: {
    code: ProductCode.POTASSIUM,
    brandName: "K+2 Potassium 300mg",
    genericName: "potassium supplement",
    directions: "Each capsule provides 300 mg of potassium.",
    image: "https://instalab-products.s3.amazonaws.com/potassium.png",
    buyUrl: "https://www.amazon.com/Designs-Health-Potassium-Glycinate-Bicarbonate/dp/B000FGZDFQ",
    buyText: "View on Amazon",
  },
  [ProductCode.CAC]: {
    code: ProductCode.CAC,
    genericName: "coronary calcium scan",
    brandName: "Schedule Coronary Calcium Scan",
    directions: "Instalab will schedule an appointment with one its partner imaging centers near you.",
    buyUrl: `/flow/${FlowType.CAC}`,
    buyText: "Get Now",
  },
  [ProductCode.CCTA]: {
    code: ProductCode.CCTA,
    genericName: "coronary CT angiogram",
    brandName: "Book your CCTA with Instalab",
    directions: "Instalab will schedule your CCTA appointment at one of our trusted partner imaging centers near you, ensuring a seamless and stress-free experience. Your results will be analyzed using Cleerly AI to precisely identify high risk soft plaques, artery narroing and blockage.",
    buyUrl: `/cleerly`,
    buyText: "Learn More",
  },
  [ProductCode.METABOLIC_HEALTH_CONSULT]: {
    code: ProductCode.METABOLIC_HEALTH_CONSULT,
    genericName: "longevity physician consult",
    brandName: "Longevity Physician Consultation",
    directions: "Meet with an Instalab longevity physician who specializes in metabolic health. They'll review your results to help you develop an action plan and answer any of your questions.",
    buyUrl: `/flow/${FlowType.LONGEVITY_CONSULT}`,
    buyText: "Book Now",
  },
  [ProductCode.HEART_HEALTH_CONSULT]: {
    code: ProductCode.HEART_HEALTH_CONSULT,
    genericName: "heart health consult",
    brandName: "Instalab's Heart Health Consultation",
    directions: "Meet with a clinical lipidgolist who specializes in heart health. They'll review your results to help you develop an action plan and answer any of your questions.",
    buyUrl: `/flow/${FlowType.HEART_HEALTH_CONSULT}`,
    buyText: "Book Now",
  },
  [ProductCode.ATHLETE_LIPIDOLOGIST_CONSULT]: {
    code: ProductCode.ATHLETE_LIPIDOLOGIST_CONSULT,
    genericName: "lipidologist consult",
    brandName: "Dr. Steven Winiarski",
    directions: "Meet with Dr. Steven Winiarski, a board-certified clinical lipidgolist. He'll review your results to help you develop an action plan and answer any of your questions.",
    buyUrl: "https://calendly.com/d/cp32-v2b-dsx",
    image: "/img/steven-square.png",
    buyText: "Book Now",
  },
  [ProductCode.SELENIUM]: {
    code: ProductCode.SELENIUM,
    brandName: "NOW Foods Selenium",
    genericName: "selenium",
    directions: "Each capsule provides 200 mcg of selenium.",
    image: "https://instalab-products.s3.amazonaws.com/selenium.png",
    buyUrl: "https://www.amazon.com/NOW-Foods-Selenium-200-VCaps/dp/B00SJKPBNY",
    buyText: "View on Amazon",
  },
  [ProductCode.FIT]: {
    code: ProductCode.FIT,
    brandName: "InSure® ONE™ FIT Test",
    genericName: "fecal globin test",
    directions: "Your sample is self-collected at home and shipped back to a Quest Diagnostics laboratory for testing and resulting.",
    image: "https://instalab-products.s3.amazonaws.com/fit.png",
    buyUrl: "https://www.questhealth.com/product/colorectal-screening-fit-home-collection-kit-11290M.html",
    buyText: "View on Quest",
  },
  [ProductCode.HEART_HEALTH_MEMBERSHIP]: {
    code: ProductCode.HEART_HEALTH_MEMBERSHIP,
    genericName: "heart health membership",
    brandName: "Check out Instalab's Heart Health Membership",
    directions: `Work with a physician who specializes in managing your cholesterol, blood pressure, and insulin resistance. Includes custom medication if recommended, regular testing, and expert physician coaching to ensure effective treatment.`,
    // image: "https://instalab-products.s3.amazonaws.com/logo-v3.png",
    buyUrl: "/heart",
    buyText: "Learn More",
  },
};
