import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, Typography, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import validator from "validator"
import "./forgotPasswordModal.scss"
import { requestPasswordReset } from '../../services/password.service'
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper';

const { Item } = Form
const { Text } = Typography

export const ForgotPasswordModal = ({ open, setOpen }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (!open) {
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }
  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const { email } = form.getFieldsValue()

      if (!email || !validator.isEmail(email)) {
        message.error('Enter a valid email address')
        setIsSubmitting(false)
        return
      }
      await requestPasswordReset({ email })
      message.success('Email sent')
      setOpen(false)
      form.resetFields()
    } catch (err) {
      message.error('Failed to send email')
    }
    setIsSubmitting(false)
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid email address')
  }

  return (
    <Modal 
      open={open} 
      title={'Forgot Your Password'}
      onCancel={onCancel}
      className="forgot-password-modal"
      footer={null}
    >
      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >
        <Text className="directions-text">
          We'll send you an email with a link to reset your password.
        </Text>

        <Item 
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          className="email-item"
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isEmail,
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Email Address" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <ContinueButton
          text='Send Email'
          isSubmitting={isSubmitting}
        />
      </Form>
    </Modal>
  )
}