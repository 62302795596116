import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { CleerlyConfirm } from "./cleerlyConfirm.component.js"
import { PanelModal } from "../panelModal/panelModal.component"
import { TassoModal } from "../tassoModal/tassoModal.component"
import { heartHealthPanel } from "../../data/heartHealthPanel.data"
import { listProductTypes } from '../../services/productType.service'
import MembershipTypeCode from "../../enums/membershipTypeCode.enum.js"
import { listMembershipTypes } from '../../services/membershipType.service'
import { MembershipPointsModal } from "../membershipPointsModal/membersipPointsModal.component";
import { completeFlow } from "../../services/flow.service.js"
import ProductHelper from "../../helpers/product.helper.js"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"


export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  GENDER: 'gender',
  PREGNANCY: 'pregnancy',
  DOB: 'dob',
  PHONE: 'phone',
  HAS_ALLERGIES: 'has-allergies',
  ALLERGIES: 'allergies',
  // BIOMETRICS: 'biometrics',
  HAS_MEDICATIONS: 'has-medications',
  MEDICATIONS: 'medications',
  MEMBERSHIP: 'membership',
  LOCATION: 'location',
  CONFIRM: 'confirm',
}

export const CleerlyFlow = () => {
  const { longevityMembership, setHasLongevityMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [openTasso, setOpenTasso] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [openMembershipPointsModal, setOpenMembershipPointsModal] = useState()
  const [hasPass, setHasPass] = useState()
  const navigate = useNavigate()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, hasPass, longevityMembership, setHasLongevityMembership, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.CLEERLY,
          ]
        }
      }
    }))
  }

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }


  const fetchSkeleton = () => {
    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.CLEERLY}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: `This is required for the physician referral.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.gender === Gender.FEMALE) {
            return Step.PREGNANCY
          } else {
            return Step.DOB
          }
        },
        buildUser: true,
        title: `What's your assigned sex at birth?`,
        description: `This is required for the physician referral.`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.PREGNANCY]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `Are you currently pregnant?`,
        description: 'Please note you should not get the scan done if there is any chance you may be pregnant.',
        field: {
          name: 'isPregnant',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender === Gender.MALE
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.HAS_ALLERGIES,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only send you urgent notifications about your account or results here.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.HAS_ALLERGIES]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.hasAllergies) {
            return Step.ALLERGIES
          } 
          else {
            return Step.HAS_MEDICATIONS
          }
        },
        buildUser: true,
        title: 'Do you currently have any allergies?',
        description: 'For example to drugs, food, iodinated contrast, etc.',
        field: {
          name: "hasAllergies",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.ALLERGIES]: {
        type: StepType.TEXTAREA,
        nextStep: Step.HAS_MEDICATIONS,
        buildUser: true,
        title: `Please list your allergies.`,
        description: `Separate each allergy with a new line.`,
        field: {
          name: 'allergies',
          placeholder: '',
        },
      },
      [Step.HAS_MEDICATIONS]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: (patient) => {
          if (patient?.hasMedications) {
            return Step.MEDICATIONS
          } 
          else {
            return Step.MEMBERSHIP
          }
        },
        buildUser: true,
        title: 'Are you taking any prescribed medications?',
        field: {
          name: "hasMedications",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.MEDICATIONS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.MEMBERSHIP,
        buildUser: true,
        title: `Please list each prescribed medication you currently take.`,
        description: `Separate each one with a new line. Include dosage if you know it.`,
        field: {
          name: 'freeformMedications',
          placeholder: 'Type medications here ...',
        },
      },
  
      [Step.MEMBERSHIP]: {
        type: StepType.PAY,
        nextStep: Step.LOCATION,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LONGEVITY,
        title: <>Activate Instalab membership for <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>. Cancel anytime.</>,
        description: "This test is exclusive to members, who also enjoy special savings, a care concierge, access to longevity physicians, and more. Easily cancel anytime.",
        skipIf: () => {
          return longevityMembership
        }
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        addFreeProduct: true,
        productType: ProductTypeCode.CLEERLY,
        buildProduct: true,
        nextStep: Step.CONFIRM,
        title: `What's your current address?`,
        description: `We'll recommend an imaging center near this address.`,
        field: {
          name: 'location'
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `All set! We'll email you within 1 business day.`,
        description: `An Instalab physician will review your details first as required by law. Once done, we'll email you the imaging center and pricing for your CT Coronary Angiogram and facilitate scheduling your appointment.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <CleerlyConfirm/>,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }
  

  return ( <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
        startIndex={1}
      />
    )}

    <MembershipPointsModal
      open={openMembershipPointsModal}
      setOpen={setOpenMembershipPointsModal}
    />

    <TassoModal
      open={openTasso}
      setOpen={setOpenTasso}
    />

    <PanelModal
      panel={heartHealthPanel}
      title="Advanced Heart Health Panel"
      open={openPanel}
      setOpen={setOpenPanel}
    />
    </>
  )
}