import { useNavigate, useParams, useSearchParams, createSearchParams, useLocation } from "react-router-dom"
import { useState, useEffect, useContext } from "react"
import FlowHelper from "../../helpers/flow.helper"
import { addFlow, addProxyFlow, getFlow } from "../../services/flow.service"
import { Spin, Typography } from 'antd'
import "./flowSplit.scss";
import { UserContext } from "../../contexts/user.context"
import FlowType from "../../enums/flowType.enum"

const { Text } = Typography

const FlowSplit = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { flowId, flowType, code } = useParams()
  const { currentUser } = useContext(UserContext)
  const [flowComponent, setFlowComponent] = useState()
  const navigate = useNavigate()
  const { token } = useContext(UserContext)

  useEffect(() => {
    fetchFlow()
  }, [flowType, currentUser, code])

  useEffect(() => {
    const protectedFlowTypes = [
      FlowType.HEART_HEALTH_UPGRADE,
      FlowType.HEART_MEMBER_CONSULT,
    ]
    if (!token && flowType && protectedFlowTypes.includes(flowType)) {
      navigate(`/login?redirect=${location.pathname}`)
    }
  }, [token, flowType])

  const fetchFlow = async () => {
    // console.log('flowType', flowType)
    // console.log('currentUser', currentUser)
    if (flowType && currentUser) {
      let { component, step, forceNew } = FlowHelper.getFlow(flowType)

      // hack: if logged in and first step is account, remove it 
      if (token && Object.keys(step)[0] === "ACCOUNT") { 
        delete step.ACCOUNT
      }


      let params = {}
      for(let entry of searchParams.entries()) {
        params[entry[0]] = entry[1]
      }

      let flow
      if (flowId) {
        flow = await getFlow(flowId)
      } else if (location.pathname.includes('proxy-flow')) {
        flow = await addProxyFlow({  type: flowType,  forceNew, ...params })
      } else {
        flow = await addFlow({  type: flowType,  forceNew, ...params })
      }

      const firstStep = Object.values(step)[0]

      let url
      if (location.pathname.includes('/provider-flow/')) {
        url = `/provider-flow`
      } else if (location.pathname.includes('/pro-flow/')) {
        url = `/pro-flow`
      } else {
        url = `/flow`
      }
      url = `${url}/${flowType}/${code || firstStep}/${flowId || flow._id}`

      let index = 0
      for (const key of Object.keys(params)) {
        url = `${url}${index === 0 ? '?' : '&'}${key}=${params[key]}`
        index += 1
      }
      navigate(url, {
        replace: true,
        queryString: createSearchParams(params).toString()
      })
      setFlowComponent(component)
    } else {
      setFlowComponent(null)
    }
  }



  return flowComponent ? (
    <div className="flow-split">
      {flowComponent}
    </div>
  ) : (
    <div className="loading-flow">
      <Spin size="small" /> <Text className="loading-flow-text">Loading...</Text>
    </div>
  )
}

export default FlowSplit