import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Input, Button, Select, message } from 'antd'
import Gender from '../../enums/gender.enum'
import "./patientForm.scss"
import { addPatient, getPatient, updatePatient } from '../../services/patient.service';
import RuleHelper from '../../helpers/rule.helper'
import FormHelper from '../flow/helpers/form.helper'
import PhoneInput from '../phoneInput/phoneInput.component';
import DateInput from '../dateInput/dateInput.component';

const { Item } = Form

export const PatientForm = ({ open, setOpen, onSuccess, patientId, select, populate=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (patientId) setPatientFields()
    } else {
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch ({ errors, values }) {
      form.setFieldsValue(values)
      return true
    }
    return false
  }

  const setPatientFields = async () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      dob,
      gender,
    } = await getPatient(patientId, {
      select: 'firstName lastName email phone dob gender',
      populate: [],
    })

    form.setFieldsValue({
      firstName,
      lastName,
      email,
      phone,
      dob,
      gender,
    })
  }

  const onFail = () => {
    console.log("on fail", new Date())
    setHasAttempt(true)
    message.error('Enter valid patient data')
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      if (await hasFormError()) {
        message.error('Enter valid patient information')
        setIsSubmitting(false)
        return 
      }

      const {
        firstName,
        lastName,
        email,
        phone,
        dob,
        gender,
      } = form.getFieldsValue()
      
      const params = {
        fields: {
          firstName,
          lastName,
          email,
          phone,
          dob,
          gender,
        },
        select,
        populate
      }
      const response = patientId ? await updatePatient(patientId, params) : await addPatient(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(patientId ? 'Patient updated' : 'Patient added')
      setOpen(false)
      form.resetFields()
    } catch (err) {
      let msg = patientId ? 'Failed to update patient' : 'Failed to add patient'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      }
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <Modal 
      open={open} 
      title={patientId ? 'Edit Patient' : 'Add Patient'}
      onCancel={onCancel}
      footer={null}
      className="patient-form"
    >
      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
      >
        <Item 
          label="Email Address"
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isEmail,
            RuleHelper.isUniqueEmail({
              fieldName: 'email',
              userId: patientId,
            })
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Email Address" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Phone Number"
          name="phone"
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isPhone,
          ]}
          validateTrigger='onSubmit'
        >
          <PhoneInput 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="First Name"
          name="firstName"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="First Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Last Name"
          name="lastName"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Input 
            placeholder="Last Name" 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Sex at Birth"
          name="gender"
          rules={[
            RuleHelper.isRequired
          ]}
          validateTrigger='onSubmit'
        >
          <Select
            placeholder="Select Sex"
            showSearch
            options={Object.values(Gender).map(gender => {
              return {
                label: gender,
                value: gender,
              }
            })}
            onSelect={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Item 
          label="Date of Birth"
          name="dob"
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isDate,
          ]}
          validateTrigger='onSubmit'
        >
          <DateInput 
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
          />
        </Item>

        <Button
          className="submit-btn"
          type='primary'
          htmlType="submit"
          loading={isSubmitting}
        >
          {patientId ? 'Save' : 'Send Invite'}
        </Button>
      </Form>
    </Modal>
  )
}