import Panel from './panel.enum'

const PanelName = Object.freeze({
  [Panel.BASIC]: 'Basic Panel',
  [Panel.ADVANCED_BASELINE]: 'Longevity Panel',
  [Panel.ADVANCED_FOLLOWUP]: 'Longevity Panel',
  [Panel.ATHLETE]: 'Athlete Panel',
  [Panel.CUSTOM]: 'Custom Panel',
  'UPLOAD': 'Custom Panel',
})

export default PanelName