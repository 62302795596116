import React, { useEffect, useState, useContext} from 'react';
import { Row, Col, Spin, Card, Collapse, Table, Tabs } from 'antd'
import './gameplan.scss';
import { UserContext } from '../../contexts/user.context';
import classNames from "classnames";
import { SupportSidebar } from '../supportSidebar/supportSidebar.component';
import RecommendationData from "../../data/recommendation.data"
import ReportHelper from '../../helpers/report.helper';
import { Product } from "../../data/product.data";
import { ProductBox }from "../universalReport/productBox/productBox.component"
import { Panel, RecommendationKind, ReportStatus, Role } from '../../enums/index.enum';
import { TestModal } from "../testModal/testModal.component";
import { PageHeader } from '../pageHeader/pageHeader.component';
import { useParams } from 'react-router-dom';
import { listAnnotatedResults } from '../../services/result.service';
import { listReports } from '../../services/report.service';
import { listAnnotatedTests } from '../../services/test.service';
import { ReferralBox } from '../universalReport/referralBox/referralBox.component';

export const Gameplan = () => {
  const { currentUser } = useContext(UserContext)
  const { patientId } = useParams()
  const [report, setReport] = useState();
  const [recs, setRecs] = useState();
  const [params, setParams] = useState();
  const [testId, setTestId] = useState();
  const [openModal, setOpenModal] = useState();
  const [activeKey, setActiveKey] = useState([]);
  const [isLoading, setIsLoading] = useState()

  const [tests, setTests] = useState()
  const [results, setResults] = useState()
  const [reports, setReports] = useState()

  useEffect(() => {
    document.title =`Instalab | Game Plan`
  }, [])

  useEffect(() => {
    fetchPatientData()
  }, [patientId, currentUser])

  useEffect(() => {
    if (!reports || reports?.length<=0) return;

    let eligibelReports = reports.filter(
      ({ status, result }) =>
        [
          Panel.BASIC,
          Panel.ADVANCED_BASELINE,
          Panel.ADVANCED_FOLLOWUP,
          Panel.HEART_HEALTH,
          Panel.ATHLETE
        ].includes(result?.order?.panel) && 
        status === ReportStatus.APPROVED 
    )

    if (currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER) {
        eligibelReports = reports.filter(
          ({ result }) =>
            [
              Panel.BASIC,
              Panel.ADVANCED_BASELINE,
              Panel.ADVANCED_FOLLOWUP,
              Panel.HEART_HEALTH,
              Panel.ATHLETE
            ].includes(result?.order?.panel) 
        )
    }

    setReport(eligibelReports[0]);
  }, [reports, patientId, currentUser]);

  useEffect(() => {
    if (!report) return;
    setParams(ReportHelper.getRecParams(report, results, tests, setTestId, setOpenModal))
  }, [report,results, tests, testId, openModal ]);

  useEffect(() => {
    if (!report || !params) return;
    setRecs(getRecs(report));
  }, [report, params]);

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };

  const fetchPatientData = async () => {
    if (!currentUser) return
    setIsLoading(true)
    const isPatient = currentUser.role === Role.PATIENT
    const userId = isPatient ? currentUser._id : patientId
    setTests(await listAnnotatedTests({ patient: userId }))
    setResults(await listAnnotatedResults({ patient: userId }))
    setReports(await listReports({
      filter: {
        patient: userId,
      },
      select: 'status healthScore healthPercentile sections',
      populate: [{
        path: "patient",
        populate: {
          path: "memberships",
          populate: [{
            path: "membershipType"
          }]
        }
      }, {
        path: "result",
        populate: [{
          path: "answers",
          populate: [{
              path: "question",
              populate: {
                path: "questionTemplate",
              },
            }, {
              path: "result",
            },
          ],
        }, {
          path: "order",
        }],
      }, {
        path: "prevReport",
        populate: {
          path: "result",
        },
      }],
      sort: '-createdAt'
    }))
    setIsLoading(false)
  }

  const getRecs = (report) => {

    const recommendationCodes = ReportHelper.getAllRecommendations(report)
    const recItems = recommendationCodes.map(code => {
      const rec = RecommendationData.find(recommendation => recommendation.code === code);
      let product;
      
      if (rec.products) {
        if (typeof rec.products === 'function') {
          product = Product[rec.products(params)[0]];
        } else {
          product = Array.isArray(rec.products) && rec.products.length > 0 ? Product[rec.products[0]] : null;
        }
      }
      
      const data  = {
        code: rec.code,
        title: rec.title(params),
        description: rec.description(params),
        referralType: rec.referralType ? rec.referralType(params) : null,
        amazon: rec.amazon,
        product: product,
        buyUrl: product?.buyUrl,
        buyText: product?.buyText,
        hideProduct: rec.hideProduct,
        kind: rec.kind,
        foodList: rec.foodList ? rec.foodList(params) : null
      };

      return data

    });
    
    const categorizedRecItems = recItems.reduce((acc, item) => {
      if (!acc[item.kind]) {
        acc[item.kind] = []; // If not, create an array for this kind
      }
      acc[item.kind].push(item); // Add the item to the array for its kind
      return acc; // Return the updated accumulator for the next iteration
    }, {});

    const maxKindValue = Math.max(...Object.values(RecommendationKind));
    return Array.from({ length: maxKindValue + 1 }, (_, index) =>  categorizedRecItems[index] || []);
  }

  const RecItem = ({item}) => {
    return (
        <div className={classNames("advice-item-description")}>{item.description}
        {item.referralType && <ReferralBox referralType={item.referralType} patient={report.patient}/>}

        {item.hideProduct ? <></>  : <ProductBox style={{ marginTop: 20}} product={item.product}></ProductBox>}
        {item.foodList ? <FoodList items={item.foodList.items} restriction={item.foodList.restriction} /> : <></>}

        </div>
    )
  }


  const FoodTable = ({foods, restriction=[]}) => { 
    const filteredFoods = foods.filter(food => !food.exclude?.some(exclude => restriction.includes(exclude)))
  
    return (<Table 
      dataSource={filteredFoods} 
      columns={[{food: 'food', dataIndex:'food'}]}
      pagination={false}
      showHeader={false}
      className="foodTable"
      />) 
  }


  const FoodList = ({items, restriction}) => {
    return (
      <div style={{marginTop:10}}>
  
      <Tabs
        size={"small"}
        tabBarGutter={15}
        items={items.map((item, index) => {
          return {
            label: item.title,
            key: index,
            children: item.supplement ? <ProductBox style={{marginTop:10}} product={Product[item.supplement]}/> : <FoodTable restriction={restriction} foods={item.foods}/>,
          };
        })}
      />
  
      </div>
    )
  
  }

  const SectionRecs= ({recs, sections}) => {

    const items = sections.map((section) => recs[section]).flat()

    return (
      <Collapse
        bordered={false}
        expandIconPosition='end'
        className="advice-item-collapse"
        activeKey={activeKey}
        onChange={handleCollapseChange} 
        accordion={true}
      >

      {items.map((item, index) => 
          <Collapse.Panel
            key={`rec-${item.code}`}
            header={items.length>1 ? <><span className="index">{index+1}.</span> {item.title}</> : <>{item.title}</>}
            className="advice-item-title"
          >
            <RecItem item={item} index={index}/>
            
          </Collapse.Panel>          
      )}
    </Collapse>)
  }

  return  <>
    <PageHeader title='Game Plan' />

    {isLoading ? <>
      <div className="loading">
        <div className="loading-text">
          <Spin /> &nbsp;&nbsp;Loading
        </div>
      </div>
    </> : <>
      {recs &&   
        <div className="gameplan">
          <TestModal
            open={openModal}
            setOpen={setOpenModal}
            testId={testId}
            setTestId={setTestId}
            report={report}
            tests={tests}
            results={results}
            reports={reports}
          />

          <Row>
            <Col 
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 16 }}
              xl={{ span: 16 }}
              xxl={{ span: 16 }}
              className="gameplan-main">

              {(recs[RecommendationKind.PROVIDER].length+recs[RecommendationKind.TESTING].length)>0  && 
              <Card title="Follow-up & Monitoring" type="inner" className="gameplan-section">
                <SectionRecs recs={recs} sections={[RecommendationKind.PROVIDER, RecommendationKind.TESTING]}/>
              </Card>}


              {recs[RecommendationKind.NUTRITION].length>0 && 
              <Card title={<>Nutrition</>} type="inner" className="gameplan-section">
                  <SectionRecs recs={recs} sections={[RecommendationKind.NUTRITION]}/>
              </Card>}

              {recs[RecommendationKind.EXERCISE].length>0 && 
              <Card title="Physical Activity" type="inner"  className="gameplan-section">
              <SectionRecs recs={recs} sections={[RecommendationKind.EXERCISE]}/>
              </Card>}

              {recs[RecommendationKind.LIFESTYLE].length>0 && 
              <Card title="Lifestyle Modifications" type="inner" className="gameplan-section">
                <SectionRecs recs={recs} sections={[RecommendationKind.LIFESTYLE]}/>
              </Card>}
              
              {recs[RecommendationKind.SUPPLEMENT].length>0  && 
              <Card title="Supplements" type="inner" className="gameplan-section">
                <SectionRecs recs={recs} sections={[RecommendationKind.SUPPLEMENT]}/>
              </Card>}

              {recs[RecommendationKind.MEDICATION].length>0  && 
              <Card title="Medications" type="inner" className="gameplan-section">
                <SectionRecs recs={recs} sections={[RecommendationKind.MEDICATION]}/>
              </Card>}

            </Col>
            {!(currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER) && 
            <Col           
              xs={{ span: 0 }}
              sm={{ span: 0 }}
              md={{ span: 0 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}>
              
              <SupportSidebar/>
              
            </Col>}
        
          </Row>
        </div>
      }
    </>}
    </>
}