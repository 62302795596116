const Recommendation = Object.freeze({
    STOP_SMOKING: 0,
    LOWER_SATURATED_FATS: 1,
    INCREASE_SOLUBLE_FIBER: 2,
    HOMOCYSTEINE_RESIST: 3,
    OMEGA_3S_TG: 4,
    CALCIFEDIOL: 5,
    OUTDOORS: 6,
    LOWER_REFINED_CARBS: 7,
    EXERCISE: 8,
    REDUCE_BODY_FAT: 9,
    CGM: 10,
    REDUCE_FRUCTOSE: 11,
    AVOID_HIGH_PURINE: 12,
    DECREASE_SODIUM: 13,
    INCREASE_POTASSIUM: 14, 
    MONITOR_BP: 15,
    EXERCISE_BP: 16,
    VERIFY_BP: 17,
    REDUCE_METHIONINE: 18,
    B12_FOODS: 19,
    B12_SUPPLEMENT: 20,
    STRENGTH_TRAIN: 21,
    MONITOR_GRIP: 22,
    NICOTINE_REPLACEMENTS: 23,
    SMOKING_CBT: 24,
    IRON: 25,
    VITAMIN_D_MAINTENANCE: 26,
    VITAMIN_B12_MAINTENANCE: 27,
    STATIN: 28,
    GLUCOSE_LOWERING_MEDICATION: 29,
    TALK_TO_PCP_DIABETES: 30,
    HOMOCYSTEINE_B12_ONLY: 31,
    TALK_TO_PCP_TESTOSTERONE: 32,
    TALK_TO_PCP_BP: 33,
    B9_B12_SUPPLEMENT: 34,
    IRON_FOODS: 35,
    HOMOCYSTEINE_B9_B12_SUPPLEMENT: 36,
    HIGH_BLOOD_PRESSURE_MEDICATION: 37,
    TALK_TO_PCP_FH: 38,
    HOMOCYSTEINE_B9_ONLY: 39,
    LOW_FAT_DIET: 40,
    TALK_TO_PCP_SEVERETG: 41,
    THYROID_MEDICATION: 42,
    B9_SUPPLEMENT: 43,
    B9_FOODS: 44,
    TALK_TO_PCP_ANEMIA: 45,
    GLUCOSE_LOWERING_MEDICATION_ADJUST: 46,
    TALK_TO_PCP_CKD: 47,
    VISCIOUS_FIBER: 48,
    EZETIMIBE: 49,
    WATER_INTAKE: 50,
    TALK_TO_PCP_THYROID: 51,
    THYROID_PANEL: 52,
    CKD_FIX_METABOLIC_SYNDROME: 53,
    TALK_TO_PCP_HORMONES: 54,
    REPEAT_RENAL_TESTS: 56,
    REPEAT_LIVER_TESTS: 57,
    CHOLESTEROL_MEDICATION_MAYBE: 58,
    TALK_TO_PCP_LIVER: 59,
    REPEAT_SEX_HORMONES_TESTS: 60,
    TG_LOWERING_MEDICATION: 61,
    MONITOR_LOW_FERRITIN: 62,
    REPEAT_FERRITIN: 63,
    TALK_TO_PCP_LOW_WBC: 64,
    TALK_TO_PCP_THYROID_MEDICATION: 65,
    VITAMIN_B9_MAINTENANCE: 66,
    FECAL_TEST: 67,
    REDUCE_SUPPLEMENTATION: 68,
    REPEAT_HSCRP: 69,
    TALK_TO_HEPATOLOGIST: 70,
    CAC: 71,
    TALK_TO_PCP_HYPONATREMIA: 72,
    DEXA: 73,
    TMG_SUPPLEMENT: 74,
    MTFHR_TEST: 75,
    TALK_TO_PCP_CGM: 76,
    IMPROVE_SLEEP: 77,
    MONITOR_TESTOSTERONE: 78,
    MODY_TEST: 79,
    TALK_TO_PCP_MODY: 80,
    TALK_TO_LIPIDOLOGIST: 81,
    MIRABEGRON_SIDE_EFFECT: 82,
    TALK_TO_PCP_PROSTATE: 83,
    OMEGA_3S: 84,
    SELENIUM: 85,
    TALK_TO_PCP_IRON: 86,
    COMPREHENSIVE_LIVER_PANEL: 87,
    URIC_ACID_MEDICATION: 88,
    REDUCE_ALCOHOL: 89,
    REPEAT_CBC: 90,
    CCTA: 91,
    TALK_TO_NUTRIONIST: 92,
    TALK_TO_PCP_LOW_PLATELET: 93,
    TALK_TO_PCP_INSULIN_RESISTANCE: 94,
    HEPATITIS_PROFILE: 95,
    EXERCISE_GENERIC: 96,
    MONITOR_ANEMIA: 97
});

export default Recommendation;
