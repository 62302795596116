const TaskCategory = Object.freeze({
  CHOLESTEROL_TREATMENT: 'cholesterol treatment',
  CCTA: 'ccta',
  CORONARY_CALCIUM_SCAN: 'coronary calcium scan',
  DIURNAL_CORTISOL_PROFILE: 'diurnal cortisol profile',
  LONGEVITY_PANEL: 'longevity panel',
  GALLERI: 'galleri',
  HEAVY_METALS_TEST: 'heavy metals test',
  MEMBER: 'member',
  MOLD_TOXINS: 'mold toxins',
  SLEEP_APNEA_TEST: 'sleep apnea test',
  SPOT: 'spot',
})

export default TaskCategory