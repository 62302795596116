import React, { useContext, useEffect, useState } from 'react';
import { listBlogs } from "../../services/blog.service"
import './newsletter.scss';
import { Typography, Avatar } from 'antd';
import moment from 'moment'
import BlogHelper from '../../helpers/blog.helper';
import SubscribeSection from '../subscribeSection/subscribeSection.component';
import { UserContext } from '../../contexts/user.context';
import EventCode from "../../enums/eventCode.enum";
import Breakpoint from "../../enums/breakpoint.enum";
import PosthogHelper from '../../helpers/posthog.helper';
import { useNavigate } from 'react-router-dom';
import { PgFooter } from '../pgFooter/pgFooter.component';
import useWidth from '../../hooks/useWidth.hook';
import StorageKey from '../../enums/storageKey.enum';
const { Text, Paragraph } = Typography

const imageMap = {
  '653eb05fe9b18433bf38b0b8': '/img/blogs/founder.jpg',
  '6564a7037d03459c85934def': '/img/blogs/sauna.jpg',
  '65cbc16079c9b3ad475c405d': '/img/blogs/cholesterol.jpg',
  '6563cc757d03459c85848176': '/img/logo_green_bg.jpg',
  '653eb005e9b18433bf38aad1': '/img/adora-square.jpg',
  '658359a67a05efff70c5dbfc': '/img/galleri.png',
  '66db36651e5da5987842b14f': '/img/cac.jpg',
}

export const Newsletter = () => {
  const [blogs, setBlogs] = useState([])
  const { currentUser } = useContext(UserContext)
  const width = useWidth()
  const [isSubscribed, setIsSubscribed] = useState(localStorage.getItem(StorageKey.SUBSCRIBED))
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Peak Health Newsletter | Instalab'
    fetchBlogs()
  }, [])

  useEffect(() => {
    trackPosthog()
  }, [currentUser])

  const trackPosthog = () => {
    PosthogHelper.track(currentUser, EventCode.VISIT_NEWSLETTER)
  }

  const fetchBlogs = async () => {
    setBlogs(await listBlogs())
  }

  return <>
    <div className="newsletter">
      <div className="newsletter-header">
        <Text className="newsletter-title">
          Peak Health
        </Text>
        <Text className="newsletter-subtitle">
          | The Instalab Newsletter
        </Text>
      </div>

      <div className="blog-list">
        {blogs.map(blog => (
          <div 
            key={`blog-${blog._id}`}
            className="blog-item"
            onClick={() => navigate(`/newsletter/${blog.slug}`)}
            >
            <div 
              className="blog-text"
              style={{
                paddingRight: width >= Breakpoint.MD ? 280 : 0
              }}
            >
              <Text className="blog-subject">
                {blog.subject}
              </Text>
              
              <Text className="blog-title">
                {blog.title}
              </Text>

              <Paragraph 
                className="blog-content" 
                ellipsis={{
                  rows: 3
                }}
              >
                {blog.content.replace(/<table[^*]+?<\/table>/g, ' ').replace(/<strong[^*]+?<\/strong>/g,' ').replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ')}
              </Paragraph>

              <div className="blog-item-footer">
                {console.log(blog.author._id)}
                {imageMap[blog.author._id] && (
                  <Avatar 
                    src={imageMap[blog.author._id]} 
                    className="blog-avatar"
                  />
                )}
                <Text className="author-name">
                  {blog.author.name}
                </Text>

                •

                <Text className="blog-published">
                  {blog.publishedAt ? moment(blog.publishedAt).format('MMM D. YYYY') : 'Not published'}
                </Text>

                •

                <Text className="blog-read-time">
                  {BlogHelper.getMinsToRead(blog)} min
                </Text>
              </div>
            </div>

            {imageMap[blog._id] && width >= Breakpoint.MD && (
              <div className="blog-image">
                <img 
                  src={imageMap[blog._id]}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>

    <SubscribeSection 
      darkbg={true}
      isSubscribed={isSubscribed}
      setIsSubscribed={setIsSubscribed}
    />

    <PgFooter />
  </>
}