import React, { useContext, useEffect, useState } from 'react';
import { Table, Tooltip, Typography } from 'antd'
import moment from 'moment'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { TrackingHelper } from "../../helpers/tracking.helper.js"
import "./testkits.scss"
import { UserContext } from '../../contexts/user.context.js';
import { listProducts } from '../../services/product.service.js';
import classNames from 'classnames';


const { Column } = Table
const { Text } = Typography

export const Testkits = () => {
  const { token } = useContext(UserContext)
  const [testKits, setTestKits] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState()

  useEffect(() => {
    document.title = 'Instalab | Test Kits'
  }, [])

  useEffect(() => {
    fetchPatientData()
  }, [token])

  useEffect(() => {
    if (!products) return
    fetchTestKits()
  }, [products])

  const fetchPatientData = async () => {
    if (token) {
      setProducts(await listProducts({
        filter: {
          isRemoved: {
            $ne: true
          }
        },
        select: 'hasKit type tracking trackingNumber createdAt status',
        populate: [{
          path: 'productType',
          select: 'title'
        }]
      }))
    } else {
      setProducts(null)
    }
  }

  const fetchTestKits = () => {
    if (!products) return
    setTestKits(products.filter(product => {
      return product.hasKit
    }))
    setIsLoading(false)
  }


  return (
    <div className="testkits">
      <PageHeader 
        title='Test Kits'
        count={testKits.length}
        />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={testKits}
        scroll={{ x: 'max-content' }}

      >


        <Column
          title='Test'
          dataIndex='productType'
          render={(_, record) => record.productType ? <>{record.productType.title}</> : <>{record.type}</>}
          fixed="left"

        />

        <Column
          title='Ordered'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MM/DD/YYYY')}
        />

<Column
          title='Status'
          dataIndex='status'
          render={(status, { _id }) => {
            return status ? <Text className={classNames(`${status}-status`, 'testkit-status')}>{status ? status : 'fulfilling'}</Text> : <Text className={classNames(`fulfilling-status`, 'testkit-status')}>fulfilling</Text>
          }}
        />

        <Column
          title='Tracking'
          dataIndex='tracking'
          render={(tracking, { _id, trackingNumber, status }) => tracking?.trackingEvents?.length > 0 ? <>{TrackingHelper.getDeliveryService(trackingNumber).service}:&nbsp; 
            <Tooltip 
              overlayStyle={{ maxWidth: '390px'}}
              title={(
                <ul className="tracking-list">
                  {tracking.trackingEvents.map(({ eventTimestamp, eventType }, index) => (
                    <li key={`tracking-${_id}-${index}`}>
                      <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType}
                    </li>
                  ))}
                </ul>
              )}
            >
              <a className="tracking-link" onClick={(e) => {
                e.stopPropagation(); 
                window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking.trackingNumber}`, '_blank');
              }}>{tracking.trackingNumber}</a>
              {/* : {tracking.trackingEvents[0].eventType} */}
            </Tooltip>
          </> 
          
          : trackingNumber && <>{TrackingHelper.getDeliveryService(trackingNumber).service}: <a className="link" href={TrackingHelper.getDeliveryService(trackingNumber).url} target="_blank">{trackingNumber}</a></>}
        />
      </Table>
      
    </div>
  )
}