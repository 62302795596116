import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Button, Modal, Dropdown, Typography, Tooltip, message } from 'antd'
import { listAppointments } from "../../services/appointment.service"
import { SettingOutlined, UserAddOutlined, StopOutlined, EyeOutlined, CalendarOutlined } from '@ant-design/icons'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { cancelAppointment } from '../../services/appointment.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import "./appointments.scss";
import AppointmentStatus from '../../enums/appointmentStatus.enum';
import { Step as LongevityStep } from '../longevityTestFlow/longevityTestFlow.component.js'
import { Step as AthleteStep } from '../athleteTestFlow/athleteTestFlow.component.js'
import { Step as InviteStep } from '../inviteFlow/inviteFlow.component.js'
import { UserContext } from '../../contexts/user.context.js';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { addEvent } from "../../services/event.service";
import { EventType, Panel, PlanCode } from "../../enums/index.enum.js"
import FlowType from '../../enums/flowType.enum.js';
import PanelName from '../../enums/panelName.enum.js';
import { getMe } from '../../services/user.service.js';
import ProductTypeCode from "../../enums/productTypeCode.enum.js"
import { listProducts } from '../../services/product.service.js';
import AppointmentHelper from '../../helpers/appointment.helper.js';
import { listProductTypes} from '../../services/productType.service.js';
import Breakpoint from "../../enums/breakpoint.enum"
import useWidth from "../../hooks/useWidth.hook"

dayjs.extend(utc)
dayjs.extend(timezone)

const { Column } = Table
const { Text } = Typography

const scheduleStep = {
  [FlowType.LONGEVITY_TEST]: LongevityStep.SCHEDULE,
  [FlowType.PRO_DRAW]: LongevityStep.SCHEDULE,
  [FlowType.ATHLETE_TEST]: AthleteStep.SCHEDULE,
}

const inviteStep = {
  [FlowType.LONGEVITY_TEST]: LongevityStep.INVITE,
  [FlowType.PRO_DRAW]: LongevityStep.INVITE,
}

const confirmStep = {
  [FlowType.LONGEVITY_TEST]: LongevityStep.APPOINTMENT_CONFIRMATION,
  [FlowType.PRO_DRAW]: LongevityStep.APPOINTMENT_CONFIRMATION,
  [FlowType.ATHLETE_TEST]: AthleteStep.APPOINTMENT_CONFIRMATION,
}

const appointmentSelect = '_id status location start type'
const appointmentPopulate = [{
  path: 'patients',
  select: '_id'
}, {
  path: 'flow',
  select: '_id type'
}, {
  path: 'orders',
  select: 'panel addOns'
}]

export const Appointments = () => {
  const navigate = useNavigate()
  const [appointments, setAppointments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [canceling, setCanceling] = useState([])
  const cancelingRef = useRef(null)
  cancelingRef.current = canceling
  const { token } = useContext(UserContext)
  const [currentUser, setCurrentUser] = useState()
  const [allProductTypes, setAllProductTypes] = useState();
  const width = useWidth()

  useEffect(() => {
    document.title = 'Testing Appoinments | Instalab'
    fetchAppointments()
    fetchProductTypes()
  }, [])

  useEffect(() => {
    fetchCurrentUser()
  }, [token])


  const fetchProductTypes = async () => {
    setAllProductTypes(await listProductTypes())
  }
  
  const fetchCurrentUser = async () => {
    if (!token) return
    setCurrentUser(await getMe({
      select: '_id credits isAthlete'
    }))
  }

  const fetchAppointments = async () => {
    // blood draws
    const bloodDraws = await listAppointments({ 
      filter: {
        status: {
          $in: [
            AppointmentStatus.PENDING,
            AppointmentStatus.COLLECTED,
            AppointmentStatus.COMPLETE,
            AppointmentStatus.CONFIRMED,
          ]
        }
      },
      select: appointmentSelect,
      populate: appointmentPopulate,
      sort: '-start'
    })

    // non blood draws
    let products = await listProducts({
      select: 'hasAppointment status start facility',
      filter: {
        status: {
          $in: [
            AppointmentStatus.COMPLETE,
            AppointmentStatus.CONFIRMED,
          ]
        },
      },
      populate: [
        {
          path: 'productType',
          select: 'code title',
        },
        {
          path: 'facility',
          select: 'name location',
        },
      ],
    });
    
    // Filter products to exclude specific product types
    let filteredProducts = products.filter((product) => 
      product.hasAppointment && 
      product.facility &&
      product.start && 
      ![ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.ATHLETE_PANEL, ProductTypeCode.CUSTOM_PANEL].includes(product.productType.code)
    );

    filteredProducts = filteredProducts.map(product => ({
      ...product,
      type: 'other',
      populate: appointmentPopulate,
      sort: '-start'
    }));

    const appts = [...bloodDraws, ...filteredProducts]
    appts.sort((a, b) => new Date(b.start) - new Date(a.start));

    setAppointments(appts);

    setIsLoading(false);
  };

  const onCancel = async (_id) => {
    setCanceling([
      ...cancelingRef.current,
      _id
    ])
    try {
      const appointment = await cancelAppointment(_id, {
        select: appointmentSelect,
        populate: appointmentPopulate,
      })
      setAppointments(appointments.map(a => a._id === _id ? appointment : a))
      message.info('Appointment canceled.')
      fetchCurrentUser()
    } catch (err) {
      message.error('Failed to cancel appointment')
    }
    setCanceling(cancelingRef.current.filter(cancelingId => cancelingId !== _id))
  }

  const getUrlPrefix = (flow) => {
    if (flow?.type === FlowType.PRO_DRAW) {
      return `/flow/${FlowType.LONGEVITY_TEST}/`
    } else if (flow?.type === FlowType.PRO_CONSULT) {
      return `/flow/${FlowType.LONGEVITY_CONSULT}/`
    } else {
      return `/flow/${flow?.type}/`
    }
  }

  const onReschedule = async (_id, flow) => {
    navigate(`${getUrlPrefix(flow)}${scheduleStep[flow.type]}/${flow._id}`)
  }

  const onInvite = async (_id, flow) => {
    navigate(`${getUrlPrefix(flow)}${inviteStep[flow.type]}/${flow._id}`)
  }

  // Determine if appointment is same day
  const isToday = (start) => {
    const today = new Date();
    const startDate = new Date(start);

    return startDate.getDate() === today.getDate() &&
            startDate.getMonth() === today.getMonth() &&
            startDate.getFullYear() === today.getFullYear();
  }

  const items = (appointment) => {

    const { _id, flow, status, start, type } = appointment

    if (type === "other") return false;


    // Function to show the confirmation modal
    const showCancelConfirm = (_id) => {
      let content = "Your account will be credited back after you do this so you can schedule another time."

      if (isToday(start)) {
        content = <>Unfortunately canceling the same day will result in a <b>$50 fee</b> due to the impact on our scheduling and business operations. We appreciate your understanding in this. <br/><br/>Your account will be credited with a blood draw after you cancel so you can reschedule another time.</>
      }

      Modal.confirm({
        title: 'Are you sure you want to cancel your appointment?',
        content: content,
        okText: "OK, cancel",
        onOk() {
          onCancel(_id);
        },
        onCancel() {
          console.log('Cancel operation was aborted');
        },
      });

    };

    // Function to show the reschedule modal
    const showRescheduleConfirm = (_id, flow) => {
      if (isToday(start)) {
        Modal.confirm({
          title: 'Are you sure you want to reschedule?',
          content: <>Unfortunately rescheduling the same day will result in a <b>$50 fee</b> due to the impact on our scheduling and business operations. We appreciate your understanding in this.</>,
          okText: "OK, reschedule",
          onOk() {
            onReschedule(_id, flow);
          },
          onCancel() {
            console.log('Reschedule operation was aborted');
          },
        });
      }

      else {
        onReschedule(_id, flow);
      }
    };

    const isMaster = appointment?.patients[0]?._id === currentUser?._id

    let viewUrl = isMaster ? 
      `${getUrlPrefix(flow)}${confirmStep[flow?.type]}/${flow?._id}` :
      `/flow/${FlowType.INVITE}/${InviteStep.CONFIRM}/${flow?._id}`

    const viewItem = flow ? {
      key: '1',
      label: (
        <a 
          onClick={() => navigate(viewUrl)}
          className="error"
        >
          <EyeOutlined style={{marginRight: 5}} /> View Appointment
        </a>
      )
    } : null

    const rescheduleItem = (flow && isMaster) ? {
      key: '2',
      label: (
        <a 
          onClick={() => showRescheduleConfirm(_id, flow)}
          className="error"
        >
          <CalendarOutlined style={{marginRight: 5}} /> Reschedule
        </a>
      )
    } : null

    const inviteItem = ([FlowType.LONGEVITY_TEST, FlowType.PRO_DRAW].includes(flow?.type) && isMaster) ? {
      key: 'invite',
      label: (
        <a 
          onClick={() => onInvite(_id, flow)}
          className="error"
        >
          <UserAddOutlined style={{marginRight: 5}} /> Invite
        </a>
      )
    } : null

    const cancelItem = isMaster ? {
      key: '3',
      label: (
        <a 
          onClick={() => showCancelConfirm(_id)}
          className="remove-item"
        >
          <StopOutlined style={{marginRight: 5}} /> Cancel
        </a>
      )
    } : null


    let menuItems = []

    // visual hack - fix this
    const customPanel = (AppointmentHelper.getPanel(appointment) === Panel.CUSTOM);

    if (status === AppointmentStatus.CONFIRMED) {
      if (viewItem && !customPanel) {
        menuItems.push(viewItem)
      }
      if (rescheduleItem && !customPanel) {
        menuItems.push(rescheduleItem)
      }
      if (inviteItem) {
        menuItems.push(inviteItem)
      }
      if (cancelItem && !appointment.appointmentParent) {
        menuItems.push({
          type: 'divider'
        })
        menuItems.push(cancelItem)
      }


    }
    
    return menuItems
  }

  const onBook = async() => {
    await addEvent({eventType: EventType.CLICK_SCHEDULE_DRAW})
    navigate(`/flow/${FlowType.LONGEVITY_TEST}`)
  }

  const onAthleteBook = async() => {
    await addEvent({eventType: EventType.CLICK_SCHEDULE_DRAW})
    navigate(`/flow/${FlowType.ATHLETE_TEST}`)
  }

  const credits = currentUser?.credits?.filter(credit => credit === PlanCode.ADVANCED)

  return (
    <div className="appointments">
      <PageHeader
        title='Appointments'
        count={appointments.length}
        actions={(
          <Tooltip 
            title={credits?.length > 0 ? `${credits.length} ${credits.length === 1 ? 'credit available' : 'credits available'}` : null}
            placement='left'
          >
            {currentUser?.isAthlete ? 
            <Button
            type='primary'
            onClick={onAthleteBook}
          >
            + Schedule Athlete Panel
            {credits?.length > 0 && (
              <span className="credit-count">
                {credits.length}
              </span>
            )}
          </Button>            
            
            :

            <Button
              type='primary'
              onClick={onBook}
            >
              + Schedule Longevity Panel
              {credits?.length > 0 && (
                <span className="credit-count">
                  {credits.length}
                </span>
              )}
            </Button>}
          </Tooltip>
        )}
      />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={appointments}
        tableLayout='auto'
        scroll={{ x: 'max-content' }}
      >
        <Column
          title='Test'
          dataIndex='orders'
          width={width < Breakpoint.SM && 175}
          render={(orders, { productType }) => {

            // for non blood draws
            if (!orders) {
              if (productType) return productType.title; 
            }


            // Assuming listProductTypes is synchronous or already fetched
            const productTypeMap = allProductTypes.reduce((acc, productType) => {
              acc[productType.code] = productType.title;
              return acc;
            }, {});
        
            // To hold the rendered output
            const combinedOutput = [];
        
            // Process Panels
            const panels = [...new Set(orders.map(({ panel }) => PanelName[panel] || panel))];
            const renderedPanels = panels.map(panel => {
              const count = orders.filter(order => {
                const panelName = PanelName[order.panel] || order.panel;
                return panelName === panel;
              }).length;
              return (
                <span key={panel}>
                  <Text className="order-panel">{panel}</Text>
                  {count > 1 && <Text className="panel-count"> (x{count})</Text>}
                </span>
              );
            });
        
            combinedOutput.push(...renderedPanels);
        
            // Process Add-ons
            const addOnCounts = {};
            orders.forEach(order => {
              if (order.addOns && order.addOns.length > 0) {
                order.addOns.forEach(addOn => {
                  const addOnTitle = productTypeMap[addOn];
                  if (addOnTitle) {
                    if (!addOnCounts[addOnTitle]) {
                      addOnCounts[addOnTitle] = 0;
                    }
                    addOnCounts[addOnTitle] += 1;
                  }
                });
              }
            });
        
            const renderedAddOns = Object.entries(addOnCounts).map(([title, count]) => (
              <span key={title}>
                <Text className="addons-info">{title}{count > 1 && <Text className="panel-count"> (x{count})</Text>}</Text>
              </span>
            ));
        
            combinedOutput.push(...renderedAddOns);
        
            // Map the combined output array to JSX with proper formatting
            return combinedOutput.map((item, index) => (
              <span key={index}>
                {index > 0 && ', '}
                {item}
              </span>
            ));

          }}
          fixed="left"
        />

        {/* <Column
          title='Test'
          dataIndex='orders'
          render={(orders, {productType}) => {

            // for non blood draws
            if (!orders) {
              if (productType) return productType.title; 
            }

            const panels = [...new Set(orders.map(({ panel }) => PanelName[panel] || panel))]
            return panels.reduce((acc, panel) => {
              const count = orders.filter(order => {
                const panelName = PanelName[order.panel] || order.panel
                return panelName === panel
              }).length
              acc.push(<><Text className="order-panel">{panel}</Text>{count > 1 && <Text className="panel-count"> (x{count})</Text>}</>)
              return acc
            }, []).map((item, index) => <>
              {index > 0 && ', '}{item}
            </>)
          }}
          fixed="left"
        /> */}


        <Column
          title='Appointment Time'
          dataIndex='start'
          render={(start, { location, facility }) => {

            if (!start) 
                return 'TBD'

            const address = location || facility?.location

            start = dayjs(start).tz(address?.timeZoneId || currentUser?.location?.timeZoneId)
            return <>
              {start.format('MMM D, YYYY')} @ {start.format('h:mm a z')}
            </>
          }}
        />

        <Column
          title='Location'
          dataIndex='location'
          render={(location, { facility }) => {

            let address = location

            // non-blood draws
            if (!location) {
              if (!facility) return <>TBD</>
              else if (!facility?.location) return <>{facility.name}</>
              else {
                const { streetAddress, streetAddress2, city, state, postalCode } = facility.location

                const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`

                return <Tooltip title={fullAddress}><span>{facility.name}</span></Tooltip>
              }
            }
            
            // blood draws
            const { streetAddress, streetAddress2, city, state, postalCode } = address
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <>{fullAddress}</>
          }}
        />


      <Column
          title='Status'
          dataIndex='status'
          render={(status, { _id }) => {
            if (canceling.includes(_id)) {
              return <Text className={classNames(`cancel-status`, 'appointment-status')}>canceling...</Text>
            }
            return status ? <Text className={classNames(`${status}-status`, 'appointment-status')}>{status ? status : 'pending'}</Text> : <Text className={classNames(`pending-status`, 'appointment-status')}>pending</Text>
          }}
        />



        <Column
          dataIndex='remove'
          width={50}
          className="action-column"
          render={(_, appointment) => {
            const dropdownItems = items(appointment);
            
            return dropdownItems.length > 0 ? (
              <Dropdown 
                menu={{ items: dropdownItems }}
                placement='bottomRight'
              >
                <Button>
                  <SettingOutlined />
                </Button>
              </Dropdown>
            ) : null;
          }}
        />
      </Table>
    </div>
  )
}