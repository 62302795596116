import { CheckOutlined, InfoCircleOutlined, HeartOutlined } from '@ant-design/icons'
import { Button, Col, Row, Popover, Timeline } from 'antd'
import { useNavigate } from 'react-router-dom'
import PosthogHelper from '../../helpers/posthog.helper'
import { useRef, useEffect, useContext, useState } from 'react'
import './home.scss'
import EventCode from '../../enums/eventCode.enum'
import Breakpoint from '../../enums/breakpoint.enum'
import PhotoHelper from '../../helpers/photo.helper'
import FlowType from '../../enums/flowType.enum'
import { PgFooter } from '../pgFooter/pgFooter.component'
import { SubscribeNewsletter } from '../subscribeNewsletter/subscribeNewsletter.component'
import { Overview } from '../overview/overview.component'
import { Questions } from '../questions/questions.component'
import { TestCatalog } from '../testCatalog/testCatalog.component'
import { ProductTestimonials } from '../productDetail/productTestimonials/productTestimonials.component'
import useWidth from '../../hooks/useWidth.hook'
import { UserContext } from '../../contexts/user.context'
import { ReactComponent as BloodSvg} from "../../assets/svg/blood_drop.svg"
import { ReactComponent as CTSvg} from "../../assets/svg/tac.svg"
import { ReactComponent as DrSvg} from "../../assets/svg/doctor-3.svg"
import { ReactComponent as RxSvg} from "../../assets/svg/prescription-1.svg"
import { addSlack } from "../../services/slack.service"
import { PopupModal } from "react-calendly";
import Color from '../../colors.scss';


export const Home = () => {
  const navigate = useNavigate()
  const width = useWidth()
  const testingRef = useRef(null)
  const membershipRef = useRef(null)
  const { currentUser } = useContext(UserContext)
  const [openCalendly, setOpenCalendly] = useState()


  useEffect(() => {
    trackPosthog()
  }, [currentUser])

  const trackPosthog = () => {
    PosthogHelper.track(currentUser, EventCode.VISIT_HOME_V2)
  }

  const onChat = () => {
    window.FrontChat("show");
  };

  const onQuiz = () => {
    navigate(`/flow/${FlowType.QUIZ}`)
  }

  const getStarted = async () => {
    try {
      await addSlack({ 
        message: 'User Clicked HOME - Main CTA',
        channel: process.env.REACT_APP_PATIENT_SLACK
      });
    } catch (error) {
      console.error('Failed to log message to Slack:', error);
    }

    navigate(`/longevity-panel`)
  }


  const onHeartHealth = async () => {
    try {
      await addSlack({ 
        message: 'User Clicked HOME - Heart Health',
        channel: process.env.REACT_APP_PATIENT_SLACK
      });
    } catch (error) {
      console.error('Failed to log message to Slack:', error);
    }

    navigate(`heart`)
  }

  const handleAdvancedClick = async () => {
    try {
      await addSlack({
        message: 'User clicked Learn More - Heart Health Membership',
        channel: process.env.REACT_APP_PATIENT_SLACK
      });
    } catch (error) {
      console.error('Failed to log message to Slack:', error);
    }

    navigate("/heart")
  }

  const userJourneys = [
    {
      key: 1,
      title: <>Longevity Blood Panel</>,
      header: false,
      dot: <BloodSvg className="svg" style={{color: "#FF9999" }} />,
      description: "Identified moderately elevated apoB and LDL cholesterol levels. Coronary calcium scan recommended to further stratify risk.",
    },
    {
      key: 2,
      title: <>Coronary Calcium Scan</>,
      header: false,
      dot: <CTSvg className="svg" style={{color: 'lightblue'}} />,
      description: "Showed a CAC score of 942 raising significant concern of major artery narrowing and blockage. CT Coronary Angiogram recommended to identify the extent of this.",
    },
    {
      key: 3,
      title: <>CT Coronary Angiogram</>,
      header: false,
      dot: <CTSvg className="svg" style={{color: 'lightblue'}} />,
      description: "Uncovered over 75% blockage in two major coronary arteries, which posed serious risk of heart attack.",
    },
    {
      key: 4,
      title: <>Interventional Cardiologist Referral</>,
      header: false,
      dot: <DrSvg className="svg" style={{color: 'darkblue'}} />,
      color: 'black',
      description: "Marshall was promptly referred to a highly-recommended cardiologist who after further evaluation recommended and proceeded with bypass surgery.",
    }
  ]

  const user2Journeys = [
    {
      key: 1,
      title: <>Longevity Blood Panel</>,
      header: false,
      dot: <BloodSvg className="svg" style={{color: "#FF9999" }} />,
      description: "Revealed very high Lp(a) and ApoB levels. CT Coronary Angiogram recommended to get full understanding of heart disease risk.",
    },
    {
      key: 3,
      title: <>CT Coronary Angiogram</>,
      header: false,
      dot: <CTSvg className="svg" style={{color: 'lightblue'}} />,
      description: "Found minor plaques, but nothing immediately alarming. These results serve as a baseline for future comparisons.",
    },
    {
      key: 2,
      title: <>Prescribed ezetimibe and statin</>,
      dot: <RxSvg className="svg" style={{color: 'lightgray'}} />,
      description: "Generic medication covered by heart health memberhsip. Efficacy monitored through regular at-home lipid testing. But statin caused increases in liver enzymes and insulin resistance."
    },
    {
      key: 5,
      title: <>Prescribed PCSK9 inhibitor</>,
      header: false,
      dot: <RxSvg className="svg" style={{color: 'lightgray'}} />,
      description: "Switched to Repatha, a brand medication. Insurance coverage coordinated by Instalab Concierge. Cholesterol levels reached optimal levels in three months.",

    }
  ]

  const testimonials = [
    {
      name: "Gustaf Alstromer",
      title: "Partner, Y Combinator",
      image: "/img/gustaf.jpg",
      webp: "/img/gustaf.webp",
      text: "Staying on top of your health is table stakes for the best founders. I've recently used Instalab and it's perfect for exactly this. I recommend it to anyone who has a remote interest in improving their health and longevity."
    },
    {
      name: "Jason Goldman",
      title: "Filmmaker @ Wild Growth Media",
      image: "/img/jason.jpg",
      text: "Instalab's totally changed the way I look after myself. After making changes based on the results, I saw improvements pretty quickly. It’s great for getting a clear view of my health without the hassle of visiting a clinic."
    }, 
    {
        name: "Kat Manalac",
        title: "Partner @ Y Combinator",
        image: "/img/kat.jpg",
        text: "As a working parent, it's hard to make time to be proactive about my health. Instalab made it quick and easy to understand what I need to improve. Based on my labs they gave me clear insights and easy-to-follow game plan."
    },
    {
        name: "Ed Suh",
        title: "MD @ Alpine Ventures ",
        image: "/img/ed.jpg",
        text: "I'm blown away by how comprehensive Instalab is. It's great to see a report focused on my most pressing issues. I love the intuitive summaries that explaind my results and where I stood on the risk curve."
    },

  ]

  const faqs = [
    {
      question: `What tests should I be doing to stop on top of my health?`,
      answer: <>Each person is different. The right set of tests is based on your genetics, your past medical history and much more. Take <a onClick={() => onQuiz() } className="cta-link">this short quiz</a> to find out which tests are best for you.</>,
    },   
    {
      question: `Where is Instalab currently available?`,
      answer: <>Anywhere in the United States.</>,
    },   
    {
      question: `Does my health insurance cover Instalab?`,
      answer: `Insurance does not cover Instalab services. However, if you have a Health Savings Account (HSA) or Flexible Spending Account (FSA), you may be eligible for reimbursement. We recommend using your HSA/FSA card for this purpose. If you don’t have one, we can provide an itemized receipt for you to submit for reimbursement.`,
    }, 
    {
      question: `Can Instalab replace my primary care provider?`,
      answer: `Instalab specializes in prevention-based testing and treatments and is not intended for emergency or immediate health issues. While we offer consultations with our physicians, we strongly believe in the importance of continuity of care. If you have a primary care provider that you trust, we recommend maintaining that relationship. We are happy to collaborate with them to ensure you receive comprehensive and well-rounded medical care.`,
    }, 
    {
      question: `What background do Instalab physicians have?`,
      answer: <>At Instalab, each of our physicians is board-certified in various specialties and brings a wealth of experience in guiding patients toward their health goals. They stay current with the latest research and are not just experts but also skilled listeners. Our physicians strive to understand your unique situation and collaborate with you to ensure that your care is aligned with your individual goals and preferences.</>,
    }, 
    {
      question: `Can I request tests not currently in your test catalog?`,
      answer: <>Definitely, please email concierge@instalab.com with your request.</>,
    }, 

    {
      question: `Can I expense Instalab to my company?`,
      answer: `Absolutely. Your company can and should cover the cost of this service. Your health directly impacts the success of your business. Just as executive coaching sessions and other professional services are justified expenses, Instalab services should be viewed similarly. If there are any reservations, we are more than willing to discuss the value of this investment with your company’s investors. It is a minimal cost for potentially significant returns.`
    }, 
    {
      question: `Can I gift an Instalab membership to my family or friends?`,
      answer: <>Yes, Instalab makes a thoughtful gift for both family and friends. If you're interested in gifting Instalab, <a className="cta-link" onClick={() => navigate('/gift')}>simply fill out you and your recipient's info</a> and we'll send it in a custom digital e-card on the date you choose.</>
  }]

  const features = [
    {
      key: 3,
      title: "Exclusive advanced diagnostics access",
      description: "Enjoy the best available pricing on all advanced diagnostics, reserved exclusively for members."
    },
    {
      key: 2,
      title: "Access to longevity physicians for consults",
      description: "Receive expert guidance and personalized advice from longevity specialists through convenient online visits after each test. Each 30 minute visit is just $99.",
    },
    {
      key: 4,
      title: "Dedicated care concierge",
      description: "A personal assistant to handle all your healthcare needs, including smooth scheduling, specialist referrals, and quick communication with our physician team."
    },
    {
      key: 6,
      title: "Supplement discounts",
      description: "Save 30% off retail on high-quality supplements through Fullscript."
    },    
  ]

  const heartHealthFeatures = [
    {
      key: 'treatment',
      title: "Treatment plan by your dedicated physician",
      description: "Your dedicated physician will review your results, discuss your heart health goals, and work with you to create a personalized treatment plan that fits your needs.",
    },
    {
      key: 'rx',
      title: "Medications prescribed & delivered",
      description: "Medications prescribed for heart health include metformin, statins, cholesterol absorption inhibitors, PCSK9 inhibitors, inclisiran and much more. We recommend which ones are best for you based on your medical history and preferences. All generics come with your heart health membership, and if you choose to take any brand medications, we'll help you find best pricing and get insurance coverage where possible."
    },    
    {
      key: 'testing',
      title: "Regular testing to verify treatment efficacy",
      description: "Membership includes frequent testing to ensure efficacy of any treatments and guide adjustments as needed."
    }, 
    {
      key: 'messaging',
      title: "Unlimited access to dedicated physician",
      description: "Gain constant access to your physician for any questions, concerns, or advice through our unlimited messaging service, ensuring continuous support for your heart health journey.",
    },
    // {
    //   key: 5,
    //   title: "Exclusive events and content",
    //   description: "Invitations to webinars, workshops, and seminars on heart health topics, plus access to a library of educational materials."
    // },
  ]

  const MembershipPlans = () => {
    const navigate = useNavigate();
  
    return (
      <Row gutter={36} className="membership-plans-container">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="membership-plan"
        >
          <div className="membership-plan-container">
              <div className="membership-plan-title">Essentials</div>
              <div className="membership-plan-description">
                A comprehensive suite of tools and personalized guidance designed to help you identify and manage potential health risks. 
              </div>
              <div className="membership-plan-price">
                $49 <span>per month</span>
              </div>
              <div className="membership-plan-cta">
                <Button
                  type="primary"
                  className="join-btn"
                  onClick={() => navigate(`/flow/${FlowType.MEMBER}/account`)}
                >
                  Join Now
                </Button>
              </div>

              <div className="membership-features-container">
              <div className="feature-item">Includes all testing, plus:</div>

                {features.map(({ title, description, key }) => (
                  <div key={key} className="feature-item">
                    <CheckOutlined className="checkmark" />
                    <div className="title">
                      {title}
                      <Popover content={description} title={title} trigger="click">
                        <InfoCircleOutlined className="info-icon" />
                      </Popover>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="membership-plan"
        >
          <div className="membership-plan-container">
              <div className="membership-plan-title">Heart Health</div>
              <div className="membership-plan-description">
              Personalized treatment to prevent heart health conditions such as high cholesterol, insulin resistance, and high blood pressure.
              </div>
              <div className="membership-plan-price">
                $149 <span>per month</span>
              </div>
              <div className="membership-plan-cta">
                <Button 
                  className="join-btn"
                  // type="primary"
                  onClick={handleAdvancedClick}
                >
                  Learn More
                </Button>
              </div>
              
              <div className="membership-features-container">
                <div className="feature-item">Everything from Essentials, plus:</div>
                {heartHealthFeatures.map(({ title, description, key }) => (
                  <div key={key} className="feature-item">
                    <CheckOutlined className="checkmark" />
                    <div className="title">
                      {title}
                      <Popover content={description} title={title} trigger="click">
                        <InfoCircleOutlined className="info-icon" />
                      </Popover>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        </Col>
   

      </Row>
    );
  };
  
  const MemberSpotlights = () => {
    return  (   
      <Row   className="member-spotlights">

        <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            xl={11}
            xxl={11} 
            className="member-spotlight"
          >

          <div className="timeline-title">

            <div className="member-info" style={{textAlign:"center", marginBottom:15}}>
              <div className="member-name">Marshall F. (49 years old)</div>
              {/* <div className="member-photo">
              <img src="/img/member-1.png" className="circle-avatar"/>
              </div> */}
            </div>
          </div>


          <div className="quote-container">After reading Peter Attia's Outlive book, I sought comprehensive testing from my primary care doctor but was denied. So I turned to Instalab. Their testing identified critical issues that likely prevented a life-threatening heart attack. I'm incredibly grateful.</div>

          {/* <div className="timeline-reason"><span>Why Instalab:</span> Marshall sought comprehensive testing from his primary care doctor but was denied. Joined Instalab for thorough screening.</div>

          <div className="timeline-reason"><span>Outcome:</span> Proactive testing at Instalab identified critical health issues that likely prevented a life-threatening heart attack.</div> */}
          {/* <hr className="timeline-line"/> */}

          <div className="timeline-reason"><span>Instalab Snapshot</span></div>
          <Timeline mode="left" className="timeline">
            {userJourneys.map((item) => (
              <Timeline.Item 
                dot={item.dot} 
                color={item.color} 
                label={item.label}
              >
                <div className="timelime-item-title">{item.title}</div>
                <div className="timelime-item-description">{item.description}</div>
              
              </Timeline.Item>
            ))}
          </Timeline>

        </Col>
        
        <Col            
            xs={0}
            sm={0}
            md={0}
            lg={1}
            xl={1}
            xxl={1}>
        </Col>

        <Col
            xs={24}
            sm={24}
            md={24}
            lg={11}
            xl={11}
            xxl={11}
            className="member-spotlight"
          >

          <div className="timeline-title">

            <div className="member-info" style={{textAlign:"center", marginBottom:15}}>
              <div className="member-name">Robert L. (37 years old)</div>
              {/* <div className="member-photo">
              <img src="/img/member-2.png" className="circle-avatar"/>
              </div> */}
            </div>
          </div>

          <div className="quote-container">My family has a history of heart attack and strokes at early age so I was worried about my own risk. I reached out to Instalab to help me understand my current risk and find effective treatments to reduce it. Their support has given me peace of mind.</div>


          {/* <div className="timeline-reason"><span>Why Instalab:</span> With a family history of heart attack and strokes at early age, Robert sought Instalab's help to minimize his own risks.
          </div>

          <div className="timeline-reason"><span>Outcome:</span> Robert quickly iterated to the most effective medication for him to lower his cholesterol and reduce his cardiovascular risk.
          </div> */}
          {/* <hr className="timeline-line"/> */}

          <div className="timeline-reason"><span>Instalab Snapshot</span></div>
          <Timeline mode="left" className="timeline">
            {user2Journeys.map((item) => (
              <Timeline.Item 
                dot={item.dot} 
                color={item.color} 
                label={item.label}
              >
                <div className="timelime-item-title">{item.title}</div>
                <div className="timelime-item-description">{item.description}</div>
              
              </Timeline.Item>
            ))}
          </Timeline>

        </Col>


      </Row>
    )
  }

  return (
    <div className="home">

      <PopupModal
        open={openCalendly}
        url="https://calendly.com/d/cmhv-xmp-7h4?hide_gdpr_banner=1"
        onModalClose={() => setOpenCalendly(false)}
        rootElement={document.getElementById("root")}
        pageSettings={{
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
          primaryColor: Color.success,

        }}
      />

      <div className="introduction">
        <div className="max-width">
          <Row 
            gutter={48}
            className="introduction-row"
          >
            <Col 
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 13 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
              className="introduction-main-col"
            >
              <div className="introduction-title">
                {/* Proactive Testing and Treatments */}
              How healthy are you <i>really</i>?
              </div>

              <div className="introduction-description">
              {/* Live a longer, healthier life.  */}
              Instalab delivers advanced testing and treatments for those seeking a longer, healthier life. 
              </div>

              <Button
                className="join-btn"
                onClick={getStarted}
                style={{
                  width: width < Breakpoint.SM  && '100%',
                }}
              >
               Start with Longevity Panel
              </Button>

              <Button
                className="learn-btn"
                onClick={onHeartHealth}
                icon={<HeartOutlined/>}
                style={{
                  width: width < Breakpoint.SM  && '100%',
                  marginLeft:  width < Breakpoint.SM && 0,
                }}
              >
               Focus on Heart Health
              </Button>
{/* 
              <Button
                className="learn-btn"
                onClick={() => setOpenCalendly(true)}
                style={{
                  width: width < Breakpoint.SM  && '100%'
                }}
              >
                Talk to Testing Specialist
              </Button> */}



                <div className="accepted-insurance" >
                  <CheckOutlined /> HSA / FSA cards accepted
                </div>
            </Col>
            <Col
              xs={{ span: 0 }}
              sm={{ span: 0 }}
              md={{ span: 0 }}
              lg={{ span: 11}}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >

                {/* <img src="/img/roadmap-phone-v2.png" className="hero-img"/> */}
               {PhotoHelper.format({
                png: `${process.env.REACT_APP_CLOUDFRONT}/roadmap-phone-v2.png`,
                webp: `${process.env.REACT_APP_CLOUDFRONT}/roadmap-phone-v2.webp`,
                className:"hero-img",
                loading: 'eager'
              })}
            </Col>

            <Col
              xs={{ span: 0 }}
              sm={{ span: 0 }}
              md={{ span: 0 }}
              lg={{ span: 0}}
              xl={{ span: 4 }}
              xxl={{ span: 4 }}
            >
              <div className="metric">
                18,000+ <span>Patients Tested</span>
              </div>

              <div className="metric">
                100+ <span>Corporate Clients</span>
              </div>

              <div className="metric">
                98% <span>Patient Satisfaction</span>
              </div>
            


            </Col>
          </Row>
        </div>
      </div>


      {/* <div className="introduction">
        <div className="max-width">
          <div className="introduction-title" style={{margin:0, textAlign:"center"}}>
          Proactive Testing<br/>and Treatments
          </div>

          <div className="introduction-description" style={{textAlign:"center", paddingTop: 15, fontSize: 25, margin: "auto"}}>
                Live a longer, healthier life. 
          </div>

          <div className="intro-questions-container">
            <div className="intro-question">Question 1</div>
            <div className="intro-question">Question 2</div>
          </div>
          <div className="intro-questions-container">
            <div className="intro-question">Question 1</div>
            <div className="intro-question">Question 2</div>
          </div>

          <Row style={{alignItems:"center"}}>
            <Col span={12}>
              <div className="intro-question">How healthy am I really?</div> 
            </Col>
            <Col span={12}>
            <div className="intro-question">Can I catch cancers early?</div> 
            </Col>
            <Col span={12}>
            <div className="intro-question">How much plaque are in my arteries?</div> 
            </Col>
            <Col span={12}>
            <div className="intro-question">Am I able to have kids?</div> 
            </Col>
          </Row>
        </div>
      </div> */}


      <div className="testing dark-bg" ref={testingRef}>

        <Overview 
          title="Test Catalogue"
          description={<>Explore advanced diagnostics to early detect conditions like heart disease and cancer before symptoms arise. <a onClick={onChat} className="cta-link">Message us</a> if you're looking for a test not listed here.</>}
        />
          
        <div className="test-catalog-container">
        <TestCatalog/>
        </div>

      </div>

      <div className="memberships-container" ref={membershipRef}>

          <Overview 
            title={<>The Perfect Health Membership</>}
            description={<div className="overview-subtitle">Instalab offers more than just advanced testing — gain a partner truly dedicated to your long-term health.</div>}
          />
           
          <MembershipPlans/>
      </div>

      <div className="member-spotlights-container dark-bg">
        <Overview title="Member Spotlights"/>
        <MemberSpotlights/>
      </div>   

  
      <div className="testimonials">
        <Overview title="Hear From More Real Patients"/>
        <div className="testimonials-container">
          <ProductTestimonials testimonials={testimonials}/>
        </div>
      </div>



      <div className="faq  dark-bg">
        <Overview
          title="Frequently Asked Questions"
          description={<>If you have any questions, <a onClick={onChat}>we'd love to chat with you</a>.</>}
        />

        <Questions items={faqs}/>
      </div>

      <div><SubscribeNewsletter /></div>
      <PgFooter />

      {/* <Wonderchat/> */}
    </div>
  )
}
