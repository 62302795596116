import { Typography, Row, Col }  from 'antd'
import './memberConfirmed.scss'
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react";
import Breakpoint from "../../../../enums/breakpoint.enum";
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import { useNavigate } from 'react-router-dom';
import PhotoHelper from '../../../../helpers/photo.helper';
import { listProductTypes } from '../../../../services/productType.service';
import useWidth from '../../../../hooks/useWidth.hook';
import ProductHelper from '../../../../helpers/product.helper';

const { Text, Paragraph } = Typography

const productTypeCodes = [
  ProductTypeCode.LONGEVITY_PANEL,
  ProductTypeCode.GALLERI,
  ProductTypeCode.CTCALCIUM,
  ProductTypeCode.WATCHPAT_ONE
]

const MemberConfirmed = ({ footer }) => {
  const width = useWidth()
  const navigate = useNavigate()
  const [productTypes, setProductTypes] = useState()
  
  useEffect(() => {
    fetchProductTypes()
  }, [])

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: productTypeCodes
        }
      }
    }))
  }

  const Panels = ({ productTypes }) => {
    return (
      <div className="panels">
        <Row gutter={12}>
          {productTypes?.sort((a, b) => {
            return productTypeCodes.findIndex(code => code === a.code) - productTypeCodes.findIndex(code => code === b.code)
          }).map(({ code, path, flowType, title, tagline, cost, photos }) => (
            <Col 
              key={`panel-${code}`}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <div 
                className="panel"
                onClick={() => navigate(path ? path : `/flow/${flowType}`)}
              >
                <div className="panel-img-container">
                  {PhotoHelper.format({
                    jpg: photos[0].jpg,
                    webp: photos[0].webp,
                    className:"panel-img"
                  })}

                  <ArrowRightOutlined className="panel-icon" />
                </div>

                <Paragraph className="panel-title">
                  {title}
                </Paragraph>
                <Paragraph className="panel-description">
                  {tagline}
                </Paragraph>
                {/* <Text className="panel-discount-price">
                  ${ProductHelper.getMemberPrice(cost)}
                </Text> */}
                {/* <Text className="panel-original-price">
                  ${cost}
                </Text> */}
              </div>
            </Col>
          ))}
        </Row>
      </div>
    )
  }

  return (
    <div 
      className="member-confirmed"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="member-confirmed-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph className="member-title">
          Congrats, you're in! 
        </Paragraph>
        <Paragraph className="member-description">
          We're thrilled to have you as an Instalab member. Your dedicated health concierge will reach out shortly via email. In the meantime, you can email <a href="mailto:concierge@instalab.com">concierge@instalab.com</a> with any immediate questions.
          <br/>
          <CloseOutlined className="close-icon" />
          <br/>
          {/* If you're new here, please fill out this quick 2-minute medical questionnaire so we can recommend what you should do first. */}
          If you're new here, take a quick quiz to find out all the preventative tests you should do this year to stay on top of your health:
        </Paragraph>


        {footer({ 
          buttonText: "Take Quiz",
          buttonCheck: false,
          buttonStyle: {
            height: 50,
            fontSize: 18,
            width: 300,
            margin: '40px auto 0 auto',
            display: 'block',
          },
          enterStyle: {
            display: 'none',
          },
          onCustomSubmit: () => navigate('/flow/quiz')
        })}
{/* 
        {footer({ 
          buttonText: "Take Quiz",
          buttonCheck: false,
          buttonStyle: {
            height: 50,
            fontSize: 18,
            width: 300,
            margin: '40px auto 0 auto',
            display: 'block',
          },
          enterStyle: {
            display: 'none',
          },
          onCustomSubmit: () => navigate('/flow/quiz')
        })} */}

        <div className="tests-header">
          <div className="tests-header-border"></div>
          <Paragraph className="tests-header-text">
            Most Popular Tests
          </Paragraph>
          <div className="tests-header-border"></div>
        </div>

        <Panels productTypes={productTypes} />
      </div>
    </div>
  )
}

export default MemberConfirmed