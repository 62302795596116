import "./results.scss";
import { ResultsTable } from '../resultsTable/resultsTable.component';
import { useState, useEffect, useContext } from "react";
import { PageHeader } from "../pageHeader/pageHeader.component";
import { Select } from "antd";
import { useSearchParams } from "react-router-dom";
import { Button, message, Typography, Tooltip} from 'antd'
import { DownloadOutlined, LoadingOutlined, SendOutlined } from '@ant-design/icons'
import { UserContext } from '../../contexts/user.context';
import fileDownload from 'js-file-download'
import axios from 'axios'
import { addReportForPatient } from '../../services/patient.service';
import { SendFileModal } from '../sendFileModal/sendFileModal.component';

const { Paragraph, Text } = Typography
const { Option } = Select;

export const Results = () => {
  const [filterValue, setFilterValue] = useState('all');
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDownloading, setIsDownloading] = useState()
  const [isSendModalVisible, setIsSendModalVisible] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const { currentUser, setCurrentUser } = useContext(UserContext)

  useEffect(() => {
    const filter = searchParams.get('filter') || 'all';
    setFilterValue(filter);
  }, [searchParams]);

  const handleFilterChange = value => {
    setFilterValue(value);
    let params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setSearchParams({ ...params, filter: value });
  };


  // TO DO: protect download to just the patient
  const onDownload = async (_id) => {
    setIsDownloading(_id)
    const location = await addReportForPatient(_id)
    const filename = location.split('?')[0]

    axios
      .get(location, { responseType: 'blob' })
      .then((res) => {
        fileDownload(res.data, filename)
        message.success('PDF downloaded')
        setIsDownloading(null)
    })    
  }

  const handleSendFileClick = (file) => {
    setSelectedFile(file);
    setIsSendModalVisible(true); // Open the send modal
  };

  return currentUser && (
    <div className="results">

      <SendFileModal 
          selectedFile={selectedFile}
          isSendModalVisible={isSendModalVisible}
          setIsSendModalVisible={setIsSendModalVisible}
      />
      <PageHeader 
        title='Test Results' 
        actions={[
          (
            <div className="actions-container">
              <Select 
                className="select-filter"
                value={filterValue} 
                onChange={handleFilterChange} 
              >
                <Option value="all">Show All Results</Option>
                <Option value="last">Last Blood Draw</Option>
                <Option value="high-risk">At Risk</Option>
                <Option value="high-moderate-risk">At Risk + Borderline</Option>
                <Option value="low-risk">Optimal</Option>
                <Option value="instalab-panel">Longevity Panel</Option>
              </Select>

              <Tooltip title="Fax your test results">
              <Button 
                type='default' 
                className="send-btn"
                onClick={async (e) => {
                  e.stopPropagation()
                  handleSendFileClick({name: "Instalab Lab Results", id: `${currentUser.email}`, folderName: currentUser?._id})
                }}
              >
                <SendOutlined />
      
              </Button>

              </Tooltip>

              <Tooltip title="Download your test results">
              <Button   
                type='default' 
                className="download-btn"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (isDownloading !== currentUser?._id) {
                    await onDownload(currentUser?._id);
                  }
                }}
              >
                {isDownloading === currentUser?._id ? (
                  <Text className="downloading-text">
                    <LoadingOutlined />
                  </Text>
                ) : <>
                  <DownloadOutlined />
                </>}
              </Button>
              </Tooltip>


            </div>
          )
        ]}
      />

      <ResultsTable 
        filterValue={filterValue} 
      />
    </div>
  );
};
