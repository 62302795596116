import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown, Space, Menu, Button } from "antd";
import Color from '../../colors.scss';
import { DownOutlined } from "@ant-design/icons";
import logo from "../../assets/svg/logo.svg";
import { useNavigate } from "react-router-dom";
import "./header.scss";
import MobileHeaderLayout from "../layouts/mobileHeader.layout.js";
import FlowType from "../../enums/flowType.enum.js";
import Breakpoint from '../../enums/breakpoint.enum'
import Role from "../../enums/role.enum.js";
import useWidth from "../../hooks/useWidth.hook.js";
import { UserContext } from "../../contexts/user.context.js";

const { Text } = Typography

export const Header = ({ isPro=false, hasMobile=true }) => {
  const navigate = useNavigate();
  const [headerItems, setHeaderItems] = useState([]);
  const { token, currentUser, longevityMembership } = useContext(UserContext)
  const width = useWidth()

  useEffect(() => {
    const contactItem = {
      title: "Contact",
      onClick: onChat,
    };

    const dashboardItem = {
      title: 'Dashboard',
      onClick: () => navigate('/')
    }

    const logoutItem = {
      title: "Logout",
      onClick: onLogout,
    };

    const loginItem = {
      title: "Login",
      onClick: () => navigate("/login"),
    };

    const testItem = {
      title: "Testing",
      dropdownItems: [
        {
          key: 'longevity',
          label: (
            <a onClick={() => navigate("/longevity-panel")}>
              <span style={{fontWeight:600}}>Most start with:</span> <br/>Longevity Blood Panel
            </a>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: 'truage',
          label: (
            <a onClick={() => navigate(`/truage`)}>
              Biological Age Test
            </a>
          )
        },

        {
          key: 'cgm',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.CGM}`)}>
              Continuous Glucose Monitor
            </a>
          )
        },

        {
          key: 'biobeat',
          label: (
            <a onClick={() => navigate(`/biobeat`)}>
              Continuous BP Monitor
            </a>
          ),
        },
        {
          key: 'cac',
          label: (
            <a onClick={() => navigate(`/cac`)}>
              Coronary Calcium Scan
            </a>
          )
        },
        {
          key: 'cleerly',
          label: (
            <a onClick={() => navigate(`/cleerly`)}>
              Coronary CT Angiogram
            </a>
          )
        },
        {
          key: 'dexa-body',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.DEXA_BODYCOMP}`)}>
              DEXA Body Composition
            </a>
          )
        },
        {
          key: 'dexa-bone',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.DEXA}`)}>
              DEXA Bone Density
            </a>
          )
        },


        {
          key: 'fertility',
          label: (
            <a onClick={() => navigate(`/fertility`)}>
              Fertility Hormone Test
            </a>
          )
        },
        {
          key: 'galleri',
          label: (
            <a onClick={() => navigate(`/galleri-test`)}>
              Galleri Cancer Detection Test
            </a>
          ),
        },

        {
          key: 'heart-health',
          label: (
            <a onClick={() => navigate(`/heart-health-test`)}>
              Heart Health Test
            </a>
          ),
        },
        {
          key: 'genetic-test',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.LONGEVITY_GENETIC_TEST}`)}>
              Longevity Genetic Test
            </a>
          ),
        },
        {
          key: 'toxic-mold',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.MOLD_TOXINS}`)}>
              Mold Toxins Test
            </a>
          ),
        },
        {
          key: 'sleep',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.SLEEP_APNEA}`)}>
              Sleep Apnea Test
            </a>
          ),
        },
        {
          key: 'testosterone',
          label: (
            <a onClick={() => navigate(`/testosterone`)}>
              Testosterone Panel
            </a>
          ),
        },

        {
          key: '5',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.TOXIC_METALS_TEST}`)}>
              Toxic Metals Test
            </a>
          ),
        },
        {
          key: 'prenuvo',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.PRENUVO}`)}>
              Whole Body MRI Scan
            </a>
          ),
        },  
        {
          key: 'vo2max',
          label: (
            <a onClick={() => navigate(`/flow/${FlowType.VO2MAX}`)}>
              Vo2 Max
            </a>
          ),
        }, 
      ]
    }

  
    let patientLinks = !isPro ? [testItem] : []

    if (!token) {
      setHeaderItems([...patientLinks, contactItem, loginItem]);
    } else if (currentUser?.role === Role.PATIENT) {
      setHeaderItems([dashboardItem, ...patientLinks, logoutItem]);
    } else if (
      currentUser?.role === Role.ADMIN ||
      currentUser?.role === Role.PROVIDER ||
      currentUser?.role === Role.STAFF ||
      currentUser?.role === Role.PHLEBOTOMIST
    ) {
      setHeaderItems([logoutItem]);
    }
  }, [currentUser, isPro, token]);

  const onLogout = () => {
    navigate("/logout");
  };

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const becomeMember = () => {
    navigate(`/flow/${FlowType.MEMBER}`)
  }

  const HeaderItem = ({ title, path, onClick, dropdownItems }) => {
    if (dropdownItems) {

      const dropdownMenu = (
        <Menu>
          {dropdownItems.map(item => {
            if (item.type === 'divider') {
              return <Menu.Divider key={item.key} />;
            } else {
              return (
                <Menu.Item key={item.key}>
                  {item.label}
                </Menu.Item>
              );
            }
          })}
        </Menu>
      );

      return (
        <div className="header-item">
          <Dropdown overlay={dropdownMenu}>
          <a onClick={(e) => e.preventDefault()}>
              <Space>
                {title}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </div>
      );
    }

    else 
      return (
        <div className="header-item">
          <a
            key={`header-${title}`}
            onClick={() => {
              if (path) {
                window.open(path, "_blank");
              } else {
                onClick();
              }
            }}
          >
            {title}
          </a>
        </div>
      );
  };

  const DesktopHeader = () => {
    return (
      <Row className="desktop-header">
        <Col>
          <div 
            className="logo-container"
            onClick={() => navigate((isPro && !token) ? '/pro' : '/')}
          >
            <img
              src={logo}
              alt="logo"
              className="logo"
            /> 
            <Text className="logo-name">
              {isPro ? <>Instalab<span style={{ color: Color.success }}>Pro</span></> : 'Instalab'}
            </Text>
          </div>
        </Col>

        <Col>
          <div className="header-item-list">
            {headerItems.map(({ title, path, onClick, dropdownItems }, index) => (
              <HeaderItem
                key={`header-item-${index}`}
                index={index}
                title={title}
                path={path}
                onClick={onClick}
                dropdownItems={dropdownItems}
              />
            ))}
            {(!longevityMembership && currentUser?.role === Role.PATIENT) && <Button type="default" onClick={becomeMember} style={{fontWeight:600}}>Become a Member</Button>}
          </div>
          
        </Col>
      </Row>
    );
  };

  return (hasMobile || width) && <>
    {(!hasMobile || width > Breakpoint.LG) ? <DesktopHeader /> : <MobileHeaderLayout isPro={isPro}  />}
  </>
};
