import FlowType from "../../enums/flowType.enum"
import StepType from "../flow/enums/stepType.enum"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import { Flow } from "../flow/flow.component"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import LongevityConsultIntro from "./custom/longevityConsultIntro/longevityConsultIntro.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"

export const Step = {
  INTRO: 'intro',
  MEMBERSHIP: 'membership',
  SELECT: 'select',
  PAY: 'pay',
  PAY_60: 'pay-60',
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  REASON: 'reason',
  CONSULT: 'schedule',
  CONSULT_60: 'schedule-60',
  CONFIRM: 'confirm',
}

export const LongevityConsultFlow = () => {
  const { longevityMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, longevityMembership, flow, hasPass])

  useEffect(() => {
    const fetchMembershipTypes = async () => {
      setMembershipTypes(await listMembershipTypes({
        filter: {
          code: {
            $in: [
              MembershipTypeCode.LONGEVITY,
            ]
          }
        }
      }))
    }
    
    const fetchProductTypes = async () => {
      setProductTypes(await listProductTypes({
        filter: {
          code: {
            $in: [
              ProductTypeCode.CONSULT_LONGEVITY,
              ProductTypeCode.CONSULT_LONGEVITY_60
            ]
          }
        }
      }))
    }

    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.MEMBERSHIP,
        content: ({ footer }) => <LongevityConsultIntro footer={footer} />
      },
      [Step.MEMBERSHIP]: {
        type: StepType.PAY,
        nextStep: Step.PAY,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LONGEVITY,
        title: <>Activate Instalab membership for <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>. Cancel anytime.</>,
        description: <>Membership is required to access Instalab longevity physicians ($99 per session). Also includes access to all advanced testing, care concierge and more.</>,
        skipIf: () => {
          return longevityMembership
        }
      },

      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.ACCOUNT,
        addProduct: true,
        addLongevityMembership: true,
        productType: ProductTypeCode.CONSULT_LONGEVITY,
        title:<>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY))}</span> for your longevity consultation.</>,
        // title: (flow?.addLongevityMembership || longevityMembership) ?
        //   <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY))}</span> <span className="original-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span> for your longevity consultation{longevityMembership ? '.' : <> and <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span> for your Instalab membership.</>}</> :
        //   <>Pay <span className="true-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span> for your longevity consultation.</>,
        description: `This is for a 30-minute video consultation with a longevity physician. You'll schedule your appointment in the next step.`,
      },
      // [Step.PAY_60]: {
      //   type: StepType.PAY,
      //   nextStep: Step.ACCOUNT,
      //   addProduct: true,
      //   addLongevityMembership: true,
      //   productType: ProductTypeCode.CONSULT_LONGEVITY_60,
      //   title:<>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY_60))}</span> for your longevity consultation.</>,
      //   // title: (flow?.addLongevityMembership || longevityMembership) ?
      //   //   <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY_60))}</span> <span className="original-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY_60)?.cost}</span> for your longevity consultation{longevityMembership ? '.' : <> and <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span> for your Instalab membership.</>}</> :
      //   //   <>Pay <span className="true-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY_60)?.cost}</span> for your longevity consultation.</>,
      //   description: `This is for a 60-minute video consultation with a longevity physician. You'll schedule your appointment in the next step.`,
      // },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `Create your Instalab acccount.`,
        description: `Create your account to manage your sessions.`,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.REASON,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or sessions.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.REASON]: {
        type: StepType.TEXTAREA,
        onNextStep: async (patient) => {
          switch (patient?.sessionPreference) {
            case ProductTypeCode.CONSULT_LONGEVITY_60:
              return Step.CONSULT_60
            default:
              return Step.CONSULT
          }
        },
        buildUser: true,
        title: `What are questions or topics do you want to focus on during your session?`,
        description: 'This will help your longevity physician prepare for your consultation.',
        field: {
          name: 'consultReason',
          placeholder: 'Type here ...',
        }
      },
      [Step.CONSULT_60]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM,
        addConsult: true,
        title: `Schedule your longevity consultation.`,
        description: `This session will be with Dr. Steven Winiarski who specializes in longevity medicine. It'll be a 60 minute video call where you can ask any questions.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest-clone' : 'https://calendly.com/d/cpv4-fmb-rj6',
      },
      [Step.CONSULT]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM,
        addConsult: true,
        title: `Schedule your longevity consultation.`,
        description: `This session will be with Dr. Steven Winiarski who specializes in longevity medicine. It'll be a 30 minute video call where you can ask any questions.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest' : 'https://calendly.com/d/z2x-3dv-qxk',
      },

      // TO DO: share what you want to talk about

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Appointment confirmed!`,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.GENDER}`,
        buttonText: 'Continue',
        description: `You'll receive an email with a calendar invite and a Zoom link for your session. Now we just need a little more information to help your physician prepare for your appointment.`,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}