import DISCOUNT from '../enums/discount.enum'
import { getProductTypeByCode } from '../services/productType.service'
import MembershipHelper from './membership.helper'
import ProductTypeCode from '../enums/productTypeCode.enum'

const ProductHelper = {
  getCost: async (code, longevityMembership, isProvider) => {
    const { cost } = await getProductTypeByCode(code)

    if (isProvider) {
      return Math.floor(cost*DISCOUNT)
    }
    return cost
  },
  getMemberPrice: (cost) => {
    if (!cost) return null

    return Math.floor(cost)
  },
  formatCost: (productType, hasDollarSign=true) => {
    if (!productType?.cost) return ''
    let { cost } = productType
    const hasDecimal = cost - Math.round(cost) !== 0
    if (hasDecimal) {
      cost = cost.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      cost = `$${cost.toLocaleString()}`
    }
    return cost
  },
  formatPrice: (price, hasDollarSign=true) => {
    if (!price) return ''
    const hasDecimal = price - Math.round(price) !== 0
    if (hasDecimal) {
      price = price.toLocaleString(undefined, {minimumFractionDigits: 2})
    }
    if (hasDollarSign) {
      price = `$${price.toLocaleString()}`
    }
    return price
  },
  formatDiscountCost: (productType, longevityMembership, isProvider, hasDollarSign=true) => {
    if (!productType?.cost) return '';
  
    // Calculate the discounted cost
    let cost = productType.cost; //Math.floor(productType.cost * DISCOUNT);

    if (MembershipHelper.isUltimate(longevityMembership) && [ProductTypeCode.LONGEVITY_PANEL, ProductTypeCode.CONSULT_LONGEVITY, ProductTypeCode.CONSULT_LONGEVITY_60, ProductTypeCode.LONGEVITY_PANEL].includes(productType.code)) {
      cost = 0
    }

    else if (isProvider) {
      cost = Math.floor(productType.cost * DISCOUNT)
    }
  
    if (hasDollarSign) {
      cost = `$${cost}`;
    }
    
    return cost;
  },
}

export default ProductHelper
