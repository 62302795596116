
import { useRef, useEffect, useState, useContext } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import { ReactComponent as BloodSVG} from '../../assets/svg/blood.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as HomeSVG} from '../../assets/svg/house.svg'; 
import { ReactComponent as ScienceSVG} from '../../assets/svg/science.svg'; 
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { advancedPanel } from "../../data/advancedPanel.data";
import { getProductTypeByCode } from "../../services/productType.service";
import { UserContext } from "../../contexts/user.context";

export const Testing = () => {
  const { longevityMembership } = useContext(UserContext)
  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchProductType(); 
  }, []);

  const fetchProductType = async () => {
    setProductType(await getProductTypeByCode(ProductTypeCode.LONGEVITY_PANEL))
  };

  
  // set product attributes
  useEffect(() => {
    if (!productType) return
    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}
    const faqs = [
      {
          question: "What biomarkers are included?",
          answer: <>The Longevity Panel is carefully curated by medical experts from diverse specialties, providing a comprehensive assessment of your ever-evolving health. <a onClick={scrollToPanel}  className="cta-link">View Biomarkers Below</a></>
      },
      {
          question: "How many vials of blood are drawn?",
          answer: "It's just 5 small tubes."
      },
      {
          question: "Who is drawing my blood?",
          answer: `A licensed phlebotomist will come to your desired location to perform your blood draw. Every phlebotomist undergoes extensive background checks and training. And they are experts in making your experience painless and stress-free.`,
      },
      {
        question: `How long does it take to get my results?`,
        answer: <>It typically takes about 1 week to get results after your sample arrives at our lab. We’ll send you an email when your results and recommendations are ready to go!</>
      },  
      {
        question: `What is longevity medicine?`,
        answer: <>Longevity medicine is a field of medical science that focuses on enhancing quality of life as people age. It acknowledges that while aging is a natural part of life, its negative impacts can often be mitigated or managed. The goal is to help people maintain a good quality of life as they grow older, through a combination of medical interventions and lifestyle modifications, rather than merely extending lifespan. This approach is grounded in scientific understanding and ongoing research into the aging process and its effects on the human body.</>
      },  
    
      {
          question: `Where is this available?`,
          answer: `Anywhere in the United States.`
      },
      {
          question: `Can friends and family join my appointment?`,
          answer: `Yes! Instalab is a fantastic experience to share with loved ones. After scheduling your appointment, you'll be able to invite others to your appointment.`,
      }, 
      {
          question: `Do I need to fast before my blood draw?`,
          answer: `Yes, please fast for at least 8 hours before your draw. Water and black coffee are permissible. Failure to fast may impact several of your results, including insulin, glucose, triglycerides, and more.`,
      }, 
      {
          question: `Where is my blood being sent?`,
          answer: `Your sample is sent to one of our CLIA + CAP certified labs for analysis. After the lab is done, any remaining sample is disposed.`,
      }, 
      {
          question: `What is the efficacy of the given recommendations?`,
          answer:  `Nearly everyone who adhered to their recommendations and subsequently followed up with a blood panel after 3 months demonstrated improvement in their results. A key reason for this success is the simplicity and straightforwardness of the guidance given.`
      }, 
      {
          question: `Can I talk to an actual human physician to review my results with me?`,
          answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a longevity physician who helped put together Instalab's Longevity Panel. They'll be ready to address any of your questions.`,
      }, 
      {
          question: `Does Instalab help with any followup needs?`,
          answer: `Yes. Instalab helps members with any additional testing and referrals to specialists.`
      },
      {
          question: "How often should I get this panel done?",
          answer: `If you're in good health, it's wise to have a check-up at least once a year to monitor your well-being. But if you're changing your lifestyle in any way – whether through diet, exercise, supplements or medications – it's recommended to undergo tests at least quarterly during this time. This ensures that your choices are benefiting, not compromising, your health. Remember, “feeling better” or shedding pounds doesn't always signify optimal health. It's essential to have tangible proof that your efforts are yielding positive results.`,
      },             
      {
          question: `If I already feel healthy, how will this data be useful?`,
          answer: `Many people who come to Instalab feel healthy but still discover a hidden health risk. Most are able to fix it by doing something super simple. This is the power of early detection testing - addressing issues while they're still molehills, rather than waiting for them to become mountains.`,
      }, 
      {
          question: `Does my health insurance cover the Longevity Panel?`,
          answer: `For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don’t have one, we can generate an itemized receipt for you to submit.`,
      }, 
      {
          question: `Can I add other biomarkers to Instalab’s Longevity Panel?`,
          answer: <>
              Yes. Instalab members can add additional biomarkers. Once you've signed up, please reach out to your health concierge (<a className="cta-link" href="mailto:concierge@instalab.com">concierge@instalab.com</a>) and we'll explore what options we can add-on.
          </>
      },     

    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: "Longevity Panel",
          memberPrice: ProductHelper.formatDiscountCost(productType, longevityMembership, false, false),
          originalPrice: productType.cost,
          oneliner: "A comprehensive blood test to early detect your overall health risks and guide you on ways to optimize your long-term health.",
          panelRef: panelRef,
          cta: {
            text: "Get Tested Now",
            url: "/flow/longevity-test"
          },
          photos: [
              {
                original: "/img/longevity-panel-box.png", 
                thumbnail: "/img/longevity-panel-box.png"
              },

             // {
              //   original: "/img/longevity-panel-hero.png", 
              //   thumbnail: "/img/longevity-panel-hero.png"
              // },
              {
                original: "/img/report-phone.png", 
                thumbnail: "/img/report-phone.png"
              },

              {
                original: "/img/results-laptop.png", 
                thumbnail: "/img/results-laptop.png"
              },
              {
                original: "/img/longevitypanel-2.png",
                thumbnail:  "/img/longevitypanel-2.png"
              },
          ],
          points: [
              <><BloodSVG className="point-icon"/>Test 60+ biomarkers at once</>,
              <><HomeSVG className="point-icon"/>Blood drawn from comfort of your home</>,
              <><ScienceSVG className="point-icon" />Tailored advice based on latest science</>,
              <><CardSVG className="point-icon" />HSA / FSA cards accepted</>,
          ],
          faqs: faqs.slice(0,3)
        },
      },
      { type: ProductDetailSection.TESTIMONIALS,
        title: "Hear it from our real customers",
        data: [
          {
              name: "Geoff Abraham",
              title: "Founder @ Spoken",
              image: "/img/geoff.jpg",
              text: "I didn’t know my body until Instalab. A lovely phlebotomist came to me on my schedule - shout out to Layla! It was very frustrating trying to get the same tests from my PCP. In 3 months, I have dramatically improved my biomarkers."
          },
          {
              name: "Kat Manalac",
              title: "Partner @ Y Combinator",
              image: "/img/kat.jpg",
              text: "As a working parent, it's hard to make time to be proactive about my health. Instalab made it quick and easy to understand what I need to improve. Based on my labs they gave me clear insights and easy-to-follow game plan."
          },
          {
              name: "Ed Suh",
              title: "MD @ Alpine Ventures ",
              image: "/img/ed.jpg",
              text: "I'm blown away by how comprehensive Instalab is. It's great to see a report focused on my most pressing issues. I love the intuitive summaries that explaind my results and where I stood on the risk curve."
          },
          {
              name: "Jason Goldman",
              title: "Filmmaker @ Wild Growth",
              image: "/img/jason.jpg",
              text: "Instalab's totally changed the way I look after myself. After making changes based on the results, I saw improvements pretty quickly. It’s great for getting a clear view of my health without the hassle of visiting a clinic."
  
          }
        ],
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
            // {
            //     title: "Schedule blood draw",
            //     description: "After you purchase your test, you'll be able to schedule a phlebotomist to visit and complete your blood draw.",
            //     image: "/img/longevitypanel-step-1.png"
            // },
            {
                title: "Get blood drawn at home",
                description: "Your phlebotomist will come to you to draw your blood and send to Instalab's CLIA and CAP-certified clinical lab.",
                image: "/img/longevitypanel-step-2.png"
            },
            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available in 1 week. You'll also get a comprenhensive report and game plan.",
                image: "/img/longevitypanel-step-1.png"
            },
            {
              title: "Get physician support",
              description: "Instalab's medical team is available to answer any of your questions and provide guidance on your next steps.",
              image: "/img/longevitypanel-step-4.png"
            }

        ],
      },
      { type: ProductDetailSection.PANEL,
        data: {
          panel: advancedPanel,
          panelRef: panelRef
        }
      },
      { type: ProductDetailSection.FAQ,
        title: 'More on Longevity Panel',
        data: faqs.slice(3),
      },

    ])
  
  }, [productType, longevityMembership])


  return (
    sections && <div className="longevity-panel">

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_BLOOD_TEST}
      />

    </div>
  )

}
