const AppointmentType = Object.freeze({
  // GROUP: 'group',
  // INDIVIDUAL: 'individual',
  BLOOD_DRAW: 'blood draw',
  CAC: 'cac',
  CCTA: 'ccta',
  CONSULTATION: 'consult',
  DEXA_BODY: 'dexa - body',
  DEXA_BONE: 'dexa - bone'
})

export default AppointmentType