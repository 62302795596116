import { useContext, useRef, useState, useEffect } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ConfirmVo2max } from "./confirmVo2max.component"
import { UserContext } from "../../contexts/user.context"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { MembershipPointsModal } from "../membershipPointsModal/membersipPointsModal.component";
import { Vo2MaxIntro } from "./vo2maxIntro.component"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  NAME: 'name',
  DOB: 'dob',
  MEMBERSHIP: 'membership',
  LOCATION: 'location',
  CONFIRM: 'confirm',
}

export const Vo2maxFlow = () => {
  const navigate = useNavigate()
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const { longevityMembership } = useContext(UserContext)
  const [productTypes, setProductTypes] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [openMembershipPointsModal, setOpenMembershipPointsModal] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, membershipTypes, productTypes, longevityMembership, flow])
  

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.VO2MAX,
          ]
        }
      }
    }))
  }

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }


  const instructions = [
    {
      sections: [{
        title: 'Schedule Your Vo2 Max',
        description: <>Instalab's concierge team will reach out to you to schedule your appointment with a nearby performance center.</>
      }, 
    ]
    }, 
    {
      sections: [{
        title: 'Get Text Done',
        description: <>Wear comfortable clothing and running shoes. The test should take about 20-40 minutes depending on your current endurance level.</>
      }]
    },
    {
      sections: [{
        title: 'Review Test Results',
        description: <>You can expect to receive your results within 24 hours. Instalab physicians are available to review your results with you and provide recommendations.</>
      }]
    }]

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.LOCATION,
        content: ({ footer }) => <Vo2MaxIntro  footer={footer} instructions={instructions}/>
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.NAME,
        title: `Where are you located?`,
        description: `We'll find a performance center near here that does this test.`,
        field: {
          name: 'location'
        },
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.DEXA}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.MEMBERSHIP,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },

      [Step.MEMBERSHIP]: {
        type: StepType.PAY,
        nextStep: Step.LOCATION,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LONGEVITY,
        title: <>Activate Instalab membership for <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>. Cancel anytime.</>,
        description: "This test is exclusive to members, who also enjoy special savings, a care concierge, access to longevity physicians, and more. Easily cancel anytime.",
        skipIf: () => {
          return longevityMembership
        }
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        addFreeProduct: true,
        productType: ProductTypeCode.VO2MAX,
        buildProduct: true,
        nextStep: Step.CONFIRM,
        title: `What's your current address?`,
        description: `We'll recommend an imaging center near this address.`,
        field: {
          name: 'location'
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>All set! We'll email you within 1 business day.</>,
        description: `An Instalab physician will review your details first as required by law. Once done, we'll email you the performance center and pricing for your VO2 max and facilitate scheduling your appointment.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmVo2max />
      },

    })
  }

  return <>
    {skeleton && (
      <>
      <Flow 
        skeleton={skeleton} 
        flow={flow}
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />

      <MembershipPointsModal
        open={openMembershipPointsModal}
        setOpen={setOpenMembershipPointsModal}
      />
      </>
    )}
    
  </>
}