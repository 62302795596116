
import { useRef, useEffect, useState } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import { ReactComponent as BloodSVG} from '../../assets/svg/blood.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as HomeSVG} from '../../assets/svg/house.svg'; 
import { ReactComponent as ScienceSVG} from '../../assets/svg/science.svg'; 
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import FlowType from "../../enums/flowType.enum"
import "./athlete.scss"
import {athletePanel} from "../../data/athletePanel.data"
import DISCOUNT from "../../enums/discount.enum";

export const Athlete = () => {
  const [cost, setCost] = useState(null)
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchCost(); 
  }, [])
  
  // set product attributes
  useEffect(() => {
    if (!cost) return
    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}
    const faqs = [
      {
          question: "What biomarkers are included?",
          answer: <>The Athlete Panel is carefully curated by medical experts from diverse specialties, providing a comprehensive assessment of your ever-evolving health. <a onClick={scrollToPanel}  className="cta-link">View Biomarkers Below</a></>
      },
      {
          question: "How many vials of bloow are drawn?",
          answer: "It's just 6 small tubes."
      },
      {
          question: "Who is drawing my blood?",
          answer: `A licensed phlebotomist will come to your desired location to perform your blood draw. Every phlebotomist undergoes extensive background checks and training. And they are experts in making your experience painless and stress-free.`,
      },
      {
          question: `Where is this available?`,
          answer: `Anywhere in the United States.`
      },
      {
          question: `Do I need to fast before my blood draw?`,
          answer: `Yes, please fast for at least 8 hours before your draw. Water and black coffee are permissible. Failure to fast may impact several of your results, including insulin, glucose, triglycerides, and more.`,
      }, 
      {
          question: `Where is my blood being sent?`,
          answer: `Your sample is sent to one of our CLIA + CAP certified labs for analysis. After the lab is done, any remaining sample is disposed.`,
      }, 
      {
          question: `What is the efficacy of the given recommendations?`,
          answer:  `Nearly everyone who adhered to their recommendations and subsequently followed up with a blood panel after 3 months demonstrated improvement in their results. A key reason for this success is the simplicity and straightforwardness of the guidance given.`
      }, 
      {
          question: `Can I talk to an actual human physician to review my results with me?`,
          answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a physician who helped put together Instalab's Athlete Panel. They specialize in working with professional athletes and will be ready to address any of your questions.`,
      }, 
      {
          question: "How often should I get this panel done?",
          answer: `If you're in good health, it's wise to have a check-up quarterly to monitor your well-being. But if you're changing your lifestyle in any way – whether through diet, exercise, supplements or medications – it's recommended to undergo tests at least quarterly during this time. This ensures that your choices are benefiting, not compromising, your health.`,
      },             
      {
          question: `If I already feel healthy, how will this data be useful?`,
          answer: `Many people who come to Instalab feel healthy but still discover a hidden health risk. Most are able to fix it by doing something super simple. This is the power of early detection testing - addressing issues while they're still molehills, rather than waiting for them to become mountains.`,
      }, 
      {
          question: `Does my health insurance cover the Athlete Panel?`,
          answer: `For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don’t have one, we can generate an itemized receipt for you to submit.`,
      }, 
      {
          question: `Can I add other biomarkers to Instalab’s Athlete Panel?`,
          answer: <>
              Yes. Instalab members can add additional biomarkers. Once you've signed up, please reach out to your health concierge (<a className="cta-link" href="mailto:conceriege@instalab.com">concierge@instalab.com</a>) and we'll explore what options we can add-on.
          </>
      },     
    
    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: "Athlete Panel",
          // memberPrice: (cost * DISCOUNT),
          originalPrice: cost,
          oneliner: "A blood test designed specifically for professional athletes to help optimize performance and longevity.",
          panelRef: panelRef,
          cta: {
            text: "Get Tested Now",
            url: `/flow/${FlowType.ATHLETE_TEST}`
          },
          photos: [
            {
              original: "/img/athlete-hero-3.png",
              thumbnail:  "/img/athlete-hero-3.png"
            },
            {
                original: "/img/athlete-panel-box.png", 
                thumbnail: "/img/athlete-panel-box.png"
            },
              {
                original: "/img/report-phone.png", 
                thumbnail: "/img/report-phone.png"
              },

              {
                original: "/img/results-laptop.png", 
                thumbnail: "/img/results-laptop.png"
              },          
          ],
          points: [
              <><BloodSVG className="point-icon"/>Test 70+ biomarkers at once</>,
              <><HomeSVG className="point-icon"/>Blood drawn from comfort of your home</>,
              <><ScienceSVG className="point-icon" />Results and analysis in one week</>,
              // <><CardSVG className="point-icon" />HSA / FSA cards accepted</>,
          ],
          faqs: faqs.slice(0,3)
        },
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
            {
                title: "Schedule blood draw",
                description: "After you purchase your test, you'll be able to schedule a phlebotomist to visit and complete your blood draw.",
                image: "/img/athlete-draw-1.png"
            },
            {
                title: "Have blood drawn",
                description: "Your phlebotomist will come to you to draw your blood and send to Instalab's CLIA and CAP-certified clinical lab.",
                image: "/img/athlete-draw-2.png"
            },
            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available in 1 week. You'll also get a comprenhensive report and game plan.",
                image: "/img/athlete-draw-3.png"
            }
        ],
      },
      { type: ProductDetailSection.PANEL,
        data: {
          panel: athletePanel,
          panelRef: panelRef
        }
      },
      { type: ProductDetailSection.FAQ,
        title: 'More on Athlete Panel',
        data: faqs.slice(3),
      },

    ])
  
  }, [cost])

  const fetchCost = async () => {
    setCost(await ProductHelper.getCost(ProductTypeCode.ATHLETE_PANEL));
  };

  return (
    sections && <div className="athlete">
      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_ATHLETE_BLOOD_TEST}
      />
    </div>
  )
}