import React, { useEffect, useState } from 'react';
import { Popover, Row, Col, Button, message, Tooltip } from 'antd'
import './memberships.scss';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import MembershipStatus from '../../enums/membershipStatus.enum';
import { cancelMembership, resumeMembership } from '../../services/membership.service';
import moment from 'moment'
import FlowType from '../../enums/flowType.enum';
import { useNavigate } from 'react-router-dom';
import { Step as MemberStep } from '../memberFlow/memberFlow.component'
import MembershipHelper from '../../helpers/membership.helper';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import { useRef } from 'react';
import { getDiscountByCode } from '../../services/discount.service';

const select = 'status startAt endAt'
const populate = [{
  path: 'membershipType',
  select: 'code'
}]

const heartHealthFeatures = [
  {
    key: 5,
    title: "Personalized care plan",
    description: "Your dedicated physician will meet with you to create a plan that considers where you're at now and what your goals should be."
  },
  {
    key: 'rx',
    title: "Medication prescribed & delivered",
    description: "Medications are shipped to you. All generics are included and we'll help navigate insurance for any brand medications."
  },    
  {
    key: 'testing',
    title: "Regular testing to verify treatment efficacy",
    description: "You'll test every 6-8 weeks to ensure efficacy of your plan. If needed, we'll also send devices like BP monitors and CGMs to gain better insights."
  }, 
  {
    key: 'messaging',
    title: "Unlimited access physician team",
    description: "You can check in with your physician anytime, and they’ll always follow up to review test results and answer questions.",
  },

]

const features = [
  {
    key: 3,
    title: "Exclusive advanced diagnostics access",
    description: "Enjoy the best available pricing on all advanced diagnostics, reserved exclusively for members."
  },
  {
    key: 2,
    title: "Access to longevity physicians for consults",
    description: "Receive expert guidance and personalized advice from longevity specialists through convenient online visits after each test. Each 30 minute visit is just $99.",
  },
  {
    key: 4,
    title: "Dedicated care concierge",
    description: "A personal assistant to handle all your healthcare needs, including smooth scheduling, specialist referrals, and quick communication with our physician team."
  },
  {
    key: 6,
    title: "Supplement discounts",
    description: "Save 30% off retail on high-quality supplements through Fullscript."
  },    
]

export const Memberships = () => {
  const navigate = useNavigate()
  const { longevityMembership, setLongevityMembership, memberships, setMemberships, token } = useContext(UserContext)
  const [heartMembership, setHeartMembership] = useState()

  const [discount, setDiscount] = useState()

  const [resuming, setResuming] = useState([])
  const resumingRef = useRef()
  resumingRef.current = resuming

  const [canceling, setCanceling] = useState([])
  const cancelingRef = useRef()
  cancelingRef.current = canceling

  useEffect(() => {
    document.title = 'Membership | Instalab'
  }, [])

  useEffect(() => {
    fetchLipidMembership()
  }, [memberships])

  useEffect(() => {
    fetchHeartDiscount()
  }, [token])

  const fetchHeartDiscount = async () => {
    if (!token) return
    setDiscount(await getDiscountByCode(MembershipTypeCode.HEART_HEALTH))
  }

  const fetchLipidMembership = () => {
    if (!memberships) return
    setHeartMembership(MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH) || MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LIPIDS))
  }

  const onMembershipUpdate = (membership) => {
    setMemberships(cachedMemberships => {
      return cachedMemberships.map(m => m._id === membership._id ? membership : m)
    })
    if (membership.membershipType.code === MembershipTypeCode.LONGEVITY) {
      setLongevityMembership(membership)
    }
  }

  const onCancelMembership = async (membership) => {
    setCanceling([
      ...cancelingRef.current,
      membership._id
    ])

    try {
      const updatedMembership = await cancelMembership(membership._id, {
        select,
        populate
      })
      message.info('Membership canceled')
      onMembershipUpdate(updatedMembership)
    } catch (err) {
      message.error('Failed to cancel membership')
    }
    setCanceling(cancelingRef.current.filter(membershipId => membershipId !== membership._id))
  }

  const onResumeMembership = async (membership) => {
    setResuming([
      ...resumingRef.current,
      membership._id
    ])
    try {
      const updatedMembership = await resumeMembership(membership._id, {
        select,
        populate
      })
      onMembershipUpdate(updatedMembership)
      message.success('Membership reactivated')
    } catch (err) {
      message.error('Failed to reactivate membership')
    }
    setResuming(resumingRef.current.filter(membershipId => membershipId !== membership._id))
  }

  const handleAdvancedClick = async () => {
    const url = (longevityMembership || discount) ? `/flow/${FlowType.HEART_HEALTH_UPGRADE}` : `/flow/${FlowType.HEART_HEALTH_INTAKE}`
    navigate(url)
  }

  return (
    <div className="memberships">
      <PageHeader
        title='Memberships'
      />


      <Row gutter={12} className="membership-plans-container">

      {MembershipHelper.isUltimate(longevityMembership) ? 
        <div className="membership-plans-container">You have the Ultimate Membership! Please email <a className="mail-link" href="mailto:concierge@instalab.com">concierge@instalab.com</a> with any assistance in managing your membership.</div>
      :
      <>
        {!heartMembership && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="membership-plan"
          >
            <div className="membership-plan-container">
              <div className="membership-plan-title">Essentials</div>
                <div className="membership-plan-description">
                  A comprehensive suite of tools and personalized guidance that helps you identify and manage potential health risks. 
                </div>
                <div className="membership-plan-price">
                  $49 <span>per month</span>
                </div>
                <div className="membership-plan-cta">
                  {longevityMembership?.status === MembershipStatus.ACTIVE ? (
                    <Button
                      type="default"
                      className="cancel-btn"
                      danger
                      onClick={() => onCancelMembership(longevityMembership)}
                      loading={cancelingRef.current?.includes(longevityMembership._id)}
                    >
                      Cancel Membership
                    </Button>
                  ) : longevityMembership ? (
                    <Tooltip title={`Your membership will expire on ${moment(longevityMembership.endAt).format('MMM D, YYYY')}. Reactivate to continue using your benefits before it expires.`}>
                      <Button
                        type="primary"
                        className="join-btn"
                        onClick={() => onResumeMembership(longevityMembership)}
                        loading={resumingRef.current?.includes(longevityMembership._id)}
                      >
                        Reactivate Membership
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      type="primary"
                      className="join-btn"
                      onClick={() => navigate(`/flow/${FlowType.MEMBER}/${MemberStep.PAY}`)}
                    >
                      Join Now
                    </Button>
                  )}
                  
                </div>

                <div className="membership-features-container">
                <div className="feature-item">Includes access to all testing, plus:</div>

                  {features.map(({ title, description, key }) => (
                    <div key={key} className="feature-item">
                      <CheckOutlined className="checkmark" />
                      <div className="title">
                        {title}
                        <Popover content={description} title={title} trigger="click">
                          <InfoCircleOutlined className="info-icon" />
                        </Popover>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
          </Col>
        )}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="membership-plan"
        >
          <div className="membership-plan-container">
              <div className="membership-plan-title">Heart Health</div>
              <div className="membership-plan-description">
                Join the many who have significantly lowered their heart disease risk with Instalab's physician-led program.
              </div>
              <div className="membership-plan-price">
                $149 <span>per month</span>
              </div>
              <div className="membership-plan-cta">
                {heartMembership?.status === MembershipStatus.ACTIVE ? (
                  <Button
                    type="default"
                    className="cancel-btn"
                    danger
                    onClick={() => onCancelMembership(heartMembership)}
                    loading={cancelingRef.current?.includes(heartMembership._id)}
                  >
                    Cancel Membership
                  </Button>
                ) : heartMembership ? (
                  <Tooltip title={`Your membership will expire on ${moment(heartMembership.endAt).format('MMM D, YYYY')}. Reactivate to continue using your benefits before it expires.`}>
                    <Button
                      type="primary"
                      className="join-btn"
                      onClick={() => onResumeMembership(heartMembership)}
                      loading={resumingRef.current?.includes(heartMembership._id)}
                    >
                      Reactivate Membership
                    </Button>
                  </Tooltip>
                ) : (
                <Button 
                  className="join-btn"
                  type="primary"
                  onClick={handleAdvancedClick}
                >
                  {longevityMembership ? 'Upgrade Now' : 'Join Now'}
                </Button>
                )}
              </div>
              
              <div className="membership-features-container">
                <div className="feature-item">Includes everything in "Essentials", plus:</div>
                {heartHealthFeatures.map(({ title, description, key }) => (
                  <div key={key} className="feature-item">
                    <CheckOutlined className="checkmark" />
                    <div className="title">
                      {title}
                      <Popover content={description} title={title} trigger="click">
                        <InfoCircleOutlined className="info-icon" />
                      </Popover>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        </Col>
        </>}
      </Row>
      
    </div>
  )
}