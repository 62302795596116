import moment from 'moment-timezone'
import copy from 'copy-to-clipboard'

const AppointmentHelper = {
  getStartTime: (appointment) => {
    return moment(appointment.start).tz(appointment.location.timeZoneId)
  },
  getLocation: (appointment) => {
    const {
      streetAddress,
      streetAddress2,
      city,
      state,
    } = appointment.location

    return `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state}`
  },
  getPhlebotomistName: (appointment) => {
    const { phlebotomist } = appointment
    if (!phlebotomist) return
    return `${phlebotomist.firstName} ${phlebotomist.lastName}`
  },
  copyJoinLink:  async (appointmentId, message) => {
    try {
      copy(`${process.env.REACT_APP_CLIENT_URL}/appointments/${appointmentId}/join`)
      message.success('Copied join link')
    } catch (err) {
      message.error('Failed to generate join link')   
    }
  },
  getPanel: (appointment) => {
    const { orders } = appointment

    if (orders && orders.length > 0) {
      return orders[0].panel
    }
  },
}

export default AppointmentHelper