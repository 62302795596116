const PrescriptionType = Object.freeze({

  // uric acid
  ALLOPURINOL_100MG: 'Allopurinol (100mg)',
  ALLOPURINOL_300MG: 'Allopurinol (300mg)',

  // cholesterol
  EZETIMIBE_10MG: 'Ezetimibe (10mg)',
  ROSUVASTATIN_2500MCG: 'Rosuvastatin (2.5mg)',
  ROSUVASTATIN_5MG: 'Rosuvastatin (5mg)',
  ROSUVASTATIN_10MG: 'Rosuvastatin (10mg)',
  ROSUVASTATIN_20MG: 'Rosuvastatin (20mg)',
  ROSUVASTATIN_40MG: 'Rosuvastatin (40mg)',
  BEMPEDOIC_ACID_180MG: 'Bempedoic Acid (180mg)',
  ALIROCUMAB_75MG: 'Alirocumab (75mg)',
  ALIROCUMAB_150MG: 'Alirocumab (150mg)',
  EVOLOCUMAB_140MG: 'Evolocumab (140mg)',
  LEQVIO_284MG: 'Leqvio (284mg)',

  // insulin resistance
  METFORMIN_500MG: 'Metformin (500mg)',
  METFORMIN_850MG: 'Metformin (850mg)', 
  METFORMIN_1000MG: 'Metformin (1000mg)',
  METFORMIN_ER_500MG: 'Metformin ER (500mg)',
  METFORMIN_ER_750MG: 'Metformin ER (750mg)',
  METFORMIN_ER_1500MG: 'Metformin ER (1500mg)',
  ICOSAPENT_ETHYL_1G: 'Icosapent Ethyl (1g)',
  ICOSAPENT_ETHYL_2G: 'Icosapent Ethyl (2g)',
  ICOSAPENT_ETHYL_4G: 'Icosapent Ethyl (4g)',

  // cgm
  FREESTYLE_LIBRE_3_SENSOR: 'Freestyle Libre 3 Sensor',
  DEXCOM_G6_TRANSMITTER: 'Dexcom G6 Transmitter',

  // misc
  LODOCO_05MG: 'Lodoco (0.5mg)',
  METOPROLOL_TARTRATE_50MG_CCTA: 'CCTA: Metoprolol Tartrate (50mg)',
  INVOKANA_100MG: 'Invokana (100mg)',
});

export default PrescriptionType;
